import React from 'react';
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-react';
// @ts-ignore
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/ru';
import { useTranslation } from 'react-i18next';

const CKEditorWrapper: React.FC<{ data: string; onChange: (data: string) => void }> = ({ data, onChange }) => {
  const { t } = useTranslation('widgets');

  return (
    <CKEditor
      onInit={(editor: any) => {
        // Insert the toolbar before the editable area.
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
      }}
      onChange={(event: any, editor: any) => onChange(editor.getData())}
      editor={DecoupledEditor}
      data={data}
      config={{ language: t('locale') }}
    />
  );
};

export default CKEditorWrapper;
