import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';

export const contractService = {
  getContractLicense,
  getContractDigitalService,
  saveContractLicense,
  saveContractDigitalService,
};

function getContractLicense() {
  return ajaxRequest.get(urlRepository.getUrl('clientDocumentLicense'));
}

function getContractDigitalService() {
  return ajaxRequest.get(urlRepository.getUrl('clientDocumentDigitalService'));
}

function saveContractLicense(text: string) {
  return ajaxRequest.post(urlRepository.getUrl('adminDocumentLicense'), { text });
}

function saveContractDigitalService(text: string) {
  return ajaxRequest.post(urlRepository.getUrl('adminDocumentDigitalService'), { text });
}
