import React, { FC, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../index';
import Select from 'react-select';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Option } from 'react-select/src/filters';
import { CoinTransaction } from '../../../models/coin-transaction';
import { toastHelper } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const BukaCoinForm: FC<{ onSubmit: (values: CoinTransaction) => Promise<any> }> = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['bukacoin', 'buttons', 'notifications', 'form']);

  const selectOperationValues = [
    { value: 1, label: t('operationText.replenish') },
    { value: 2, label: t('operationText.spend') },
  ];
  const selectTypeReplenishValues = [
    { value: 1, label: t('typeReplenishText.online') },
    { value: 2, label: t('typeReplenishText.cash') },
  ];

  const initialValues: CoinTransaction = {
    id: 0,
    userId: 0,
    value: 0,
    operation: null,
    typeReplenish: null,
    balance: 0,
    comment: '',
  };
  const onSubmitFormik = (values: CoinTransaction, { setSubmitting, resetForm, setFieldValue }: any) => {
    const copyValues: CoinTransaction = { ...values };
    copyValues.operation = Number(((values.operation as unknown) as Option).value);
    copyValues.typeReplenish = Number(((values.typeReplenish as unknown) as Option).value);
    copyValues.value = Number(copyValues.value);
    setLoading(true);
    onSubmit(copyValues).then(
      () => {
        resetForm({ values: { ...initialValues, operation: values.operation, typeReplenish: values.typeReplenish } });
        toastHelper.success(t('notifications:success'));
        setLoading(false);
      },
      (error) => {
        toastHelper.error(`Error: ${error}`);
        setSubmitting(false);
        console.log(error);
        setLoading(false);
      },
    );
  };
  const schema = yup.object({
    value: yup
      .number()
      .min(1, t('form:error.minValue', { value: 1 }))
      .required(t('form:error.requiredField')),
    comment: yup.string().required(t('form:error.requiredField')),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmitFormik}>
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            {[
              { name: 'value', placeholder: t('value'), type: 'number' },
              { name: 'operation', placeholder: t('operation'), type: 'select' },
              { name: 'typeReplenish', placeholder: t('typeReplenish'), type: 'select' },
              { name: 'comment', placeholder: t('comment'), type: 'text' },
            ].map(({ name, placeholder, type }) => (
              <Col sm={3} lg={2} as={Form.Group} key={`${name}_coinform`}>
                {type !== 'select' ? (
                  <>
                    <Form.Control
                      type={type}
                      id={`${name}_id`}
                      name={name}
                      placeholder={placeholder}
                      onChange={handleChange}
                      value={values[name]}
                      isInvalid={!!errors[name]}
                    />
                  </>
                ) : (
                  <>
                    <Select
                      className={!!errors[name] ? 'is-invalid' : ''}
                      id={`${name}_id`}
                      name={name}
                      options={name === 'operation' ? selectOperationValues : selectTypeReplenishValues}
                      onChange={(option) => setFieldValue(name, option)}
                      value={values[name]}
                      placeholder={name === 'operation' ? t('operation') : t('typeReplenish')}
                      isClearable
                    />
                  </>
                )}
                <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
              </Col>
            ))}
            <Col xs="auto">
              <LoadingButton variant={'success'} loading={loading} type={'submit'}>
                <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons:add')}
              </LoadingButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default BukaCoinForm;
