const getNav = (t: (s: string) => string) => {
  return [
    {
      _tag: 'CSidebarNavItem',
      name: t('navAdminUsers'),
      to: '/users',
      fontIcon: 'fas fa-users',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientCatalog'),
      to: '/catalog',
      fontIcon: 'fas fa-store',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientDiscounts'),
      to: '/discounts',
      fontIcon: 'fas fa-fire-alt',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientInvoices'),
      to: '/invoices',
      fontIcon: 'fas fa-receipt',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientOrders'),
      to: '/orders',
      fontIcon: 'fas fa-file-invoice',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navTransfer'),
      to: '/transfer',
      fontIcon: 'fas fa-landmark',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientContract'),
      to: '/contract',
      fontIcon: 'fas fa-file-contract',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('Help'),
      to: '/help',
      fontIcon: 'fas fa-question',
    },
  ];
};

export default getNav;
