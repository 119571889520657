import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

const AlertMoreMaxCountProductOrder: React.FC<{
  products: { name: string; maxCount: number }[];
}> = observer(({ products }) => {
  const { t } = useTranslation(['basket']);
  return (
    <Alert variant="danger">
      {products.map((item) => (
        <p key={item.name}>
          <Trans i18nKey={'basket:create.errorMoreMaxCount'} t={t}>
            The quantity for the position "{{ product: item.name }}" exceeds the maximum in "
            {{ maxCount: item.maxCount }}"
          </Trans>
          <br />
        </p>
      ))}
    </Alert>
  );
});

export default AlertMoreMaxCountProductOrder;
