import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CSwitch } from '@coreui/react';
import Select from 'react-select';
import { AlertUserIsBlocked, LoadingButton, UserNewPassword } from '../../index';
import { User } from '../../../models/user';
import { userService } from '../../../services';
import { rolesList } from '../../../helpers/user/roles';
import { history, toastHelper } from '../../../helpers';
import { validation } from '../../../helpers/validation';

const UserEditForm: React.FC<{ userID: any }> = ({ userID }) => {
  const isEdit: boolean = userID !== 'create';

  const { t } = useTranslation(['users', 'notifications', 'form']);
  const [roles, setRoles] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const initialState: User = {
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    phoneNumber: '',
    fixDiscountPercent: 0,
    delayedPaymentFlag: false,
    downloadKeysAfterPaymentFlag: false,
    issuingKeysByClientFlag: false,
    cashPaymentFlag: false,
    intervalDelayedPaymentNumber: 0,
    isEnableBukacoin: false,
  };

  const [state, setState] = useState<User>(initialState);

  useEffect(() => {
    const roles = rolesList.map(({ label, value }) => ({ value: value, label: t(label) }));
    if (isEdit) {
      userService.getUserDetail(userID as number).then(
        (data: User) => {
          data.role = data.role === '' ? null : (roles.find((item: any) => item.value === data.role) as any);
          setState(data);
        },
        (error) => console.log(error),
      );
    }
    setRoles(roles);
  }, [userID, isEdit, t]);

  const schema = yup.object({
    email: yup.string().required(t('form:error.requiredField')).email(t('form:error.notValidEmail')),
    firstName: yup.string().required(t('form:error.requiredField')),
    lastName: yup.string().required(t('form:error.requiredField')),
    phoneNumber: yup.string().matches(validation.phoneNumber, t('form:error.notValidPhone')),
    fixDiscountPercent: yup
      .number()
      .min(0, t('form:error.minValue', { value: 0 }))
      .max(99, t('form:error.maxValue', { value: 99 })),
    intervalDelayedPaymentNumber: yup.number().moreThan(-1, t('form:error.positiveNumber')),
  });

  const handleSubmit = (values: User, { setSubmitting }: any) => {
    setIsLoading(true);
    let role: string | null = null;
    if (values.role !== null) {
      role = (values.role as any).value;
    }
    const data = { ...values, role: role };
    const request = isEdit ? userService.saveUser(userID, data) : userService.createUser(data);
    request.then(
      (response) => {
        toastHelper.success(t('notifications:success'));
        setIsLoading(false);
        !isEdit && history.push(`/users/${response.id}`);
      },
      (error) => {
        toastHelper.error(`Error: ${error}`);
        setSubmitting(false);
        console.log(error);
        setIsLoading(false);
      },
    );
  };

  return (
    <Card>
      <Card.Header>
        {t('headerUser')}
        <div className="float-right">{isEdit ? <UserNewPassword userId={userID} /> : null}</div>
      </Card.Header>
      <Card.Body>
        <Formik validationSchema={schema} initialValues={state} enableReinitialize={true} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
              {values.isBlocked ? <AlertUserIsBlocked userId={values.id as number} /> : null}
              {[
                { field: 'email', trans: 'fieldEmail', type: 'text' },
                { field: 'firstName', trans: 'fieldFirstName', type: 'text' },
                { field: 'lastName', trans: 'fieldLastName', type: 'text' },
                { field: 'phoneNumber', trans: 'fieldPhone', type: 'text' },
                { field: 'role', trans: 'fieldRole', type: 'select' },
                { field: 'fixDiscountPercent', trans: 'fieldFixDiscountPercent', type: 'number' },
                { field: 'delayedPaymentFlag', trans: 'fieldDelayedPayment', type: 'bool' },
                { field: 'downloadKeysAfterPaymentFlag', trans: 'fieldDownloadKeysAfterPayment', type: 'bool' },
                { field: 'issuingKeysByClientFlag', trans: 'fieldIssuingKeysByClient', type: 'bool' },
                { field: 'cashPaymentFlag', trans: 'fieldCashPayment', type: 'bool' },
                { field: 'isEnableBukacoin', trans: 'fieldBukacoin', type: 'bool' },
                { field: 'intervalDelayedPaymentNumber', trans: 'fieldIntervalDelayedPaymentNumber', type: 'number' },
              ].map(({ field, trans, type }) => (
                <Form.Group controlId={field} key={field}>
                  {['text', 'number'].includes(type) ? (
                    <>
                      <Form.Label>{t(trans)}</Form.Label>
                      <Form.Control
                        type={type}
                        name={field}
                        value={values[field]}
                        onChange={handleChange}
                        isInvalid={!!errors[field]}
                      />
                      <Form.Control.Feedback type="invalid">{errors[field]}</Form.Control.Feedback>
                    </>
                  ) : null}
                  {type === 'select' ? (
                    <>
                      <Form.Label>{t(trans)}</Form.Label>
                      <Select
                        name={field}
                        type={'text'}
                        options={roles}
                        onChange={(option) => setFieldValue(field, option)}
                        value={values[field]}
                        defaultValue={roles.find((item: any) => item.value === values[field])}
                        isClearable
                        placeholder={t('placeholderSelectRole')}
                      />
                    </>
                  ) : null}
                  {type === 'bool' ? (
                    <>
                      <CSwitch
                        className={'mx-1 float-left'}
                        id={`${field}_id`}
                        name={field}
                        variant={'3d'}
                        color={'success'}
                        labelOn={'\u2713'}
                        labelOff={'\u2715'}
                        onChange={(e: any) => {
                          if (field === 'delayedPaymentFlag' && e.target.checked === false) {
                            setFieldValue('downloadKeysAfterPaymentFlag', false);
                          }
                          handleChange(e);
                        }}
                        disabled={!values['delayedPaymentFlag'] && field === 'downloadKeysAfterPaymentFlag'}
                        checked={values[field]}
                      />
                      <Form.Label>{t(trans)}</Form.Label>
                    </>
                  ) : null}
                </Form.Group>
              ))}
              <Form.Group>
                <LoadingButton variant="success" type="submit" loading={isLoading}>
                  <FontAwesomeIcon icon={faSave} /> {isEdit ? t('buttons.edit') : t('buttons.create')}
                </LoadingButton>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default UserEditForm;
