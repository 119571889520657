import React, { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '../../index';
import { validation } from '../../../helpers/validation';
import { userService } from '../../../services';
import { toastHelper } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { ClientInfo } from '../../../models/client-info';

const UserEditInfoForm: FC<{ userID: number }> = ({ userID }) => {
  const [initialState, setInitialState] = useState<ClientInfo>(() => ({
    country: '',
    tradingWebsite: '',
    companyName: '',
    registeredAddress: '',
    taxId: '',
    bank: '',
    bankAddress: '',
    bankBicOrSwift: '',
  }));
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['form', 'users', 'notifications']);

  useEffect(() => {
    userService.getClientInfo(userID).then((response: ClientInfo) => setInitialState(response), console.log);
  }, [userID]);

  const schema = yup.object({
    tradingWebsite: yup.string().nullable().url(t('form:error.url')).required(t('form:error.requiredField')),
    country: yup
      .string()
      .nullable()
      .matches(validation.latinString, t('form:error.latinString'))
      .required(t('form:error.requiredField')),
    companyName: yup
      .string()
      .nullable()
      .matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber'))
      .required(t('form:error.requiredField')),
    registeredAddress: yup
      .string()
      .nullable()
      .matches(validation.latinStringAndSymbols, t('form:error.latinStringAndSymbols'))
      .required(t('form:error.requiredField')),
    taxId: yup
      .string()
      .nullable()
      .matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber'))
      .required(t('form:error.requiredField')),
    bank: yup.string().nullable().matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber')),
    bankAddress: yup
      .string()
      .nullable()
      .matches(validation.latinStringAndSymbols, t('form:error.latinStringAndSymbols')),
    bankBicOrSwift: yup
      .string()
      .nullable()
      .matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber')),
  });

  const handleSubmit = (values: ClientInfo, { setSubmitting }: any) => {
    setLoading(true);
    userService.saveClientInfo(userID, values).then(
      () => {
        setLoading(false);
        toastHelper.success(t('notifications:success'));
      },
      (error) => {
        setLoading(false);
        console.log(error);
        toastHelper.error(t('notifications:error'));
        setSubmitting(false);
      },
    );
  };

  return (
    <Card>
      <Formik validationSchema={schema} initialValues={initialState} onSubmit={handleSubmit} enableReinitialize>
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
            <Card.Header>
              {t('users:headerInfo')}
              {/*<div className="float-right"></div>*/}
            </Card.Header>
            <Card.Body>
              {[
                { field: 'tradingWebsite', label: t('users:infoField.tradingWebsite'), type: 'text' },
                { field: 'country', label: t('users:infoField.country'), type: 'text' },
                { field: 'companyName', label: t('users:infoField.companyName'), type: 'text' },
                { field: 'registeredAddress', label: t('users:infoField.registeredAddress'), type: 'text' },
                { field: 'taxId', label: t('users:infoField.taxId'), type: 'text' },
                { field: 'bank', label: t('users:infoField.bank'), type: 'text' },
                { field: 'bankAddress', label: t('users:infoField.bankAddress'), type: 'text' },
                { field: 'bankBicOrSwift', label: t('users:infoField.bankBicOrSwift'), type: 'text' },
              ].map(({ field, label }) => {
                return (
                  <Form.Group controlId={`form${field}`} key={`key_info_${field}`}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                      type="text"
                      name={field}
                      value={values[field]}
                      onChange={handleChange}
                      isInvalid={!!errors[field]}
                    />
                    <Form.Control.Feedback type="invalid">{errors[field]}</Form.Control.Feedback>
                  </Form.Group>
                );
              })}
              <Form.Group>
                <LoadingButton variant="success" type="submit" loading={loading}>
                  <FontAwesomeIcon icon={faSave} /> {t('users:buttons.edit')}
                </LoadingButton>
              </Form.Group>
            </Card.Body>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default UserEditInfoForm;
