import React from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';

const ModalWarning: React.FC<{
  show: boolean;
  toggleClose: () => void;
  data: any;
  toggleSuccess: (data?: any) => void;
  title: string;
  children: React.ReactChild;
}> = ({ show, data, toggleClose, toggleSuccess, title, children }) => {
  const { t } = useTranslation('modal');

  return (
    <Modal
      show={show}
      onHide={toggleClose}
      className={'modal-warning'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton>
        <ModalTitle id={`contained-modal-title-vcenter-${uuidv1()}`}>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody> {children} </ModalBody>
      <ModalFooter>
        <Button variant="success" onClick={() => toggleSuccess(data)}>
          {t('buttonSuccess')}
        </Button>
        <Button variant="danger" onClick={toggleClose}>
          {t('buttonCancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(ModalWarning);
