import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';
import { CoinTransaction } from '../models/coin-transaction';

export const bukaCoinService = {
  getRemoteBukaCoinBalance,
  getRemoteBukaCoinTransactions,
  getRemoteBukaCoinBalanceForUser,
  saveCoinTransaction,
};

function getRemoteBukaCoinBalance() {
  return ajaxRequest.get(urlRepository.getUrl('clientBukaCoinBalance'));
}

function getRemoteBukaCoinBalanceForUser(userID: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('adminBukaCoinBalance', userID));
}

function getRemoteBukaCoinTransactions(params: any): Promise<any> {
  return ajaxRequest.get(urlRepository.getUrl('clientBukaCoinTransactions'), params);
}

function saveCoinTransaction(values: CoinTransaction) {
  return ajaxRequest.put(urlRepository.getUrl('adminBukaCoinAdd'), values);
}
