import React from 'react';
import BasketViewModel from '../components/Basket/BasketViewModel';
import TheSidebarStore from '../containers/TheLayout/TheSidebarStore';
import CreateOrderStore from '../components/CreateOrder/CreateOrderStore';
import ClientSettingStore from '../components/ClientSetting/ClientSettingStore';
import ButtonFullUpdateViewModel from '../components/Buttons/ButtonFullUpdateCatalog/ButtonFullUpdateViewModel';

export function createStores() {
  return {
    basketViewModel: new BasketViewModel(),
    sidebarStore: new TheSidebarStore(),
    clientSettingStore: new ClientSettingStore(),
    createOrderStore: new CreateOrderStore(),
    buttonFullUpdateViewModel: new ButtonFullUpdateViewModel(),
  };
}

export const stores = createStores();
export const AppContext = React.createContext(stores);
