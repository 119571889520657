import React, { useState } from 'react';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import BootstrapTable, {
  ColumnDescription,
  ExpandRowProps,
  PaginationOptions,
  SelectRowProps,
  SortOrder,
} from 'react-bootstrap-table-next';
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Col, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import './GeneralTable.scss';

interface GeneralTableProps {
  defaultSorted: [{ dataField: any; order: SortOrder }];
  columns: ColumnDescription[];
  selectRow?: SelectRowProps<any>;
  rowClasses?: ((row: any, rowIndex: number) => string) | string;
  rowStyle?: React.CSSProperties | ((row: any, rowIndex: number) => React.CSSProperties);
  expandRow?: ExpandRowProps<any>;
  filter?: any;
  getData: (params: any) => Promise<any>;
  placeholderSearch?: string;
}

interface GeneralTableState {
  data: object[];
  page: number;
  sizePerPage: number;
  totalSize: number;
  loading: boolean;
}

const GeneralTable: React.FC<GeneralTableProps> = (props) => {
  const initialState: GeneralTableState = {
    data: [],
    page: 1,
    sizePerPage: 50,
    totalSize: 0,
    loading: false,
  };

  const [state, setState] = useState<GeneralTableState>(initialState);
  const { t } = useTranslation('table');

  const handleTableChange = (type: any, stateChange: any) => {
    const { page, sizePerPage, searchText, sortField, sortOrder, filters } = stateChange;

    if (type === 'search' && searchText.trim() && searchText.trim().length < 3) {
      return;
    }

    const text: string = window.btoa(unescape(encodeURIComponent(searchText || 'NULL')));
    const params = {
      type,
      page,
      sizePerPage,
      searchText: text,
      sortField,
      sortOrder,
      filters,
    };
    setState(() => ({ ...state, loading: true }));
    props
      .getData(params)
      .then((result) =>
        setState({
          page,
          sizePerPage,
          ...result,
        }),
      )
      .catch((error) => console.log(error));
  };

  const customTotal = (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">{t('paginationTotal', { count: size, from, to })}</span>
  );

  const optionsPagination: PaginationOptions = {
    sizePerPage: state.sizePerPage,
    totalSize: state.totalSize,
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: t('firstPageText'),
    prePageText: t('prePageText'),
    nextPageText: t('nextPageText'),
    lastPageText: t('lastPageText'),
    nextPageTitle: t('nextPageTitle'),
    prePageTitle: t('prePageTitle'),
    firstPageTitle: t('firstPageTitle'),
    lastPageTitle: t('lastPageTitle'),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '300', value: 300 },
      { text: '500', value: 500 },
    ],
  };

  return (
    <PaginationProvider
      // @ts-ignore
      pagination={paginationFactory(optionsPagination)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div>
          <ToolkitProvider keyField="id" bootstrap4={true} data={state.data} columns={props.columns} search>
            {(toolkitprops) => (
              <>
                <Row>
                  <Col sm={3}>
                    <Search.SearchBar
                      {...toolkitprops.searchProps}
                      placeholder={props.placeholderSearch ? props.placeholderSearch : t('search')}
                    />
                  </Col>
                  <Col sm={3}>
                    <PaginationTotalStandalone {...paginationProps} />
                  </Col>
                  <Col sm={6}>
                    <div className="float-right ml-2">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                    <div className="float-sm-right">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
                <BootstrapTable
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                  // @ts-ignore
                  loading={state.loading}
                  striped
                  hover
                  remote
                  defaultSorted={props.defaultSorted}
                  selectRow={props.selectRow}
                  rowClasses={props.rowClasses}
                  rowStyle={props.rowStyle}
                  expandRow={props.expandRow}
                  onTableChange={handleTableChange}
                  filter={props.filter}
                  // @ts-ignore
                  noDataIndication={t('noDataIndication')}
                  overlay={overlayFactory({
                    spinner: true,
                    styles: {
                      overlay: (base: any) => ({ ...base, background: 'rgba(206, 210, 216, 0.5)' }),
                    },
                  })}
                  wrapperClasses="table-responsive-lg"
                />
              </>
            )}
          </ToolkitProvider>
          <Row>
            <Col sm={6}>
              <PaginationTotalStandalone {...paginationProps} />
            </Col>
            <Col sm={6}>
              <div className="float-right ml-2">
                <PaginationListStandalone {...paginationProps} />
              </div>
              <div className="float-sm-right">
                <SizePerPageDropdownStandalone {...paginationProps} variation={'dropup'} />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </PaginationProvider>
  );
};

export default GeneralTable;
