import { action, computed, flow, observable } from 'mobx';

import PositionBasket from '../../models/position-basket';
import BasketModel from './BasketModel';
import BasketProductModel from './BasketProductModel';
import { localStorageHelper } from '../../helpers/local-storage';
import { ajaxRequest } from '../../helpers';
import { urlRepository } from '../../routes';
import { basketService } from '../../services/basket.service';
import { bukaCoinService } from '../../services';

class BasketViewModel {
  @observable
  basket: BasketModel[] = [];
  @observable
  paymentMethod: number | null = null;
  @observable
  countsMoreMaxCount: { name: string; maxCount: number }[] = [];
  @observable
  countsNdsMoreMaxCount: { name: string; maxCount: number }[] = [];
  @observable
  balanceBukaCoin: number = 0;

  get paymentMethodCoin(): number {
    return 0;
  }

  get paymentMethodTransfer(): number {
    return -1;
  }

  constructor() {
    this.load();
  }

  @action
  add(product: BasketModel): void {
    this.basket.push(product);
    this.save();
  }

  @action
  remove(productID: number): void {
    const delIndex = this.basket.findIndex((item: BasketModel) => item.productID === productID);
    if (delIndex !== -1) {
      this.basket.splice(delIndex, 1);
    }
    this.save();
  }

  save() {
    if (localStorageHelper) {
      localStorageHelper.setItem('basket', JSON.stringify(this.basket.map((item: BasketModel) => item.serialize())));
      this.saveToRemote();
    }
  }

  saveToRemote() {
    basketService.saveBasketToDB(this.basket);
  }

  @action
  load() {
    if (localStorageHelper) {
      const json = JSON.parse(localStorageHelper.getItem('basket') || '[]');
      this.basket = json.map((basket: BasketModel) => BasketModel.deserialize(basket));
    }
  }

  @action
  loadFromRemote = flow(function* (this: BasketViewModel) {
    if (this.basket.length !== 0) {
      return;
    }

    try {
      const data: BasketModel[] = yield basketService.getRemoteBasketCounts();
      data.forEach((item) => this.basket.push(new BasketModel(item)));
      this.save();
    } catch (error) {
      console.log(error.toString());
    }
  });

  @action
  clear(): void {
    this.basket = this.basketNds;
    this.save();
  }

  @action
  clearNds(): void {
    this.basket = this.basketNotNds;
    this.save();
  }

  @action
  setPaymentMethod(value: number) {
    this.paymentMethod = value;
  }

  @action
  setPaymentMethodIfNotExist(value: number) {
    if (this.paymentMethod == null) {
      this.paymentMethod = value;
    }
  }

  @action
  loadBalanceBukaCoin = flow(function* (this: BasketViewModel) {
    try {
      const data: { balance: number } = yield bukaCoinService.getRemoteBukaCoinBalance();
      this.balanceBukaCoin = data.balance;
      this.paymentMethod = this.paymentMethodCoin;
    } catch (error) {
      console.log(error.toString());
    }
  });

  @computed
  get getBalanceBukaCoin() {
    return this.balanceBukaCoin;
  }

  isActiveMethod(value: number): boolean {
    return this.paymentMethod === value;
  }

  @computed
  get lengthAll() {
    return this.basket.length;
  }

  @computed
  get lengthNotNds() {
    return this.basketNotNds.length;
  }

  @computed
  get lengthNds() {
    return this.basketNds.length;
  }

  @computed
  get fullSum(): number {
    return this.basketNotNds.reduce((sum: number, item: BasketModel) => {
      return sum + item.info.finishPrice * this.countById(item.productID);
    }, 0);
  }

  @computed
  get fullSumNds(): number {
    return this.basketNds.reduce((sum: number, item: BasketModel) => {
      return sum + item.info.finishPrice * this.countById(item.productID);
    }, 0);
  }

  @computed
  get basketNotNds() {
    return this.basket.filter((item) => item.nds !== 1);
  }

  @computed
  get basketNds() {
    return this.basket.filter((item) => item.nds === 1);
  }

  existProduct(productID: number): boolean {
    return this.basket.findIndex((item: PositionBasket) => item.productID === productID) !== -1;
  }

  fetchProducts = flow(function* (this: BasketViewModel) {
    if (this.basket.length === 0) {
      return;
    }
    try {
      const all: BasketProductModel[] = yield ajaxRequest.get(
        urlRepository.getUrl('clientBasket'),
        this.basket.map((item: BasketModel) => item.productID),
      );

      all.forEach((value) => {
        const basketIndex: number = this.basket.findIndex((item: BasketModel) => item.productID === value.id);
        this.basket[basketIndex].nds = value.nds;
        this.basket[basketIndex].info = value;
      });
    } catch (error) {
      console.log(error.toString());
    }
  });

  countById(productID: number): number {
    return this.basket.find((item: BasketModel) => item.productID === productID)?.count || 0;
  }

  sumById(productID: number): number {
    return (
      this.countById(productID) *
      (this.basketNotNds.find((item: BasketModel) => item.productID === productID)?.info.finishPrice || 0)
    );
  }

  sumNdsById(productID: number): number {
    return (
      this.countById(productID) *
      (this.basketNds.find((item: BasketModel) => item.productID === productID)?.info.finishPrice || 0)
    );
  }

  changeCountById(productID: number, count: number) {
    const index: number = this.basket.findIndex((item) => item.productID === productID) as number;
    this.basket[index].changeCount(count);
    this.checkCountsMoreMaxCount();
    this.save();
  }

  @action
  checkCountsMoreMaxCount(): void {
    const notNdsProducts = this.basketNotNds.filter((basket) => {
      return basket.info.maxCountForBuy !== 0 && basket.info.maxCountForBuy < basket.count;
    });
    const ndsProducts = this.basketNds.filter((basket) => {
      return basket.info.maxCountForBuy !== 0 && basket.info.maxCountForBuy < basket.count;
    });
    this.countsMoreMaxCount = notNdsProducts.map((item) => ({
      name: item.info.name,
      maxCount: item.info.maxCountForBuy,
    }));
    this.countsNdsMoreMaxCount = ndsProducts.map((item) => ({
      name: item.info.name,
      maxCount: item.info.maxCountForBuy,
    }));
  }

  @computed
  get isExistMoreMaxCount(): boolean {
    return this.countsMoreMaxCount.length > 0;
  }

  @computed
  get isExistNdsMoreMaxCount(): boolean {
    return this.countsNdsMoreMaxCount.length > 0;
  }

  @computed
  get countsProduct(): { productID: number; count: number }[] {
    return this.basketNotNds.map((item) => ({ productID: item.productID, count: item.count }));
  }

  @computed
  get countsNdsProduct(): { productID: number; count: number }[] {
    return this.basketNds.map((item) => ({ productID: item.productID, count: item.count }));
  }

  @computed
  get isExistCountMoreRemainingPart(): boolean {
    let result = false;
    this.basketNotNds.forEach((basket) => {
      const count = this.basket.find((item) => item.productID === basket.productID);
      if (parseInt(basket.info.remainder as string) < (count?.count as number)) {
        result = true;
      }
    });
    return result;
  }
  @computed
  get isExistNdsCountMoreRemainingPart(): boolean {
    let result = false;
    this.basketNds.forEach((basket) => {
      const count = this.basket.find((item) => item.productID === basket.productID);
      if (parseInt(basket.info.remainder as string) < (count?.count as number)) {
        result = true;
      }
    });
    return result;
  }
}

export default BasketViewModel;
