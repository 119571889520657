import React, { FC, useCallback, useEffect, useRef } from 'react';
import { ReCaptchaProvider, ReCaptchaV3, ReCaptchaV2, EReCaptchaV2Theme, EReCaptchaV2Size } from 'react-recaptcha-x';
import { useTranslation } from 'react-i18next';
import { TRefreshToken } from 'react-recaptcha-x/dist/reCaptchaV3/component/TRefreshToken';

const RecaptchaWrapper: FC<{
  onLoadToken: (token: string | void) => void;
  enableV2: boolean;
}> = ({ onLoadToken, enableV2, children }) => {
  const { t } = useTranslation('recaptcha');

  const interval: any = useRef(null);

  const v3Callback = useCallback(
    (token: string | void, refreshToken: TRefreshToken | void) => {
      if (enableV2) {
        return;
      }
      if (typeof token === 'string') {
        onLoadToken(token);
        if (typeof refreshToken === 'function') {
          if (!interval.current) {
            interval.current = setInterval(() => {
              refreshToken();
            }, 60000);
          }
        }
      }
    },
    [enableV2, onLoadToken],
  );
  const v2Callback = useCallback(
    (token: string | false | Error) => {
      if (typeof token === 'string') {
        onLoadToken(token);
      } else if (typeof token === 'boolean' && !token) {
        console.log('token has expired, user must check the checkbox again');
      } else {
        console.log('error. please check your network connection');
      }
    },
    [onLoadToken],
  );

  useEffect(() => () => clearInterval(interval.current), [interval]);

  return (
    <ReCaptchaProvider
      siteKeyV3={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY}
      siteKeyV2={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
      langCode={t('local')}
      hideV3Badge={true}
    >
      {children}
      <ReCaptchaV3 action="login" callback={v3Callback} />
      {enableV2 ? (
        <ReCaptchaV2
          callback={v2Callback}
          theme={EReCaptchaV2Theme.Light}
          size={EReCaptchaV2Size.Normal}
          id="my-id"
          data-test-id="my-test-id"
          tabindex={0}
        />
      ) : null}
    </ReCaptchaProvider>
  );
};

export default RecaptchaWrapper;
