import React, { useEffect, useState } from 'react';

import { SortOrder } from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, OverlayTrigger, Row, Tooltip, Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

import GeneralTable from '../GeneralTable';
import { catalogService } from '../../../services';
import { Publisher } from '../../../models/publisher';
import { Activation } from '../../../models/activation';
import ModalProductInfo from '../../ModalWindow/ModalProductInfo';
import { Product } from '../../../models/product';
import { BasketChangeButton, CatalogFilter, Currency } from '../../index';

let filterPublishersFunc: any = null;
let filterActivationsFunc: any = null;
let filterNewCatalog: any = null;
let filterNewLicense: any = null;
let filterDiscount: any = null;
let filterPreorder: any = null;
let filterIndividualPrice: any = null;

const CatalogTable = () => {
  const [publishers, setPublishers] = useState<Array<any>>([]);
  const [activations, setActivations] = useState<Array<any>>([]);
  const [switchNewCatalog, setSwitchNewCatalog] = useState<boolean>(false);
  const [switchNewLicense, setSwitchNewLicense] = useState<boolean>(false);
  const [switchDiscount, setSwitchDiscount] = useState<boolean>(false);
  const [switchIndividualPrice, setSwitchIndividualPrice] = useState<boolean>(false);
  const [switchPreorder, setSwitchPreorder] = useState<boolean>(false);
  const [stateModal, setStateModal] = useState<any>({
    show: false,
    productInfo: null,
  });

  const { t } = useTranslation(['catalog-table', 'markers']);

  useEffect(() => {
    catalogService.getRemotePublishersList().then(
      (data: Array<Publisher>) => {
        setPublishers(
          data.map((item: Publisher) => {
            return { value: item.publisherId, label: item.name };
          }),
        );
      },
      (error) => {
        console.log(error);
      },
    );
    catalogService.getRemoteActivationsList().then(
      (data: Array<Activation>) => {
        setActivations(
          data.map((item: Activation) => {
            return { value: item.activationId, label: item.name };
          }),
        );
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  const toggleOpenModal = (id: number) => {
    setStateModal({ show: true });
    catalogService.getRemoteProductDescription(id).then(
      (data: Product) => {
        setStateModal({ show: true, productInfo: data });
      },
      (error: any) => {
        console.log(error);
      },
    );
  };

  const toggleCloseModal = () => setStateModal({ show: false });

  const columns = [
    {
      dataField: 'date_release',
      text: t('fieldDateRelease'),
      sort: true,
      headerStyle: { width: '12%' },
    },
    {
      dataField: 'name',
      text: t('fieldProductName'),
      sort: true,
      formatter: (cellContent: any, row: any) => (
        <>
          <div>
            {cellContent}{' '}
            {+row.is_individual_price ? (
              <Badge pill variant="warning">
                {t('markers:individualPrice')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_new_catalog ? (
              <Badge pill variant="primary">
                {t('markers:newCatalog')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_new_license ? (
              <Badge pill variant="secondary">
                {t('markers:newProduct')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_preorder ? (
              <Badge pill variant="info">
                {t('markers:preorder')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_discount ? (
              <Badge pill variant="danger">
                {t('markers:discount')}
              </Badge>
            ) : (
              ''
            )}
          </div>
        </>
      ),
    },
    {
      dataField: 'publisher',
      text: t('fieldPublisher'),
      sort: true,
      filter: selectFilter({
        options: publishers,
        className: 'd-none',
        getFilter: (filter) => {
          filterPublishersFunc = filter;
        },
      }),
    },
    {
      dataField: 'activation',
      text: t('fieldActivation'),
      sort: true,
      formatter: (cellContent: any, row: any) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-activation-${row.id}`}>{row.activation}</Tooltip>}
        >
          <Image src={`/assets/activation/${row.activation_id}.png`} />
        </OverlayTrigger>
      ),
      filter: selectFilter({
        options: activations,
        className: 'd-none',
        getFilter: (filter) => {
          filterActivationsFunc = filter;
        },
      }),
      align: 'center',
      headerStyle: { width: '8%' },
    },
    {
      dataField: 'remainder',
      text: t('fieldStock'),
      sort: true,
      headerStyle: { width: '8%' },
      formatter: (cellContent: any, row: any) => (row.remainder ? row.remainder : '-'),
    },
    {
      dataField: 'finish_calc_price',
      text: t('fieldPrice'),
      sort: true,
      headerStyle: { width: '10%' },
      formatter: (cellContent: any, row: any) => (
        <Row>
          <Col sm={12}>
            <del>
              <Currency value={row.price_or_discount} />
            </del>
            <em className="text-muted">{` -${row.percent_pr_cv}%`}</em>
          </Col>
          <Col>
            <Currency value={row.finish_calc_price} />{' '}
            {+row.nds ? (
              <Badge pill variant="primary">
                {t('ndsTitle')}
              </Badge>
            ) : (
              ''
            )}
          </Col>
        </Row>
      ),
    },
    {
      dataField: 'info',
      text: '',
      formatter: (cellContent: any, row: Product) => (
        <Row>
          <Col className="form-group">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-info">{t('buttonInfo')}</Tooltip>}>
              <Button variant="info" onClick={() => toggleOpenModal(row.id)} size={'sm'}>
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      ),
      headerStyle: { width: '5%' },
      classes: 'text-center',
    },
    {
      dataField: 'basket',
      text: '',
      formatter: (cellContent: any, row: Product) => (
        <Row>
          <Col>
            <BasketChangeButton productID={row.id} />
          </Col>
        </Row>
      ),
      headerStyle: { width: '5%' },
      classes: 'text-center',
    },

    {
      dataField: 'is_new_catalog',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterNewCatalog = filter) }),
    },
    {
      dataField: 'is_new_license',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterNewLicense = filter) }),
    },
    {
      dataField: 'is_discount',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterDiscount = filter) }),
    },
    {
      dataField: 'is_preorder',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterPreorder = filter) }),
    },
    {
      dataField: 'is_individual_price',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({
        options: {},
        getFilter: (filter) => (filterIndividualPrice = filter),
      }),
    },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'date_release',
      order: 'desc',
    },
  ];

  const handleFilterPublisher = (value: any) => filterPublishersFunc(value);
  const handleFilterActivation = (value: any) => filterActivationsFunc(value);
  const handleNewCatalog = (e: any) => {
    setSwitchNewCatalog(!switchNewCatalog);
    filterNewCatalog(+e.target.checked);
  };
  const handleNewLicense = (e: any) => {
    setSwitchNewLicense(!switchNewLicense);
    filterNewLicense(+e.target.checked);
  };
  const handleDiscount = (e: any) => {
    setSwitchDiscount(!switchDiscount);
    filterDiscount(+e.target.checked);
  };
  const handleIndividualPrice = (e: any) => {
    setSwitchIndividualPrice(!switchIndividualPrice);
    filterIndividualPrice(+e.target.checked);
  };
  const handlePreorder = (e: any) => {
    setSwitchPreorder(!switchPreorder);
    filterPreorder(+e.target.checked);
  };

  const getData: (params: any) => Promise<any> = (params: any) => catalogService.getRemoteCatalog(params);

  return (
    <>
      <CatalogFilter
        handleNewCatalog={handleNewCatalog}
        switchNewCatalog={switchNewCatalog}
        handleNewLicense={handleNewLicense}
        switchNewLicense={switchNewLicense}
        handleDiscount={handleDiscount}
        switchDiscount={switchDiscount}
        handlePreorder={handlePreorder}
        switchPreorder={switchPreorder}
        handleIndividualPrice={handleIndividualPrice}
        switchIndividualPrice={switchIndividualPrice}
        handleFilterPublisher={handleFilterPublisher}
        handleFilterActivation={handleFilterActivation}
        publishers={publishers}
        activations={activations}
      />
      <GeneralTable defaultSorted={defaultSorted} columns={columns} getData={getData} filter={filterFactory()} />

      <ModalProductInfo show={stateModal.show} toggleClose={toggleCloseModal} productInfo={stateModal.productInfo} />
    </>
  );
};

export default CatalogTable;
