import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faHandHolding, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FieldArray, Formik, FormikErrors } from 'formik';
import * as yup from 'yup';
import { Moment } from 'moment';
import { CSwitch } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import { DatetimePicker, LoadingButton } from '../../index';
import { Order, ProductsOrder } from '../../../models/order';
import { catalogService, discountService, orderService, userService } from '../../../services';
import { orderStatus } from '../../../helpers/order/order-status';
import { statusesIssue } from '../../../helpers/order/statuses-issue';
import { history, toastHelper } from '../../../helpers';
import { validation } from '../../../helpers/validation';

const OrderEditForm: React.FC<{ isEdit: boolean; id: number }> = ({ isEdit, id }) => {
  const [errorGiveAway, setErrorGiveAway] = useState<string | null>(null);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loadingGiveAway, setLoadingGiveAway] = useState<boolean>(false);
  const [statusGiveAwayList, setStatusGiveAwayList] = useState<string[]>([]);
  const [stateStatusOrder, setStateStatusOrder] = useState<Array<any>>([]);
  const [status, setStatus] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [discountsList, setDiscountsList] = useState<any[]>([]);
  const [forceRender, setForceRender] = useState<boolean>(false);

  const STATUS_CANCELED_BUYER = 4;
  const STATUS_CANCELED_SELLER = 6;
  const STATUS_AUTOCANCEL = 10;

  const initialValues: Order = {
    userEmail: '',
    userId: null,
    dateCreate: '',
    dateIssue: undefined,
    datePayment: undefined,
    id: 0,
    invoiceId: 0,
    invoiceNumber: '',
    isPaid: false,
    nds: undefined,
    orderNumber: '',
    products: [],
    status: 1,
    statusIssue: 0,
    statusOrderTitle: '',
    sum: 0,
  };
  const [order, setOrder] = useState<Order>(initialValues);

  const { t } = useTranslation(['orders', 'buttons', 'dates', 'form', 'search-element', 'notifications']);

  const STATUS_FULL_GIVE_AWAY = 8;

  const schema = yup.object({
    datePayment: yup.string().nullable().matches(validation.datetimeFormat, t('form:error.notValidDatetime')),
    userId: yup.mixed().nullable().required(t('form:error.requiredField')),
    products: yup.array().of(
      yup.object({
        productId: yup.mixed().nullable().required(t('form:error.requiredField')),
        count: yup
          .number()
          .integer(t('form:error.integerNumber'))
          .when('stock', (stock: any, schema: any) => {
            return stock > 0
              ? schema.max(stock, t('form:error.maxValue', { value: stock }))
              : schema.max(10000, 'error');
          })
          .min(0, t('form:error.minValue', { value: 0 }))
          .required(t('form:error.requiredField')),
        price: yup
          .number()
          .min(0, t('form:error.minValue', { value: 0 }))
          .required(t('form:error.requiredField')),
      }),
    ),
  });
  const productForAdd: ProductsOrder = {
    discountId: 0,
    isGivenAway: false,
    orderProductId: 0,
    productId: null,
    productName: '',
    count: 0,
    price: 0,
    discountName: '',
    countFormat: '',
    existCount: 0,
    expectedCount: 0,
    isFull: 0,
    name: '',
    priceOne: 0,
    sum: 0,
    stock: 0,
  };

  useEffect(() => {
    const statusList = orderStatus.map((item) => ({ value: item.value, label: t(item.label) }));
    setStateStatusOrder(statusList);
    const list: any = statusesIssue.map((x) => t(x.label));
    setStatusGiveAwayList(list);

    if (!isEdit) {
      setStatus(statusList.find((item) => item.value === 1));
      return;
    }
    orderService
      .getRemoteOrder(id)
      .then((response: Order) => {
        setOrder(response);
        setUser({ value: response.userId, label: response.userEmail });
        setStatus(statusList.find((item) => item.value === response.status));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [t, id, isEdit, forceRender]);

  const onGiveAwayOrder = () => {
    setLoadingGiveAway(true);
    orderService.giveAwayOrder(id).then(
      () => {
        setLoadingGiveAway(false);
        setStatus(stateStatusOrder.find((item) => item.value === STATUS_FULL_GIVE_AWAY));
        toastHelper.success(t('notifications:successOperation'));
        setForceRender(!forceRender);
      },
      (err) => {
        setLoadingGiveAway(false);
        setErrorGiveAway(err);
        console.log(err);
      },
    );
  };

  const handleSubmit = (values: Order, { setSubmitting }: any) => {
    setLoadingForm(true);

    const order: Order = { ...values };

    if (typeof values.userId === 'object') {
      order.userEmail = (values.userId as any).label;
      order.userId = +(values.userId as any).value;
    }
    if (typeof values.status === 'object') {
      order.status = +(values.status as any).value;
    }

    order.products = values.products?.map((item) => {
      const newItem = { ...item };
      if (item.productId !== null && typeof item.productId === 'object') {
        newItem.productName = (item.productId as any).label;
        newItem.productId = +(item.productId as any).value;
      }
      if (item.discountId !== null && typeof item.discountId === 'object') {
        newItem.discountName = (item.discountId as any).label;
        newItem.discountId = +(item.discountId as any).value;
      }
      return newItem;
    });

    const request: Promise<any> = isEdit
      ? orderService.saveOrderAdmin(id, order)
      : orderService.createOrderAdmin(order);
    request.then(
      (response: { id: number }) => {
        toastHelper.success(t('notifications:success'));
        setLoadingForm(false);
        !isEdit && history.push(`/orders/${response.id}`);
        setForceRender(!forceRender);
      },
      (error) => {
        toastHelper.error(`Error: ${error}`);
        setSubmitting(false);
        console.log(error);
        setLoadingForm(false);
      },
    );
  };

  const searchClient = (value: string) => {
    return userService.findUserByValue(value).then(
      (response) => response,
      (error) => console.log(error),
    );
  };
  const searchProduct = (value: string) => {
    return catalogService.findProductForSelectControl(value).then(
      (response) => response,
      (error) => console.log(error),
    );
  };
  const onLoadPriceProduct = (
    id: number,
    params: { products?: number[]; userId: number },
    field: string,
    setFieldValue: any,
  ) => {
    catalogService.getCalcPriceAndDiscountsByClient(id, params).then(
      (response: { price: number; stock: number; discounts: any[] }) => {
        setFieldValue(field + 'price', response.price);
        setFieldValue(field + 'stock', response.stock);
        setDiscountsList(response.discounts.map((item: any) => ({ value: item.id, label: item.name })));
      },
      (error) => console.log(error),
    );
  };
  const onLoadPriceDiscount = (
    id: number,
    params: { products?: number[]; userId: number },
    field: string,
    setFieldValue: any,
  ) => {
    discountService.getProductPrices(id, params).then(
      (response: any) => {
        setFieldValue(field, response[0].finishPrice);
      },
      (error) => console.log(error),
    );
  };
  const onDownloadOrder = () => {
    orderService.downloadOrder(order);
  };
  let onAdd: (product: ProductsOrder) => {};

  const style = { width: '8%' };
  return (
    <Card>
      <Formik validationSchema={schema} initialValues={order} enableReinitialize={true} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
            <Card.Header>
              <LoadingButton type={'submit'} size={'sm'} loading={loadingForm} variant={'success'}>
                <FontAwesomeIcon icon={faSave} /> {t(isEdit ? 'buttons:edit' : 'buttons:save')}
              </LoadingButton>
              {values.id > 0 &&
              isEdit &&
              values.statusIssue !== 1 &&
              ![STATUS_CANCELED_BUYER, STATUS_CANCELED_SELLER, STATUS_AUTOCANCEL].includes(status?.value) ? (
                <LoadingButton
                  loading={loadingGiveAway}
                  size={'sm'}
                  variant="primary"
                  onClick={() => onGiveAwayOrder()}
                  className={'ml-1'}
                >
                  <FontAwesomeIcon icon={faHandHolding} /> {t('btnGiveAwayOrder')}
                </LoadingButton>
              ) : null}
            </Card.Header>
            <Card.Body>
              {errorGiveAway && <Alert variant={'danger'}>{errorGiveAway}</Alert>}
              {[
                { field: 'orderNumber', trans: 'fieldNumber', type: 'static' },
                { field: 'invoiceNumber', trans: 'fieldInvoice', type: 'static' },
                { field: 'dateCreate', trans: 'fieldDateCreate', type: 'static' },
                { field: 'dateIssue', trans: 'fieldDateIssue', type: 'static' },
                { field: 'datePayment', trans: 'fieldDatePayment', type: 'date' },
                { field: 'isPaid', trans: 'fieldPaymentState', type: 'check' },
                { field: 'userId', trans: 'fieldClient', type: 'asyncselect' },
                { field: 'status', trans: 'fieldStatus', type: 'select' },
              ].map(({ field, trans, type }) => {
                let errorText = errors[field];
                return (
                  <div key={field}>
                    {(!isEdit && type !== 'static') || isEdit ? (
                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t(trans)}
                        </Form.Label>
                        <Col sm={10}>
                          {type === 'static' ? <Form.Control plaintext readOnly defaultValue={values[field]} /> : null}
                          {type === 'date' ? (
                            <DatetimePicker
                              name={field}
                              locale={t('dates:locale')}
                              onChangeDate={(date: Moment | any) => {
                                let value = date;
                                if (typeof date === 'object') {
                                  value = date.format('YYYY-MM-DD hh:mm:ss');
                                }
                                setFieldValue(field, value);
                              }}
                              value={values[field]}
                              isInvalid={!!errorText}
                            />
                          ) : null}
                          {type === 'check' ? (
                            <CSwitch
                              className={'mx-1'}
                              id={field}
                              name={field}
                              variant={'3d'}
                              color={'success'}
                              labelOn={'\u2713'}
                              labelOff={'\u2715'}
                              onChange={handleChange}
                              checked={values[field]}
                            />
                          ) : null}
                          {type === 'asyncselect' ? (
                            <AsyncSelect
                              className={!!errorText ? 'is-invalid' : ''}
                              name={field}
                              type={'text'}
                              onChange={(option) => {
                                setUser(option);
                                setFieldValue(field, option);
                              }}
                              isClearable
                              cacheOptions
                              value={user}
                              placeholder={t('search-element:placeholder.clientEmail')}
                              loadOptions={searchClient}
                            />
                          ) : null}
                          {type === 'select' ? (
                            <Select
                              name={field}
                              options={stateStatusOrder}
                              onChange={(option) => {
                                setStatus(option);
                                setFieldValue(field, option);
                              }}
                              value={status}
                            />
                          ) : null}
                          <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    ) : null}
                  </div>
                );
              })}

              <Form.Group as={Row}>
                {values.userId && (
                  <Col sm={'auto'} className="pr-0">
                    <Button variant={'primary'} onClick={() => onAdd(productForAdd)} size={'sm'}>
                      <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons:add')}
                    </Button>
                  </Col>
                )}
                {values?.statusIssue === 1 ? (
                  <Col sm={'auto'}>
                    <Button variant={'warning'} onClick={() => onDownloadOrder()} size={'sm'}>
                      <FontAwesomeIcon icon={faFileExcel} /> {t('buttons:tooltipDownload')}
                    </Button>
                  </Col>
                ) : null}
              </Form.Group>
              <Table responsive={'lg'} size="sm">
                <thead>
                  <tr>
                    <th>{t('fieldProductName')}</th>
                    <th style={style}>{t('fieldCount')}</th>
                    <th style={style}>{t('fieldPrice')}</th>
                    <th style={style}>{t('fieldSum')}</th>
                    <th style={style}>{t('fieldDiscount')}</th>
                    <th style={style}>{t('fieldStock')}</th>
                    <th style={style}>{t('fieldStatusProduct')}</th>
                    <th style={{ width: '3%' }} />
                  </tr>
                </thead>
                <tfoot>
                  {[t('footerCount'), t('footerSum')].map((text, ind) => {
                    return (
                      <tr key={ind}>
                        <td />
                        <td />
                        <td>{text}</td>
                        <td>
                          {!ind
                            ? values?.products?.reduce((count: number, item: ProductsOrder) => count + item.count, 0)
                            : values?.products?.reduce(
                                (sum: number, item: ProductsOrder) => sum + item.count * item.price,
                                0,
                              )}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    );
                  })}
                </tfoot>
                <tbody>
                  <FieldArray
                    name="products"
                    render={({ unshift, remove }) => {
                      onAdd = unshift;
                      let errorText: any;
                      return (
                        <>
                          {values?.products?.length > 0 &&
                            values.products?.map((product, key) => (
                              <tr key={`${product.orderProductId}_${key}`}>
                                {[
                                  { field: 'productName', type: 'static' },
                                  { field: 'count', type: 'number' },
                                  { field: 'price', type: 'number' },
                                  { field: 'sum', type: 'static' },
                                  { field: 'discountName', type: 'static' },
                                  { field: 'stock', type: 'static' },
                                  { field: 'isGivenAway', type: 'static' },
                                ].map(({ field, type }) => {
                                  const mapping: { [k: string]: string } = {
                                    productName: 'productId',
                                    discountName: 'discountId',
                                  };
                                  const errorField = mapping[field] !== undefined ? mapping[field] : field;
                                  errorText = ((errors.products as unknown) as FormikErrors<ProductsOrder>[])?.[key]?.[
                                    errorField
                                  ];
                                  const userId =
                                    values.userId?.value !== undefined ? values.userId.value : values.userId;
                                  return (
                                    <td key={`${field}_${key}`}>
                                      {type === 'static' && ['productName', 'discountName'].includes(field) ? (
                                        <>
                                          {product.orderProductId === 0 ? (
                                            <>
                                              {field === 'productName' ? (
                                                <AsyncSelect
                                                  className={!!errorText ? 'is-invalid' : ''}
                                                  name={`products[${key}].${mapping[field]}`}
                                                  type={'text'}
                                                  onChange={(option: any) => {
                                                    setFieldValue(`products[${key}].${mapping[field]}`, option);
                                                    if (option === null) {
                                                      setFieldValue(`products[${key}].price`, 0);
                                                      setFieldValue(`products[${key}].discountId`, null);
                                                      return;
                                                    }

                                                    onLoadPriceProduct(
                                                      option.value,
                                                      {
                                                        userId,
                                                      },
                                                      `products[${key}].`,
                                                      setFieldValue,
                                                    );
                                                  }}
                                                  isClearable
                                                  placeholder={t('search-element:placeholder.product')}
                                                  cacheOptions
                                                  value={product[mapping[field]]}
                                                  loadOptions={searchProduct}
                                                />
                                              ) : (
                                                <Select
                                                  name={`products[${key}].${mapping[field]}`}
                                                  options={discountsList}
                                                  isDisabled={!product.productId}
                                                  placeholder={'...'}
                                                  value={product[mapping[field]]}
                                                  onChange={(option: any) => {
                                                    setFieldValue(`products[${key}].${mapping[field]}`, option);
                                                    if (option === null) {
                                                      setFieldValue(`products[${key}].price`, 0);
                                                      return;
                                                    }
                                                    onLoadPriceDiscount(
                                                      option.value,
                                                      { products: [product.productId.value], userId },
                                                      `products[${key}].price`,
                                                      setFieldValue,
                                                    );
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <Form.Control
                                                name={`products[${key}].${mapping[field]}`}
                                                type={'hidden'}
                                                value={product[mapping[field]]}
                                              />
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip id={`tooltip-${field}-${product.orderProductId}`}>
                                                    {product[field]}
                                                  </Tooltip>
                                                }
                                              >
                                                <LinkContainer
                                                  to={`/${field === 'productName' ? 'catalog' : 'discounts'}/${
                                                    product[mapping[field]]
                                                  }`}
                                                  style={{ cursor: 'pointer', minWidth: '15rem', display: 'block' }}
                                                >
                                                  <span>{product[field]}</span>
                                                </LinkContainer>
                                              </OverlayTrigger>
                                            </>
                                          )}
                                        </>
                                      ) : type === 'static' ? (
                                        field === 'isGivenAway' ? (
                                          statusGiveAwayList[+product[field]]
                                        ) : field === 'sum' ? (
                                          product.count * product.price
                                        ) : (
                                          product[field]
                                        )
                                      ) : null}
                                      {type === 'number' ? (
                                        <Form.Control
                                          style={{ minWidth: '6rem' }}
                                          type={type}
                                          name={`products[${key}].${field}`}
                                          value={product[field]}
                                          onChange={handleChange}
                                          isInvalid={!!errorText}
                                        />
                                      ) : null}
                                      <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
                                    </td>
                                  );
                                })}
                                <td>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-remove-${product.productId}`}>
                                        {t('buttons:removeFromOrder')}
                                      </Tooltip>
                                    }
                                  >
                                    <Button variant="danger" onClick={() => remove(key)}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))}
                        </>
                      );
                    }}
                  />
                </tbody>
              </Table>
            </Card.Body>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default OrderEditForm;
