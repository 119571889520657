import { toast, ToastOptions } from 'react-toastify';

export const toastHelper = { success, error };

function success(message: string, options?: ToastOptions | undefined) {
  toast.success(message, options);
}

function error(message: string) {
  toast.error(message, { autoClose: 10000 });
}
