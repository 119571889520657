import React, { FC, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { userService } from '../../services';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastHelper } from '../../helpers';

const AlertUserIsBlocked: FC<{ userId: number }> = ({ userId }) => {
  const [show, setShow] = useState(true);
  const { t } = useTranslation(['users', 'notifications']);

  const onClick = () => {
    userService.unblockingUser(userId).then(() => {
      setShow(false);
      toastHelper.success(t('notifications:success'));
    }, console.log);
  };

  return (
    <>
      <Alert show={show} variant="warning">
        <Alert.Heading>{t('alertBlocking.header')}</Alert.Heading>
        <p>{t('alertBlocking.body')}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={onClick} variant="outline-success">
            <FontAwesomeIcon icon={faUnlockAlt} />
          </Button>
        </div>
      </Alert>
    </>
  );
};

export default React.memo(AlertUserIsBlocked);
