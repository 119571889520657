import React from 'react';
import GeneralTable from '../GeneralTable';
import { User } from '../../../models/user';
import { userService } from '../../../services';
import { SortOrder } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

const UserShoppingListTable: React.FC<{ userID: number }> = ({ userID }) => {
  const { t } = useTranslation(['orders', 'search-element']);

  const columns: any[] = [
    { dataField: 'id', text: '', hidden: true },
    { dataField: 'invoiceNumber', text: t('fieldInvoice'), sort: true },
    { dataField: 'productName', text: t('fieldProductName'), sort: true },
    { dataField: 'datePayment', text: t('fieldDatePayment'), sort: true },
    { dataField: 'count', text: t('fieldCount'), sort: true },
    { dataField: 'sum', text: t('fieldSum'), sort: true },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'datePayment',
      order: 'desc',
    },
  ];

  const getData: (params: any) => Promise<User[]> = (params: any) => {
    return userService.getUserShoppingList(userID, params);
  };
  return (
    <Card>
      <Card.Header>{t('headerShoppingHistory')}</Card.Header>
      <Card.Body>
        <GeneralTable
          defaultSorted={defaultSorted}
          columns={columns}
          getData={getData}
          placeholderSearch={t('search-element:placeholder.search')}
        />
      </Card.Body>
    </Card>
  );
};

export default UserShoppingListTable;
