import React, { useEffect, useState } from 'react';
import { SortOrder } from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import GeneralTable from '../GeneralTable';
import { Product } from '../../../models/product';
import { catalogService } from '../../../services';
import { toastHelper } from '../../../helpers';
import ModalWarning from '../../ModalWindow/ModalWarning';
import { CatalogFilter, Currency, UpdateProductAPIButton } from '../..';
import { currencyService } from '../../../services/currency.service';
import { Publisher } from '../../../models/publisher';
import { Activation } from '../../../models/activation';

let filterPublishersFunc: any = null;
let filterActivationsFunc: any = null;
let filterNewCatalog: any = null;
let filterNewLicense: any = null;
let filterDiscount: any = null;
let filterPreorder: any = null;
let filterIndividualPrice: any = null;

const CatalogAdminTable: React.FC = () => {
  const [publishers, setPublishers] = useState<{ value: number; label: string }[]>([]);
  const [activations, setActivations] = useState<{ value: number; label: string }[]>([]);
  const [switchNewCatalog, setSwitchNewCatalog] = useState<boolean>(false);
  const [switchNewLicense, setSwitchNewLicense] = useState<boolean>(false);
  const [switchDiscount, setSwitchDiscount] = useState<boolean>(false);
  const [switchIndividualPrice, setSwitchIndividualPrice] = useState<boolean>(false);
  const [switchPreorder, setSwitchPreorder] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deletingID, setDeletingID] = useState<number>(0);

  const { t } = useTranslation(['catalog-table', 'markers', 'notifications', 'buttons']);

  useEffect(() => {
    catalogService.getRemotePublishersList().then(
      (data: Array<Publisher>) => {
        setPublishers(
          data.map((item: Publisher) => {
            return { value: item.publisherId, label: item.name };
          }),
        );
      },
      (error) => {
        console.log(error);
      },
    );
    catalogService.getRemoteActivationsList().then(
      (data: Array<Activation>) => {
        setActivations(
          data.map((item: Activation) => {
            return { value: item.activationId, label: item.name };
          }),
        );
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  const onShowDeleteModal = (id: number) => {
    setShowModal(true);
    setDeletingID(id);
  };

  const onDelete = (id: number) => {
    catalogService.deleteProduct(id).then(
      () => {
        setShowModal(false);
        toastHelper.success(t('notifications:successDelete'));
        filterNewCatalog(+switchNewCatalog);
      },
      (error) => {
        toastHelper.error(t('notifications:error'));
        console.log(error);
      },
    );
  };

  const onUpdateFromAPI = (id: number) => {
    return catalogService.updateFromAPI(id).then(
      () => {
        toastHelper.success(t('notifications:success'));
      },
      (error) => {
        toastHelper.error(t('notifications:error'));
        console.log(error);
      },
    );
  };

  const columns: any[] = [
    { dataField: 'id', text: 'ID', sort: false, headerStyle: { width: '5%' } },
    {
      dataField: 'wholesalePublication',
      text: 'W.P.',
      sort: false,
      headerTitle: () => t('fieldWholesalePublication'),
      headerStyle: { width: '3%' },
    },
    {
      dataField: 'wholesaleVisible',
      text: 'W.V.',
      sort: false,
      headerTitle: () => t('fieldWholesaleVisible'),
      headerStyle: { width: '3%' },
    },
    {
      dataField: 'apiPublication',
      text: 'API P.',
      sort: false,
      headerTitle: () => t('fieldApiPublication'),
      headerStyle: { width: '3%' },
    },
    {
      dataField: 'apiAvailable',
      text: 'API A.',
      sort: false,
      headerTitle: () => t('fieldApiAvailable'),
      headerStyle: { width: '3%' },
    },
    {
      dataField: 'name',
      text: t('fieldProductName'),
      sort: true,
      headerStyle: { width: '40%' },
      formatter: (cellContent: any, row: any) => (
        <>
          <div>
            {cellContent}{' '}
            {+row.is_individual_price ? (
              <Badge pill variant="warning">
                {t('markers:individualPrice')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_new_catalog ? (
              <Badge pill variant="primary">
                {t('markers:newCatalog')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_new_license ? (
              <Badge pill variant="secondary">
                {t('markers:newProduct')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_preorder ? (
              <Badge pill variant="info">
                {t('markers:preorder')}
              </Badge>
            ) : (
              ''
            )}
            {+row.is_discount ? (
              <Badge pill variant="danger">
                {t('markers:discount')}
              </Badge>
            ) : (
              ''
            )}
          </div>
        </>
      ),
    },
    {
      dataField: 'publisher',
      text: t('fieldPublisher'),
      sort: true,
      filter: selectFilter({
        options: publishers,
        className: 'd-none',
        getFilter: (filter) => {
          filterPublishersFunc = filter;
        },
      }),
    },
    {
      dataField: 'price',
      text: t('fieldPrice'),
      sort: true,
      headerStyle: { width: '6%' },
      formatter: (cellContent: any, row: Product) => (
        <>
          <p>
            <Currency value={row.price as number} />
          </p>
          {currencyService.isSet() ? `(${row.sourcePriceFromApi} ₽)` : null}
        </>
      ),
    },
    {
      dataField: 'price_discount',
      text: t('fieldDiscountPrice'),
      sort: true,
      formatter: (cellContent: any, row: Product) => (
        <>
          <p>
            <Currency value={row.priceDiscount as number} />
          </p>
          {currencyService.isSet() ? `(${row.sourcePriceDiscountFromApi} ₽)` : null}
        </>
      ),
      headerStyle: { width: '6%' },
    },
    {
      dataField: 'cnt',
      text: t('fieldStock'),
      sort: true,
      headerStyle: { width: '6%' },
      formatter: (cellContent: any, row: Product) => row.stock,
    },
    {
      dataField: 'buttonEdit',
      text: '',
      formatter: (cellContent: any, row: Product) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-info-${row.id}`}>{t('buttons:edit')}</Tooltip>}
        >
          <LinkContainer to={`/catalog/${row.id}`}>
            <Button variant="info" size={'sm'}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </LinkContainer>
        </OverlayTrigger>
      ),
      classes: 'text-center',
    },

    {
      dataField: 'buttonUpdate',
      text: '',
      formatter: (cellContent: any, row: Product) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-update-${row.id}`}> {t('buttons.updateFromAPI')} </Tooltip>}
        >
          <UpdateProductAPIButton onClick={() => onUpdateFromAPI(row.id)} />
        </OverlayTrigger>
      ),
      classes: 'text-center',
    },

    {
      dataField: 'buttonDelete',
      text: '',
      formatter: (cellContent: any, row: Product) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-delete-${row.id}`}>{t('buttons:delete')}</Tooltip>}
        >
          <Button variant="danger" onClick={() => onShowDeleteModal(row.id)} size={'sm'}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </OverlayTrigger>
      ),
      classes: 'text-center',
    },

    {
      dataField: 'is_new_catalog',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterNewCatalog = filter) }),
    },
    {
      dataField: 'is_new_license',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterNewLicense = filter) }),
    },
    {
      dataField: 'is_discount',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterDiscount = filter) }),
    },
    {
      dataField: 'is_preorder',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterPreorder = filter) }),
    },
    {
      dataField: 'is_individual_price',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({
        options: {},
        getFilter: (filter) => (filterIndividualPrice = filter),
      }),
    },
    {
      dataField: 'filter_activation',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({
        options: activations,
        className: 'd-none',
        getFilter: (filter) => {
          filterActivationsFunc = filter;
        },
      }),
    },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const handleFilterPublisher = (value: any) => filterPublishersFunc(value);
  const handleFilterActivation = (value: any) => filterActivationsFunc(value);
  const handleNewCatalog = (e: any) => {
    setSwitchNewCatalog(!switchNewCatalog);
    filterNewCatalog(+e.target.checked);
  };
  const handleNewLicense = (e: any) => {
    setSwitchNewLicense(!switchNewLicense);
    filterNewLicense(+e.target.checked);
  };
  const handleDiscount = (e: any) => {
    setSwitchDiscount(!switchDiscount);
    filterDiscount(+e.target.checked);
  };
  const handleIndividualPrice = (e: any) => {
    setSwitchIndividualPrice(!switchIndividualPrice);
    filterIndividualPrice(+e.target.checked);
  };
  const handlePreorder = (e: any) => {
    setSwitchPreorder(!switchPreorder);
    filterPreorder(+e.target.checked);
  };

  const getData: (params: any) => Promise<any> = (params: any) => catalogService.getAdminRemoteCatalog(params);

  return (
    <>
      <CatalogFilter
        handleNewCatalog={handleNewCatalog}
        switchNewCatalog={switchNewCatalog}
        handleNewLicense={handleNewLicense}
        switchNewLicense={switchNewLicense}
        handleDiscount={handleDiscount}
        switchDiscount={switchDiscount}
        handlePreorder={handlePreorder}
        switchPreorder={switchPreorder}
        handleIndividualPrice={handleIndividualPrice}
        switchIndividualPrice={switchIndividualPrice}
        handleFilterPublisher={handleFilterPublisher}
        handleFilterActivation={handleFilterActivation}
        publishers={publishers}
        activations={activations}
      />
      <GeneralTable defaultSorted={defaultSorted} columns={columns} getData={getData} filter={filterFactory()} />
      <ModalWarning
        show={showModal}
        toggleClose={() => setShowModal(false)}
        data={deletingID}
        toggleSuccess={onDelete}
        title={t('modal.titleDeleteProduct')}
      >
        <Trans i18nKey={'modal.textWarningDeleteProduct'} t={t}>
          Are you sure you want to delete the product with ID={{ id: deletingID }} and all his data?
          <strong style={{ color: 'red' }}> This operation is irreversible!</strong>
        </Trans>
      </ModalWarning>
    </>
  );
};

export default CatalogAdminTable;
