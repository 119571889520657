import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';

export const catalogService = {
  getRemoteCatalog,
  getRemotePublishersList,
  getRemoteActivationsList,
  getRemoteProductDescription,
  getAdminRemoteCatalog,
  getAdminProduct,
  saveAdminProduct,
  updateFromAPI,
  deleteProduct,
  getCalcPriceAndDiscountsByClient,
  findProductForSelectControl,
  fullUpdateSize,
  fullUpdatePage,
};

function getRemoteCatalog(params: object) {
  return ajaxRequest.get(urlRepository.getUrl('clientCatalog'), params);
}

function getRemotePublishersList() {
  return ajaxRequest.get(urlRepository.getUrl('clientPublishers'));
}

function getRemoteActivationsList() {
  return ajaxRequest.get(urlRepository.getUrl('clientActivations'));
}

function getRemoteProductDescription(id: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('clientCatalogProductInfo', id));
}

function getAdminRemoteCatalog(params: object) {
  return ajaxRequest.get(urlRepository.getUrl('adminCatalog'), params);
}

function getAdminProduct(id: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('adminProduct', id));
}

function saveAdminProduct(id: number, params: object) {
  return ajaxRequest.post(urlRepository.getUrlWithId('adminProduct', id), params);
}

function updateFromAPI(id: number) {
  return ajaxRequest.post(urlRepository.getUrlWithId('adminProductUpdate', id), {});
}

function fullUpdateSize() {
  return ajaxRequest.get(urlRepository.getUrl('adminFullUpdateSize'));
}

function fullUpdatePage() {
  return ajaxRequest.get(urlRepository.getUrl('adminFullUpdatePage'));
}

function deleteProduct(id: number) {
  return ajaxRequest.del(urlRepository.getUrlWithId('adminProduct', id));
}

function findProductForSelectControl(value: string) {
  return ajaxRequest.get(urlRepository.getUrl('adminProductFind'), { value });
}

function getCalcPriceAndDiscountsByClient(id: number, params: { userId: number }) {
  return ajaxRequest.get(urlRepository.getUrlWithId('adminProductPriceDiscountsClient', id), params);
}
