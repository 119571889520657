import React, { FC } from 'react';

const Currency: FC<{ value: number }> = ({ value }) => {
  const currency = process.env.REACT_APP_CURRENCY;
  const formattedValue = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: currency !== 'RUB' ? 2 : 0,
  }).format(value);
  return <>{formattedValue}</>;
};

export default Currency;
