import { action, computed, flow, observable } from 'mobx';
import { ButtonFullUpdateModel } from './ButtonFullUpdateModel';
import { catalogService } from '../../../services';

class ButtonFullUpdateViewModel {
  @observable
  model: ButtonFullUpdateModel;

  constructor() {
    this.model = new ButtonFullUpdateModel();
  }

  @action
  startUpdate = flow(function* (this: ButtonFullUpdateViewModel) {
    try {
      this.setLoading(true);
      const response: { size: number } = yield catalogService.fullUpdateSize();
      this.setSize(response.size);
    } catch (error) {
      console.log(error);
    }
  });

  @action
  updateByPage = flow(function* (this: ButtonFullUpdateViewModel) {
    try {
      const response: { page: number } = yield catalogService.fullUpdatePage();
      this.setPage(response.page);
    } catch (error) {
      console.log(error);
    }
  });

  @action
  setLoading(load: boolean) {
    this.model._loading = load;
  }

  @action
  setPage(now: number) {
    this.model._page = now;
  }

  @action
  setSize(now: number) {
    this.model._size = now;
  }

  get page() {
    return this.model._page;
  }

  get size() {
    return this.model._size;
  }

  @computed
  get progress(): number {
    return Math.ceil((this.page * 100) / this.size);
  }

  @computed
  get isLoading(): boolean {
    return this.model._loading;
  }

  @computed
  get isEndUpdate(): boolean {
    return this.model._size <= this.model._page;
  }
}

export default ButtonFullUpdateViewModel;
