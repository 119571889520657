import React, { useEffect, useState } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import './FormSingIn.scss';
import { authenticationService } from '../../services';
import { LoadingButton } from '../index';
import RecaptchaWrapper from '../Recaptcha/RecaptchaWrapper';

interface FormState {
  email: string;
  password: string;
}

const FormSingIn: any = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [tokenRecaptcha, setTokenRecaptcha] = useState<string | void>('');
  const [reCaptchaV2, setReCaptchaV2] = useState<boolean>(false);
  const [errorIP, setErrorIP] = useState<string | void>('');

  const { t } = useTranslation('sign-in');

  useEffect(() => {
    if (authenticationService.ipUser && authenticationService.ipUser === 'undefined') {
      const error = t('errorIP');
      setErrorIP(error);
    }
    if (process.env.NODE_ENV !== 'production') {
      setTokenRecaptcha('fake');
    }
  }, [t]);

  const schema = yup.object({
    email: yup.string().email(t('notValidEmail')).required(t('requireEmail')),
    password: yup.string().required(t('requirePassword')),
  });

  const onLoadToken = (token: string | void) => setTokenRecaptcha(token);

  const handleSubmit = (values: any, { setSubmitting, setStatus }: any) => {
    if (!tokenRecaptcha) {
      setStatus(t('errorRecaptchaToken'));
      return;
    }

    setLoading(true);
    setStatus();
    authenticationService
      .login(values.email, values.password, tokenRecaptcha, reCaptchaV2 ? 2 : 3)
      .then(() => {
        setLoading(false);
        const { from } = props.location.state || { from: { pathname: '/' } };
        props.history.push(from);
      })
      .catch((error: string) => {
        setLoading(false);
        setSubmitting(false);
        if (error.includes('Recaptcha')) {
          setReCaptchaV2(true);
          setTokenRecaptcha('');
          setStatus(t('errorRecaptchaToken'));
        } else if (error.includes('login attempts')) {
          setStatus(t('errorBlockingUser'));
        } else {
          setStatus(t('errorSubmit'));
        }
      });
  };

  const initialState: FormState = {
    email: '',
    password: '',
  };
  return (
    <Formik validationSchema={schema} initialValues={initialState} onSubmit={handleSubmit}>
      {({ handleSubmit, handleChange, values, errors, status }) => (
        <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
          <h1 className="h3 mb-3 font-weight-normal">{t('welcomeText')}</h1>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="sr-only">{t('placeholderEmail')}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder={t('placeholderEmail')}
              value={values.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label className="sr-only">{t('placeholderPassword')}</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder={t('placeholderPassword')}
              value={values.password}
              isInvalid={!!errors.password}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          {process.env.NODE_ENV !== 'production' ? (
            <Form.Group>
              <LoadingButton variant="primary" type="submit" loading={loading} className={'py-1 px-4'}>
                {t('signIn')}
              </LoadingButton>
            </Form.Group>
          ) : (
            <RecaptchaWrapper onLoadToken={onLoadToken} enableV2={reCaptchaV2}>
              <Form.Group>
                <LoadingButton variant="primary" type="submit" loading={loading} className={'py-1 px-4'}>
                  {t('signIn')}
                </LoadingButton>
              </Form.Group>
              <span />
            </RecaptchaWrapper>
          )}
          <Form.Group>
            <Button variant={'link'} onClick={() => props.onReg()}>
              {t('reg.label')}
            </Button>
          </Form.Group>
          {status && <Alert variant="danger">{status}</Alert>}
          {errorIP && <Alert variant="danger">{errorIP}</Alert>}
        </Form>
      )}
    </Formik>
  );
};

export default FormSingIn;
