import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

const AlertCountMoreRemainingPart: React.FC = () => {
  const { t } = useTranslation('basket');
  return <Alert variant="danger">{t('basket:create.errorCountMoreRemainingPart')}</Alert>;
};

export default AlertCountMoreRemainingPart;
