import { observable } from 'mobx';

export class ButtonFullUpdateModel {
  @observable
  _loading: boolean;
  @observable
  _page: number;
  @observable
  _size: number;

  constructor() {
    this._loading = false;
    this._page = 0;
    this._size = 0;
  }
}
