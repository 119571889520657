import React from 'react';
import { Moment } from 'moment';
import Datetime from 'react-datetime';

interface DatetimePicker extends React.HTMLProps<HTMLInputElement> {
  onChangeDate: (data: Moment | any) => void;
  value: string;
  locale: string;
  isInvalid: boolean;
}

const DatetimePicker: React.FC<DatetimePicker> = ({ onChangeDate, value, locale, isInvalid, ...rest }) => {
  return (
    <Datetime
      className={isInvalid ? 'is-invalid' : ''}
      inputProps={rest}
      locale={locale}
      dateFormat={'YYYY-MM-DD'}
      timeFormat={'hh:mm:ss'}
      onChange={onChangeDate}
      value={value}
    />
  );
};

export default DatetimePicker;
