import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
  Col,
  Form,
  Badge,
  OverlayTrigger,
  Tooltip,
  Table,
  Alert,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';

import { CreateOrderButton } from '../index';
import { DiscountProduct } from '../../models/discount-product';
import CreateOrderStore from './CreateOrderStore';
import { orderService } from '../../services';
import ClientSettingStore from '../ClientSetting/ClientSettingStore';
import AlertMoreMaxSumOrder from '../Alert/AlertMoreMaxSumOrder';
import AlertMoreMaxCountProductOrder from '../Alert/AlertMoreMaxCountProductOrder';
import { Link } from 'react-router-dom';
import AlertCountMoreRemainingPart from '../Alert/AlertCountMoreRemainingPart';

const ModalCreateOrder: React.FC<{
  show: boolean;
  toggleClose: () => void;
  discountID: number;
  createOrderStore: CreateOrderStore;
  clientSettingStore: ClientSettingStore;
}> = observer(({ show, toggleClose, discountID, createOrderStore, clientSettingStore }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createdOrder, setCreatedOrder] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation(['modal', 'orders', 'markers', 'basket', 'translation']);

  useEffect(() => {
    if (createOrderStore.lengthCounts > 0) {
      createOrderStore.loadProductPrices(discountID);
      clientSettingStore.loadSettingsPurchases();
    }
  }, [show, createOrderStore, discountID, clientSettingStore]);

  const onCreateOrder = () => {
    setLoading(true);
    const params = {
      products: createOrderStore.productCounts,
      discountID: discountID,
    };
    orderService.createOrder(params).then(
      (response: { orderNumber: string }) => {
        setLoading(false);
        setCreatedOrder(response.orderNumber);
        createOrderStore.clear();
      },
      (error) => {
        setLoading(false);
        console.log(error);
        setError(error);
      },
    );
  };

  const style = { width: '9%' };
  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={toggleClose}
        aria-labelledby="modal-styling-title"
        className={'modal-danger'}
      >
        <ModalHeader closeButton>
          <ModalTitle id="modal-styling-title">{t('titleCreateOrderModal')}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Table size="sm">
            <thead>
              <tr>
                <th>{t('orders:fieldProductName')}</th>
                <th style={style}>{t('orders:fieldStock')}</th>
                <th style={style}>{t('orders:fieldPrice')}</th>
                <th style={style}>{t('orders:fieldCount')}</th>
                <th style={style}>{t('orders:fieldSum')}</th>
                <th style={{ width: '3%' }} />
              </tr>
            </thead>
            <tfoot>
              <tr>
                <td />
                <td />
                <td>{t('basket:create.total')}</td>
                <td />
                <td>{createOrderStore.allSum || 0}</td>
                <td />
              </tr>
            </tfoot>
            <tbody>
              {createOrderStore.productPrices.map((item: DiscountProduct) => (
                <tr key={item.id}>
                  <td>
                    {item.productName}
                    <div>
                      {item.isPreorder ? (
                        <Badge pill variant="info">
                          {t('markers:preorder')}
                        </Badge>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                  <td>{item.count}</td>
                  <td>
                    <Row>
                      <Col sm={'12'}>
                        <del>{item.sourcePrice}</del>
                        <em className="text-muted">{` -${item.percent}%`}</em>
                      </Col>
                      <Col>{item.finishPrice}</Col>
                    </Row>
                  </td>
                  <td>
                    <Form.Control
                      type={'number'}
                      value={createOrderStore.countById(item.id)}
                      min={1}
                      onChange={(e) => createOrderStore.changeCountByProduct(item.id, +e.target.value)}
                    />
                  </td>
                  <td>{createOrderStore.sumById(item.id)}</td>
                  <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-delete-${item.id}`}>{t('buttons:remove')}</Tooltip>}
                    >
                      <Button variant={'danger'} onClick={() => createOrderStore.remove(item.id)}>
                        {<FontAwesomeIcon icon={faTrashAlt} />}
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {error && <Alert variant="danger">{error}</Alert>}
          {createOrderStore.isExistCountMoreRemainingPart ? <AlertCountMoreRemainingPart /> : null}
          {createOrderStore.isExistMoreMaxCount ? (
            <AlertMoreMaxCountProductOrder products={createOrderStore.countsMoreMaxCount} />
          ) : null}
          <AlertMoreMaxSumOrder clientSettingStore={clientSettingStore} allSum={createOrderStore.allSum} />
          {createdOrder && (
            <Alert variant="success">
              {t('basket:create.createSuccess')} <Link to={`/orders/${createdOrder}`}>№{createdOrder}</Link>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="danger" onClick={toggleClose}>
            {t('buttonCancel')}
          </Button>
          {createOrderStore.lengthCounts > 0 &&
          !createOrderStore.isExistMoreMaxCount &&
          !createOrderStore.isExistCountMoreRemainingPart ? (
            <CreateOrderButton
              loading={loading}
              onClick={() => onCreateOrder()}
              allSum={createOrderStore.allSum}
              clientSettingStore={clientSettingStore}
            />
          ) : null}
        </ModalFooter>
      </Modal>
    </>
  );
});

export default ModalCreateOrder;
