import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';
import { Discount } from '../models/discount';

export const discountService = {
  getRemoteDiscounts,
  getRemoteDiscountDetail,
  getRemoteDiscountProducts,
  getProductPrices,
  downloadDiscount,
};

function getRemoteDiscounts(params: object) {
  return ajaxRequest.get(urlRepository.getUrl('clientDiscounts'), params);
}

function getRemoteDiscountDetail(id: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('clientDiscountOne', id));
}

function getRemoteDiscountProducts(id: number, params: object) {
  return ajaxRequest.get(urlRepository.getUrlWithId('clientDiscountProducts', id), params);
}

function getProductPrices(id: number, params: number[] | { products?: number[]; userId: number }) {
  return ajaxRequest.get(urlRepository.getUrlWithId('clientDiscountProductsPrices', id), params);
}

function downloadDiscount(discount: Discount) {
  ajaxRequest
    .downloadFile(
      urlRepository.getUrlWithId('clientDownloadDiscount', discount.id),
      `#${discount?.id}_${discount?.name}.xlsx`,
    )
    .then();
}
