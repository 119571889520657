import React, { FC } from 'react';
import { Col, Form, FormLabel, Row } from 'react-bootstrap';
import { CSwitch } from '@coreui/react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const CatalogFilter: FC<{
  handleNewCatalog: any;
  switchNewCatalog: boolean;
  handleNewLicense: any;
  switchNewLicense: boolean;
  handleDiscount: any;
  switchDiscount: boolean;
  handlePreorder: any;
  switchPreorder: boolean;
  handleIndividualPrice: any;
  switchIndividualPrice: boolean;
  handleFilterPublisher: any;
  handleFilterActivation: any;
  publishers: any;
  activations: any;
}> = ({
  handleNewCatalog,
  switchNewCatalog,
  handleNewLicense,
  switchNewLicense,
  handleDiscount,
  switchDiscount,
  handlePreorder,
  switchPreorder,
  handleIndividualPrice,
  switchIndividualPrice,
  handleFilterPublisher,
  handleFilterActivation,
  publishers,
  activations,
}) => {
  const { t } = useTranslation(['catalog-table', 'markers']);

  return (
    <>
      <Row>
        <Col md={2} as={Form.Group}>
          <CSwitch
            size="lg"
            className={'mx-1 float-left'}
            variant={'3d'}
            color={'primary'}
            labelOn={'\u2713'}
            labelOff={'\u2715'}
            onChange={handleNewCatalog}
            checked={switchNewCatalog}
          />
          <FormLabel>{t('markers:newCatalog')}</FormLabel>
        </Col>
        <Col md={2} as={Form.Group}>
          <CSwitch
            size="lg"
            className={'mx-1 float-left'}
            variant={'3d'}
            color={'secondary'}
            labelOn={'\u2713'}
            labelOff={'\u2715'}
            onChange={handleNewLicense}
            checked={switchNewLicense}
          />
          <FormLabel>{t('markers:newProduct')}</FormLabel>
        </Col>
        <Col md={2} as={Form.Group}>
          <CSwitch
            size="lg"
            className={'mx-1 float-left'}
            variant={'3d'}
            color={'danger'}
            labelOn={'\u2713'}
            labelOff={'\u2715'}
            onChange={handleDiscount}
            checked={switchDiscount}
          />
          <FormLabel>{t('markers:discount')}</FormLabel>
        </Col>
        <Col md={2} as={Form.Group}>
          <CSwitch
            size="lg"
            className={'mx-1 float-left'}
            variant={'3d'}
            color={'info'}
            labelOn={'\u2713'}
            labelOff={'\u2715'}
            onChange={handlePreorder}
            checked={switchPreorder}
          />
          <FormLabel>{t('markers:preorder')}</FormLabel>
        </Col>
        <Col md={3} as={Form.Group}>
          <CSwitch
            size="lg"
            className={'mx-1 float-left'}
            variant={'3d'}
            color={'warning'}
            labelOn={'\u2713'}
            labelOff={'\u2715'}
            onChange={handleIndividualPrice}
            checked={switchIndividualPrice}
          />
          <FormLabel>{t('markers:individualPrice')}</FormLabel>
        </Col>
      </Row>

      <Row>
        <Col md={6} as={Form.Group}>
          <Select
            options={publishers}
            onChange={handleFilterPublisher}
            isMulti
            isClearable
            placeholder={t('placeholderSelectPublisher')}
          />
        </Col>
        <Col md={6} as={Form.Group}>
          <Select
            options={activations}
            onChange={handleFilterActivation}
            isMulti
            isClearable
            placeholder={t('placeholderSelectActivation')}
          />
        </Col>
      </Row>
    </>
  );
};

export default CatalogFilter;
