import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { Loading } from '..';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ children, loading, ...rest }) => {
  let existText = false;
  React.Children.map(children, (child: any) => {
    if (child.type === undefined) {
      existText = true;
      return;
    }
  });
  return (
    <Button disabled={loading} {...rest}>
      {loading ? (
        <>
          {existText ? (
            <>
              {children} {<Loading min />}
            </>
          ) : (
            <Loading min />
          )}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default React.memo(LoadingButton);
