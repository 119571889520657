const getNav = (t: (s: string) => string) => {
  return [
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientCatalog'),
      to: '/catalog',
      fontIcon: 'fas fa-store',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientDiscounts'),
      to: '/discounts',
      fontIcon: 'fas fa-fire-alt',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientInvoices'),
      to: '/invoices',
      fontIcon: 'fas fa-receipt',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientOrders'),
      to: '/orders',
      fontIcon: 'fas fa-file-invoice',
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navTransfer'),
      to: '/transfer',
      fontIcon: 'fas fa-landmark',
    },
    {
      _tag: 'CSidebarNavDropdown',
      name: t('navClientContract'),
      route: '/contract',
      fontIcon: 'fas fa-file-contract',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: t('navClientContractLicense'),
          to: '/contract/license',
          fontIcon: 'fas fa-file',
        },
        {
          _tag: 'CSidebarNavItem',
          name: t('navClientContractDigitalService'),
          to: '/contract/digitalService',
          fontIcon: 'fas fa-file',
        },
      ],
    },
    {
      _tag: 'CSidebarNavItem',
      name: t('navClientHelp'),
      to: '/help',
      fontIcon: 'fas fa-question',
    },
  ];
};

export default getNav;
