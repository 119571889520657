import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { LoadingButton } from '../index';

const UpdateProductAPIButton: React.FC<{ onClick: () => Promise<any> }> = ({ onClick, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      onClick().then(
        () => setLoading(false),
        () => setLoading(false),
      );
    }
  }, [onClick, loading]);

  return (
    <LoadingButton variant="primary" onClick={() => setLoading(true)} loading={loading} {...rest} size={'sm'}>
      <FontAwesomeIcon icon={faSyncAlt} />
    </LoadingButton>
  );
};

export default UpdateProductAPIButton;
