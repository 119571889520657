import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { SortOrder } from 'react-bootstrap-table-next';

import { Badge, Col, Image, OverlayTrigger, Row, Tooltip, Form, Fade, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

import { observer } from 'mobx-react-lite';

import GeneralTable from '../GeneralTable';
import { authenticationService, discountService } from '../../../services';
import { DiscountProduct } from '../../../models/discount-product';
import {
  BasketChangeButton,
  ClientSettingStore,
  AddProductForDelayedButton,
  ModalCreateOrder,
  Currency,
} from '../../index';
import { Discount } from '../../../models/discount';
import { useStores } from '../../../hooks/use-store';
import AlertOrdersOverduePayment from '../../Alert/AlertOrdersOverduePayment';

const DiscountProductsTable: React.FC<{
  match: any;
  discount: Discount;
  clientStore: ClientSettingStore;
}> = observer(({ match, discount, clientStore }) => {
  const isAdmin = authenticationService.isAdmin;
  const [showModal, setShowModal] = useState<boolean>(false);

  const { createOrderStore } = useStores();

  useEffect(() => {
    createOrderStore.clear();
  }, [createOrderStore]);

  const { t } = useTranslation(['catalog-table', 'search-element', 'buttons']);

  const FUTURE_DISCOUNT: number = 1;

  const downloadXlsx = () => {
    discountService.downloadDiscount(discount);
  };

  const columns: any[] = [
    { dataField: 'id', text: 'ID', sort: true, hidden: true },
    { dataField: 'productName', text: t('fieldProductName'), sort: true },
    {
      dataField: 'publisher',
      text: t('fieldPublisher'),
      sort: true,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'activation',
      text: t('fieldActivation'),
      sort: true,
      headerStyle: { width: '8%' },
      align: 'center',
      formatter: (cellContent: any, row: DiscountProduct) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-activation-${row.id}`}>{row.activation}</Tooltip>}
        >
          <Image src={`/assets/activation/${row.activationId}.png`} />
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'cnt',
      text: t('fieldStock'),
      sort: true,
      headerStyle: { width: '8%' },
      align: 'center',
      formatter: (cellContent: any, row: DiscountProduct) => (row.count ? row.count : '-'),
    },
    {
      dataField: 'finish_calc_price',
      hidden: isAdmin,
      text: t('fieldPrice'),
      sort: true,
      headerStyle: { width: '10%' },
      formatter: (cellContent: any, row: DiscountProduct) => (
        <Row>
          <Col sm={12}>
            <del>
              <Currency value={row.sourcePrice} />
            </del>
            <em className="text-muted">{` -${row.percent}%`}</em>
          </Col>
          <Col>
            <Currency value={row.finishPrice} />{' '}
            {+row.nds ? (
              <Badge pill variant="primary">
                {t('ndsTitle')}
              </Badge>
            ) : (
              ''
            )}
          </Col>
        </Row>
      ),
    },
    {
      dataField: 'priceDiscount',
      text: t('fieldRetailPrice'),
      sort: true,
      headerStyle: { width: '8%' },
      hidden: !isAdmin,
    },
    {
      dataField: 'priceWholesaleDiscount',
      text: t('fieldWholesalePrice'),
      sort: true,
      headerStyle: { width: '8%' },
      hidden: !isAdmin,
    },
    {
      dataField: 'basket',
      text: '',
      hidden: isAdmin,
      formatter: (cellContent: any, row: DiscountProduct) => (
        <Row>
          <Col>
            {+row.isEnableDiscount ? (
              <BasketChangeButton productID={row.id} />
            ) : discount.status === FUTURE_DISCOUNT &&
              clientStore.isDelayedPayment &&
              (createOrderStore.nds === null || row.nds === +createOrderStore.nds) ? (
              <AddProductForDelayedButton productID={row.id} nds={row.nds} createOrderStore={createOrderStore} />
            ) : null}
          </Col>
        </Row>
      ),
      headerStyle: { width: '5%' },
    },
  ];

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'productName',
      order: 'asc',
    },
  ];

  const getData: (params: any) => Promise<DiscountProduct[]> = (params: any) =>
    discountService.getRemoteDiscountProducts(match.params.id, params);

  return (
    <>
      <Row>
        <Col md={'auto'} as={Form.Group} className="pr-0">
          <Button variant={'warning'} onClick={() => downloadXlsx()}>
            <FontAwesomeIcon icon={faFileExcel} /> {t('buttons:tooltipDownload')}
          </Button>
        </Col>
        {authenticationService.isClient && clientStore.isDelayedPayment && discount.status === FUTURE_DISCOUNT ? (
          <Fade in={true} appear={true}>
            <Col md={'auto'} as={Form.Group}>
              <Button onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faFileInvoice} /> {t('buttons:createOrder')}
                {createOrderStore.lengthCounts > 0 ? (
                  <Badge pill variant="warning">
                    {createOrderStore.lengthCounts}
                  </Badge>
                ) : null}
              </Button>
            </Col>
          </Fade>
        ) : null}
      </Row>
      <AlertOrdersOverduePayment clientSettingStore={clientStore} />
      <GeneralTable
        defaultSorted={defaultSorted}
        columns={columns}
        getData={getData}
        placeholderSearch={t('search-element:placeholder.product')}
      />
      <ModalCreateOrder
        show={showModal}
        toggleClose={() => setShowModal(false)}
        clientSettingStore={clientStore}
        createOrderStore={createOrderStore}
        discountID={discount.id}
      />
    </>
  );
});

export default DiscountProductsTable;
