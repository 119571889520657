import React, { useEffect, useState, FC } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { RowSelectionType, SortOrder } from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { LinkContainer } from 'react-router-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faBan, faFileExcel, faInfo } from '@fortawesome/free-solid-svg-icons';

import Select from 'react-select';

import DateRangePickerWrapper from '../../DateRangePickerWrapper/DateRangePickerWrapper';
import GeneralTable from '../GeneralTable';
import { Order } from '../../../models/order';
import { orderService } from '../../../services';
import { statusesIssue } from '../../../helpers/order/statuses-issue';
import { orderStatus } from '../../../helpers/order/order-status';
import { ajaxRequest, toastHelper } from '../../../helpers';
import { urlRepository } from '../../../routes';
import { basketService } from '../../../services/basket.service';
import { useStores } from '../../../hooks/use-store';
import PaymentButton from '../../PaymentButton/PaymentButton';
import AlertMoreMaxSumOrder from '../../Alert/AlertMoreMaxSumOrder';
import { Currency } from '../../index';
import transferService from '../../../services/transfer.service';

let filterStatusOrder: any = null;
let filterStatusIssue: any = null;
let filterStartDateFunc: any = null;
let filterEndDateFunc: any = null;

const PaymentOrderTable: FC<any> = (props) => {
  const [stateStatusesIssue, setStateStatusesIssue] = useState<Array<any>>([]);
  const [stateStatusOrder, setStateStatusOrder] = useState<Array<any>>([]);
  const [selectedRow, setSelectedRow] = useState<number[]>([]);
  const [amountToPay, setAmountToPay] = useState<number>(0);
  const [sumAllOrders, setSumAllOrders] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorPayment, setErrorPayment] = useState<string | null>(null);
  const [createdTransfer, setCreatedTransfer] = useState<string | null>(null);

  const { t } = useTranslation(['orders', 'buttons', 'search-element', 'dates', 'basket']);
  const { basketViewModel, clientSettingStore } = useStores();

  useEffect(() => {
    const notStatus: number[] = [1, 2, 4, 6, 10, 9];
    let statuses = orderStatus.map((item) => ({ value: item.value, label: t(item.label) }));
    statuses = statuses.filter((item) => {
      return !notStatus.includes(item.value);
    });
    setStateStatusOrder(statuses);
    setStateStatusesIssue(statusesIssue.map((item: any) => ({ value: item.value, label: t(item.label) })));
    orderService.getRemoteSumOrdersAwaitPay().then((response: { sum: number }) => {
      setSumAllOrders(response.sum);
    });
  }, [t]);

  const downloadOrder = (order: Order) => {
    ajaxRequest.downloadFile(
      urlRepository.getUrlWithId('clientDownloadOrderKey', order.id),
      `order_${order.orderNumber}.xlsx`,
    );
  };
  const downloadOrderProduct = (order: Order, productID: number) => {
    ajaxRequest.downloadFile(
      urlRepository.getUrlWithParams('clientDownloadOrderProductKey', [order.id.toString(), productID.toString()]),
      `order_${order.orderNumber}_product_${productID}.xlsx`,
    );
  };

  const columns: any[] = [
    { dataField: 'id', text: '', hidden: true },
    { dataField: 'orderNumber', text: t('fieldNumber'), sort: true },
    {
      dataField: 'invoiceNumber',
      text: t('fieldInvoice'),
      sort: true,
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'startDate',
      text: t('fieldDateCreate'),
      sort: true,
      headerStyle: { width: '12%' },
      formatter: (cellContent: any, row: Order) => row.dateCreate,
      filter: selectFilter({
        options: {},
        className: 'd-none',
        getFilter: (filter) => (filterStartDateFunc = filter),
      }),
    },
    {
      dataField: 'endDate',
      text: t('fieldDatePayment'),
      sort: true,
      headerStyle: { width: '12%' },
      formatter: (cellContent: any, row: Order) => row.datePayment,
      filter: selectFilter({
        options: {},
        className: 'd-none',
        getFilter: (filter) => (filterEndDateFunc = filter),
      }),
    },
    {
      dataField: 'status',
      text: t('fieldStatus'),
      sort: true,
      formatter: (cellContent: any, row: Order) => stateStatusOrder.find((item) => item.value === row.statusID)?.label,
      filter: selectFilter({
        options: stateStatusOrder,
        className: 'd-none',
        getFilter: (filter) => {
          filterStatusOrder = filter;
        },
      }),
    },
    {
      dataField: 'statusIssue',
      text: t('fieldStatusIssue'),
      sort: true,
      headerStyle: { width: '8%' },
      formatter: (cellContent: any, row: Order) => {
        return stateStatusesIssue.find((item) => item.value === row.statusIssue).label;
      },
      filter: selectFilter({
        options: stateStatusesIssue,
        className: 'd-none',
        getFilter: (filter) => {
          filterStatusIssue = filter;
        },
      }),
    },
    { dataField: 'sum', text: t('fieldSum'), sort: true, headerStyle: { width: '8%' } },

    {
      dataField: 'buttonInfo',
      text: '',
      classes: 'text-center',
      formatter: (cellContent: any, row: Order) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-order-info-${row.id}`}>{t('infoOrderTooltip')}</Tooltip>}
        >
          <LinkContainer to={`/orders/${row.id}`}>
            <Button variant="info" size={'sm'}>
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </LinkContainer>
        </OverlayTrigger>
      ),
    },

    {
      dataField: 'buttonFile',
      text: '',
      classes: 'text-center',
      formatter: (cellContent: any, row: Order) => (
        <>
          {row.statusIssue === 1 ? (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-download-${row.id}`}>{t('buttons:tooltipDownload')}</Tooltip>}
            >
              <Button
                variant="warning"
                onClick={(e) => {
                  e.stopPropagation();
                  downloadOrder(row);
                }}
                size={'sm'}
              >
                <FontAwesomeIcon icon={faFileExcel} />
              </Button>
            </OverlayTrigger>
          ) : (
            ' '
          )}
        </>
      ),
    },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'startDate',
      order: 'desc',
    },
  ];

  const expandRow: any = {
    renderer: (row: Order) => {
      const style = { width: '5%' };
      return (
        <Table bordered size="sm">
          <thead>
            <tr>
              <th>{t('fieldProductName')}</th>
              <th style={style}>{t('fieldCount')}</th>
              <th style={style}>{t('fieldPrice')}</th>
              <th style={style}>{t('fieldSum')}</th>
              <th style={style}>{t('fieldKeys')}</th>
            </tr>
          </thead>
          <tbody>
            {row.products &&
              row.products.map((product) => (
                <tr key={product.productId}>
                  <td>{product.name}</td>
                  <td>
                    {product.existCount}/{product.expectedCount}
                  </td>
                  <td>{product.priceOne}</td>
                  <td>{product.sum}</td>
                  <td>
                    {+product.isFull ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-button-${product.productId}`}>{t('buttons:tooltipDownload')}</Tooltip>
                        }
                      >
                        <Button
                          size="sm"
                          variant={'warning'}
                          onClick={() => downloadOrderProduct(row, product.orderProductId)}
                        >
                          <FontAwesomeIcon icon={faFileExcel} />
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <FontAwesomeIcon icon={faBan} />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }: any) => (
      <FontAwesomeIcon icon={isAnyExpands ? faMinusSquare : faPlusSquare} />
    ),
    expandColumnRenderer: ({ expanded }: any) => <FontAwesomeIcon icon={expanded ? faMinusSquare : faPlusSquare} />,
  };

  const selectRow = {
    mode: 'checkbox' as RowSelectionType,
    clickToSelect: true,
    clickToExpand: true,
    hideSelectAll: true,
    selected: selectedRow,
    onSelect: (row: Order, isSelect: boolean) => {
      let selected: number[];
      let amount: number = amountToPay;
      if (isSelect) {
        selected = [...selectedRow, row.id];
        amount += row.sum;
      } else {
        selected = selectedRow.filter((value) => row.id !== value);
        amount -= row.sum;
      }

      setAmountToPay(amount);
      setSelectedRow(selected);
    },
    classes: 'table-success',
  };

  const getData: (params: any) => Promise<Order[]> = (params: any) => {
    if (props.location.hash === '#overdue') {
      params['overdue'] = 1;
    }
    return orderService.getRemoteOrdersAwaitPay(params);
  };
  const handleFilterStatusOrder = (data: any) => filterStatusOrder(data && data.value);
  const handleFilterStatusIssue = (data: any) => filterStatusIssue(data && data.value);
  const handleFilterStartDate = (date: moment.Moment) => {
    filterStartDateFunc(date && date.toISOString());
    return date;
  };
  const handleFilterEndDate = (date: moment.Moment) => {
    filterEndDateFunc(date && date.toISOString());
    return date;
  };

  const handlePayment = () => {
    const orders: number[] = selectedRow;
    const data: any = {
      orders,
      method: basketViewModel.paymentMethod,
    };

    setLoading(true);
    let query: Promise<any>;
    switch (basketViewModel.paymentMethod) {
      case basketViewModel.paymentMethodCoin:
        query = basketService.paymentByBukaCoin(data);
        break;
      case basketViewModel.paymentMethodTransfer:
        query = transferService.payment(data);
        break;
      default:
        query = basketService.redirectToPayment(data);
    }

    query.then(
      (data) => {
        setLoading(false);
        if (basketViewModel.paymentMethod === basketViewModel.paymentMethodCoin) {
          basketViewModel.loadBalanceBukaCoin();
          toastHelper.success(t('basket:payment.invoiceSuccess', { invoiceNumber: data.invoiceNumber }));
        }
        if (basketViewModel.paymentMethod === basketViewModel.paymentMethodTransfer) {
          setCreatedTransfer(data.transferNumber);
        }
      },
      (err) => {
        setLoading(false);
        setErrorPayment(err);
      },
    );
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} lg={3} as={Form.Group}>
            <DateRangePickerWrapper
              showClearDates
              showDefaultInputIcon
              readOnly
              startDatePlaceholderText={t('dates:placeholderStartDate')}
              endDatePlaceholderText={t('dates:placeholderFinishedDate')}
              onChangeStartDate={handleFilterStartDate}
              onChangeEndDate={handleFilterEndDate}
              isOutsideRange={() => false}
            />
          </Col>
          <Col md={3} as={Form.Group}>
            <Select
              options={stateStatusOrder}
              onChange={handleFilterStatusOrder}
              isClearable
              placeholder={t('fieldStatus')}
            />
          </Col>
          <Col md={2} as={Form.Group}>
            <Select
              options={stateStatusesIssue}
              onChange={handleFilterStatusIssue}
              isClearable
              placeholder={t('fieldStatusIssue')}
            />
          </Col>
        </Row>
        <GeneralTable
          defaultSorted={defaultSorted}
          columns={columns}
          getData={getData}
          placeholderSearch={t('search-element:placeholder.key_or_product')}
          expandRow={expandRow}
          selectRow={selectRow}
          filter={filterFactory()}
        />
      </Card.Body>
      <Card.Footer>
        <Row as={Form.Group}>
          <Col>
            {amountToPay === 0 ? null : (
              <PaymentButton
                loading={loading}
                onClick={() => handlePayment()}
                allSum={amountToPay}
                clientSettingStore={clientSettingStore}
              />
            )}
          </Col>
          <Col>
            <p>
              {`${t('amountToPay')}: `}
              <strong>
                <Currency value={amountToPay} />
              </strong>
            </p>
          </Col>
          <Col>
            <p>
              {`${t('leftToPay')}: `}
              <Currency value={sumAllOrders} />
            </p>
          </Col>
        </Row>
        <AlertMoreMaxSumOrder clientSettingStore={clientSettingStore} allSum={amountToPay} />
        {createdTransfer && (
          <Alert variant="success">{t('basket:payment.transferSuccess', { transferNumber: createdTransfer })}</Alert>
        )}
        {errorPayment && <Alert variant="danger">{errorPayment}</Alert>}
      </Card.Footer>
    </Card>
  );
};

export default PaymentOrderTable;
