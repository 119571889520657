import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLandmark } from '@fortawesome/free-solid-svg-icons';

const Transfer: FC = () => {
  return (
    <>
      <Row>
        <Col md={4}>
          <FontAwesomeIcon icon={faLandmark} size={'3x'} color={'grey'} />
        </Col>
        <Col className="pl-0">
          <p className="mb-0">
            <b>Bank Transfer</b>
          </p>
          <p>
            <b>(invoice)</b>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Transfer;
