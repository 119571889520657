import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, Row, Col } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { useTranslation } from 'react-i18next';
import { Product } from '../../models/product';
import { BasketChangeButton } from '../index';

import logoNotFound from '../../assets/img/logo_not_found.png';

interface ModalProductInfoProps {
  show: boolean;
  toggleClose: () => void;
  productInfo?: Product;
}

const ModalProductInfo: React.FC<ModalProductInfoProps> = (props) => {
  const { t } = useTranslation('modal');

  const imgPath = props.productInfo?.cover === null ? 'stub' : props.productInfo?.cover;
  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.toggleClose}
        dialogClassName="modal-90w"
        aria-labelledby="modal-styling-title"
        className={'modal-danger'}
      >
        <ModalHeader closeButton>
          <ModalTitle id="modal-styling-title">{t('titleProductModal')}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {props.productInfo && (
            <>
              <Row>
                <Col md={8}>
                  <Row>
                    <Col xs={4}>
                      <strong>Дата выхода:</strong>
                    </Col>
                    <Col>{props.productInfo.dateRelease}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <strong>Возрастной рейтинг:</strong>
                    </Col>
                    <Col>{props.productInfo.ageRating}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <strong>Разработчик:</strong>
                    </Col>
                    <Col>{props.productInfo.developer}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <strong>Издатель:</strong>
                    </Col>
                    <Col>{props.productInfo.publisher}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <strong>Локализация:</strong>
                    </Col>
                    <Col>{props.productInfo.localization}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <strong>Система активации:</strong>
                    </Col>
                    <Col>{props.productInfo.activation}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <strong>Регион:</strong>
                    </Col>
                    <Col>{props.productInfo.region}</Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <img
                    width="100%"
                    height="auto"
                    className="mb-4"
                    src={imgPath}
                    onError={(e) => {
                      // @ts-ignore
                      e.target.onerror = null;
                      // @ts-ignore
                      e.target.src = logoNotFound;
                    }}
                    alt="Not Found Cover"
                  />
                  <BasketChangeButton productID={props.productInfo.id} />
                </Col>
              </Row>
              <div dangerouslySetInnerHTML={{ __html: props.productInfo.description as string }} />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleClose}>
            {t('buttonCancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalProductInfo;
