import React from 'react';

const Users = React.lazy(() => import('./../views/Admin/Users/Users'));
const UserDetails = React.lazy(() => import('./../views/Admin/Users/UserDetails/UserDetails'));
const Catalog = React.lazy(() => import('./../views/Admin/Catalog/Catalog'));
const ProductDetails = React.lazy(() => import('./../views/Admin/Catalog/ProductDetails/ProductDetails'));
const Contract = React.lazy(() => import('./../views/Admin/Contract/Contract'));
const Discounts = React.lazy(() => import('./../views/Client/Discounts/Discounts'));
const DiscountDetail = React.lazy(() => import('./../views/Client/Discounts/DiscountProducts/DiscountDetail'));
const Help = React.lazy(() => import('./../views/Admin/Help/Help'));
const Orders = React.lazy(() => import('./../views/Client/Orders/Orders'));
const OrderDetails = React.lazy(() => import('./../views/Admin/Orders/OrderDetails/OrderDetails'));
const Invoice = React.lazy(() => import('./../views/Client/Invoice/Invoice'));
const Settings = React.lazy(() => import('./../views/Admin/Settings/Settings'));
const Transfer = React.lazy(() => import('./../views/Admin/Transfer/TransferAdminController'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const getRoutes = (t: (s: string) => string) => [
  { path: '/', exact: true, name: t('navHome') },
  { path: '/users', exact: true, name: t('navAdminUsers'), component: Users },
  { path: '/users/:id', exact: true, name: t('navAdminEditUser'), component: UserDetails },
  { path: '/catalog', exact: true, name: t('navClientCatalog'), component: Catalog },
  { path: '/catalog/:id', exact: true, name: t('navAdminEditProduct'), component: ProductDetails },
  { path: '/discounts', exact: true, name: t('navClientDiscounts'), component: Discounts },
  {
    path: '/discounts/:id',
    exact: true,
    name: t('navClientDiscountDetails'),
    component: DiscountDetail,
  },
  { path: '/invoices', exact: true, name: t('navClientInvoices'), component: Invoice },
  { path: '/orders', exact: true, name: t('navClientOrders'), component: Orders },
  { path: '/orders/:id', exact: true, name: t('navAdminEditOrder'), component: OrderDetails },
  { path: '/transfer', exact: true, name: t('navTransfer'), component: Transfer },
  { path: '/settings', exact: true, name: t('navClientSettings'), component: Settings },
  { path: '/contract', exact: true, name: t('navClientContract'), component: Contract },
  { path: '/help', exact: true, name: t('navClientHelp'), component: Help },
];

export default getRoutes;
