import { BehaviorSubject } from 'rxjs';

import { localStorageHelper } from '../helpers/local-storage';
import { User } from '../models/user';
import { ajaxRequest, history } from '../helpers';
import { urlRepository } from '../routes';

const user = localStorageHelper?.getItem('currentUser') || null;
const currentUserSubject = new BehaviorSubject(user && JSON.parse(user));

export const authenticationService = {
  login,
  logout,
  forceLogin,
  loadIP,
  currentUserSubject: currentUserSubject,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue(): User | null {
    return currentUserSubject.value;
  },
  get isClient(): boolean {
    return currentUserSubject.value.roles.includes('ROLE_CLIENT');
  },
  get isAdmin(): boolean {
    return currentUserSubject.value.roles.includes('ROLE_ADMIN');
  },
  get ipUser(): string | null {
    return localStorage.getItem('ipUser');
  },
};

function login(email: string, password: string, tokenRecaptcha: string | void, versionRecaptcha: number) {
  const ip = authenticationService.ipUser;
  return ajaxRequest
    .post(urlRepository.getUrl('login'), { email, password, tokenRecaptcha, versionRecaptcha, ip })
    .then((data: User) => {
      const user: User = setIp(data);
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      currentUserSubject.next(user);

      return user;
    });
}

function forceLogin(password: string) {
  return ajaxRequest.post(urlRepository.getUrl('login'), { password, force: true }).then((data: User) => {
    const user: User = setIp(data);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);

    return user;
  });
}

function loadIP() {
  ajaxRequest.getIP().then((result: any) => {
    const ip = result?.ip;
    localStorage.setItem('ipUser', ip);
  });
}

function setIp(user: User) {
  user.ip = localStorage.getItem('ipUser') as string;
  localStorage.removeItem('ipUser');
  return user;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
  history.push('/auth');
}
