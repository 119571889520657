import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';
import BasketModel from '../components/Basket/BasketModel';

export const basketService = {
  getRemotePaymentMethods,
  getRemoteBasketCounts,
  saveBasketToDB,
  redirectToPayment,
  paymentByBukaCoin,
};

function getRemotePaymentMethods() {
  return ajaxRequest.get(urlRepository.getUrl('clientPaymentMethod'));
}

function getRemoteBasketCounts() {
  return ajaxRequest.get(urlRepository.getUrl('clientBasketCount'));
}

function saveBasketToDB(basket: BasketModel[]) {
  return ajaxRequest.post(urlRepository.getUrl('clientBasketSave'), basket);
}

function redirectToPayment(data: any) {
  return ajaxRequest.post(urlRepository.getUrl('clientPayment'), data).then((response) => {
    const link = document.createElement('a');
    link.href = response.url;
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  });
}

function paymentByBukaCoin(data: any) {
  return ajaxRequest.post(urlRepository.getUrl('clientPaymentByCoin'), data);
}
