import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { Button, Col, OverlayTrigger, Row, Tooltip, Form } from 'react-bootstrap';
import { SortOrder } from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { LinkContainer } from 'react-router-bootstrap';

import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faInfo } from '@fortawesome/free-solid-svg-icons';

import GeneralTable from '../GeneralTable';
import DateRangePickerWrapper from '../../DateRangePickerWrapper/DateRangePickerWrapper';
import { discountService } from '../../../services';
import { Discount } from '../../../models/discount';
import { ajaxRequest, discountStatuses } from '../../../helpers';
import { urlRepository } from '../../../routes';

let filterStatusFunc: any = null;
let filterStartDateFunc: any = null;
let filterEndDateFunc: any = null;

const DiscountsTable: React.FC = () => {
  const { t } = useTranslation(['discounts-table', 'search-element', 'dates']);

  const [statuses, setStatuses] = useState<Array<any>>([]);

  useEffect(() => {
    setStatuses(discountStatuses.map((item: any) => ({ value: item.value, label: t(item.label) })));
  }, [t]);

  const downloadXlsx = (id: number, name: string) => {
    ajaxRequest.downloadFile(urlRepository.getUrlWithId('clientDownloadDiscount', id), `discount_${name}.xlsx`).then();
  };

  const columns: any[] = [
    { dataField: 'id', text: 'ID', sort: true, headerStyle: { width: '5%' } },
    { dataField: 'dateStart', text: t('fieldDateStart'), sort: true, headerStyle: { width: '12rem' } },
    {
      dataField: 'dateFinish',
      text: t('fieldDateFinish'),
      sort: true,
      headerStyle: { width: '12rem' },
    },
    { dataField: 'name', text: t('fieldName'), sort: true, headerStyle: { width: '50%' } },
    {
      dataField: 'status',
      text: t('fieldStatus'),
      sort: true,
      headerStyle: { width: '8%' },
      formatter: (cellContent: any) => {
        return statuses.find((item) => item.value === +cellContent)?.label;
      },
      filter: selectFilter({
        options: statuses,
        className: 'd-none',
        getFilter: (filter) => {
          filterStatusFunc = filter;
        },
      }),
    },

    {
      dataField: 'buttonInfo',
      text: '',
      formatter: (cellContent: any, row: Discount) => (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-info-${row.id}`}>{t('buttonInfo')}</Tooltip>}>
          <LinkContainer to={`/discounts/${row.id}`}>
            <Button variant="info" size={'sm'}>
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </LinkContainer>
        </OverlayTrigger>
      ),
      classes: 'text-center',
    },

    {
      dataField: 'buttonFile',
      text: '',
      formatter: (cellContent: any, row: Discount) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-download-${row.id}`}>{t('buttonExcel')}</Tooltip>}
        >
          <Button variant="warning" onClick={() => downloadXlsx(row.id, row.name)} size={'sm'}>
            <FontAwesomeIcon icon={faFileExcel} />
          </Button>
        </OverlayTrigger>
      ),
      classes: 'text-center',
    },

    {
      dataField: 'start_date',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterStartDateFunc = filter) }),
    },
    {
      dataField: 'end_date',
      text: '',
      classes: 'd-none',
      headerClasses: 'd-none',
      filter: selectFilter({ options: {}, getFilter: (filter) => (filterEndDateFunc = filter) }),
    },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  const rowClasses = (row: any) => {
    if (row.status === '3') {
      return '';
    }
    if (row.status === '1') {
      return 'table-warning';
    }
    return 'table-success';
  };

  const handleFilterStartDate = (date: moment.Moment) => {
    filterStartDateFunc(date && date.toISOString());
    return date;
  };
  const handleFilterEndDate = (date: moment.Moment) => {
    filterEndDateFunc(date && date.toISOString());
    return date;
  };
  const handleFilterStatus = (value: any) => filterStatusFunc(value);
  const getData: (params: any) => Promise<Discount[]> = (params: any) => discountService.getRemoteDiscounts(params);

  return (
    <>
      <Row>
        <Col sm={3} as={Form.Group}>
          <DateRangePickerWrapper
            showClearDates
            readOnly
            startDatePlaceholderText={t('dates:placeholderStartDate')}
            endDatePlaceholderText={t('dates:placeholderFinishedDate')}
            onChangeStartDate={handleFilterStartDate}
            onChangeEndDate={handleFilterEndDate}
            isOutsideRange={() => false}
          />
        </Col>
        <Col sm={4} lg={2} as={Form.Group}>
          <Select
            options={statuses}
            onChange={handleFilterStatus}
            isMulti
            isClearable
            placeholder={t('placeholderSelectStatus')}
          />
        </Col>
      </Row>
      <GeneralTable
        defaultSorted={defaultSorted}
        columns={columns}
        getData={getData}
        rowClasses={rowClasses}
        placeholderSearch={t('search-element:placeholder.product')}
        filter={filterFactory()}
      />
    </>
  );
};

export default DiscountsTable;
