import { action, observable } from 'mobx';
import BasketProductModel from './BasketProductModel';

class BasketModel {
  productID: number = 0;
  @observable
  count: number = 0;
  @observable
  nds: number = 0;
  @observable
  info: BasketProductModel = new BasketProductModel();

  constructor(data: { productID: number; count: number }) {
    this.productID = data.productID;
    this.count = data.count;
  }

  @action
  changeCount(count: number) {
    this.count = Math.abs(Math.round(count || 1));
  }

  serialize() {
    return {
      productID: this.productID,
      count: this.count,
    };
  }
  static deserialize(json: { productID: number; count: number }) {
    return new BasketModel(json);
  }
}

export default BasketModel;
