import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, Form, Image, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik, FormikErrors } from 'formik';
import { LoadingButton } from '../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { GlobalOptions } from '../../../models/global-options';
import { PaymentSystem } from '../../../models/payment-system';
import { PaymentMethod } from '../../../models/payment-method';
import { PaymentRelation } from '../../../models/payment-relation';
import { globalSettingsService } from '../../../services';
import { toastHelper } from '../../../helpers';
import { CSwitch } from '@coreui/react';
import { OptionsType } from 'react-select';
import { ValueType } from 'react-select/src/types';
import { currencyService } from '../../../services/currency.service';
import OptionCurrency from './OptionCurrency';

interface ResponseOptionsForm {
  [k: string]: any;
  globalOption: GlobalOptions | null;
  currencyValue: number | null;
  systems: PaymentSystem[];
  systemOptions?: OptionsType<any>;
  methods: PaymentMethod[];
  relations: PaymentRelation[];
}

const OptionsForm: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const initialState: ResponseOptionsForm = {
    globalOption: null,
    currencyValue: null,
    systems: [],
    methods: [],
    relations: [],
  };
  const [state, setState] = useState<ResponseOptionsForm>(initialState);

  const { t } = useTranslation(['settings', 'notifications', 'form', 'buttons']);

  useEffect(() => {
    globalSettingsService.getOptions().then(
      (response: ResponseOptionsForm) => {
        response.systemOptions = response.systems.map((item) => ({ value: item.paymentSystemId, label: item.name }));
        setState(response);
      },
      (error) => console.log(error),
    );
  }, []);

  const schema = yup.object({
    globalOption: yup.object({
      maxSumForOrder: yup.number().positive(t('form:error.positiveNumber')).required(t('form:error.requiredField')),
      maxSumForOrderWithCash: yup
        .number()
        .positive(t('form:error.positiveNumber'))
        .required(t('form:error.requiredField')),
      indexCurrencyFlu: yup
        .number()
        .min(0, t('form:error.minValue', { value: 0 }))
        .max(99, t('form:error.maxValue', { value: 99 }))
        .required(t('form:error.requiredField')),
    }),
  });

  const handleSubmit = (values: ResponseOptionsForm, { setSubmitting }: any) => {
    setLoading(true);
    const data = { ...values };
    data.relations.forEach((item, key, obj) => {
      if (typeof item.paymentSystemId == 'object') {
        obj[key].paymentSystemId = (item.paymentSystemId as ValueType<any>).value;
      }
    });

    globalSettingsService.saveOptions(data).then(
      () => {
        toastHelper.success(t('notifications:success'));
        setLoading(false);
      },
      (error) => {
        toastHelper.error(`Error: ${error}`);
        setSubmitting(false);
        console.log(error);
        setLoading(false);
      },
    );
  };

  const { currencyValue } = state;
  return (
    <Card>
      <Card.Header>{t('global.titleGlobalOptions')}</Card.Header>
      <Card.Body>
        <Formik validationSchema={schema} initialValues={state} enableReinitialize={true} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
              {values.globalOption &&
                [
                  { field: 'maxSumForOrder', trans: 'global.fieldMaxSumForOrder' },
                  { field: 'maxSumForOrderWithCash', trans: 'global.fieldMaxSumForOrderWithCash' },
                  { field: 'indexCurrencyFlu', trans: '' },
                ].map(({ field, trans }) => {
                  const errorText = ((errors.globalOption as unknown) as FormikErrors<GlobalOptions>)?.[field];
                  if (field === 'indexCurrencyFlu') {
                    return currencyService.isSet() ? (
                      <OptionCurrency
                        field={field}
                        key={field}
                        handleChange={handleChange}
                        errorText={((errors.globalOption as unknown) as FormikErrors<GlobalOptions>)?.[field]}
                        value={values.globalOption?.[field]}
                        currencyValue={currencyValue as number}
                      />
                    ) : null;
                  }
                  return (
                    <Form.Group controlId={field} key={field}>
                      <Form.Label>{t(trans)}</Form.Label>
                      <Form.Control
                        type={'number'}
                        name={`globalOption.${field}`}
                        value={values.globalOption?.[field]}
                        onChange={handleChange}
                        isInvalid={!!errorText}
                      />
                      <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
                    </Form.Group>
                  );
                })}

              <FieldArray
                name="relations"
                render={() => {
                  return (
                    <Table>
                      <thead>
                        <tr>
                          <th className="col-sm-7">{t('global.headMethods')}</th>
                          <th>{t('global.headSystems')}</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {values.methods.map(({ paymentMethodId, fileName }) => {
                          const index = values.relations.findIndex((item) => item.paymentMethodId === paymentMethodId);
                          return (
                            <tr key={paymentMethodId}>
                              <td>
                                <Form.Control
                                  name={`relations[${index}].paymentMethodId`}
                                  type={'hidden'}
                                  value={paymentMethodId}
                                />
                                <Image
                                  className="col-sm-8 col-lg-7 col-xl-4"
                                  src={`${process.env.REACT_APP_BASE_URL_BACKEND}/img/payment/${fileName}`}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  plaintext
                                  readOnly
                                  defaultValue={
                                    values.systemOptions?.find(
                                      (item) => item.value === values.relations[index].paymentSystemId,
                                    ).label
                                  }
                                />
                              </td>
                              <td>
                                <CSwitch
                                  className={'mx-1'}
                                  id={`enable_${paymentMethodId}`}
                                  name={`relations[${index}].enable`}
                                  variant={'3d'}
                                  color={'success'}
                                  labelOn={'\u2713'}
                                  labelOff={'\u2715'}
                                  onChange={handleChange}
                                  checked={values.relations[index].enable}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  );
                }}
              />
              <Form.Group>
                <LoadingButton variant="success" type="submit" loading={loading}>
                  <FontAwesomeIcon icon={faSave} /> {t('buttons:save')}
                </LoadingButton>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default OptionsForm;
