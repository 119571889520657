export const orderStatus = [
  { value: 1, label: 'status.sentForReview' },
  { value: 2, label: 'status.changesPendingBuyerConfirmation' },
  { value: 3, label: 'status.buyerConfirmed' },
  { value: 4, label: 'status.canceledByBuyer' },
  { value: 5, label: 'status.verifiedBySeller' },
  { value: 6, label: 'status.canceledBySeller' },
  { value: 7, label: 'status.partiallyIssued' },
  { value: 8, label: 'status.fullyIssued' },
  { value: 9, label: 'status.completed' },
  { value: 10, label: 'status.autoCancel' },
  { value: 11, label: 'status.fullyIssuedAndAwaitingPayment' },
];
