import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Image, FormGroup } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import './PaymentList.scss';
import { PaymentMethod } from '../../models/payment-method';
import { basketService } from '../../services/basket.service';
import { useStores } from '../../hooks/use-store';
import { BukaCoin, Currency } from '../index';
import Transfer from './Transfer';

interface ResponsePaymentList {
  methods: PaymentMethod[] | null;
  amountPurchases: number;
  fixDiscount: number;
}

const PaymentList: React.FC = observer(() => {
  const [methods, setMethods] = useState<PaymentMethod[] | null>(null);
  const [amountPurchases, setAmountPurchases] = useState(0);
  const [fixDiscount, setFixDiscount] = useState(0);

  const { basketViewModel } = useStores();
  const { t } = useTranslation('basket');

  useEffect(() => {
    basketService.getRemotePaymentMethods().then(
      (response: ResponsePaymentList) => {
        const res = response as ResponsePaymentList;
        const methods = res.methods as Array<PaymentMethod>;
        if (methods.length > 0) {
          basketViewModel.setPaymentMethodIfNotExist(Number(methods[0].paymentMethodId));
        }

        setMethods(response.methods);
        setAmountPurchases(response.amountPurchases);
        setFixDiscount(response.fixDiscount);
      },
      (error) => console.log(error),
    );
  }, [basketViewModel]);

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>{t('payment.titleMethods')}</Card.Header>
          <Card.Body>
            <Row>
              {methods &&
                methods.map((item) => (
                  <Col lg={2} md={3} xs={6} as={FormGroup} key={item.paymentMethodId}>
                    <div
                      className={`payment-link ${
                        basketViewModel.isActiveMethod(Number(item.paymentMethodId)) ? 'active' : ''
                      }`}
                      onClick={() => basketViewModel.setPaymentMethod(Number(item.paymentMethodId))}
                    >
                      {Number(item.paymentMethodId) === basketViewModel.paymentMethodCoin ? (
                        <BukaCoin basketViewModel={basketViewModel} />
                      ) : Number(item.paymentMethodId) === basketViewModel.paymentMethodTransfer ? (
                        <Transfer />
                      ) : (
                        <Image src={`${process.env.REACT_APP_BASE_URL_BACKEND}/img/payment/${item.fileName}`} />
                      )}
                    </div>
                  </Col>
                ))}
            </Row>
          </Card.Body>
          <Card.Footer className="text-center text-muted">
            {t('amountAllPurchases')}{' '}
            <b>
              <Currency value={amountPurchases} />
            </b>
            <br />
            {fixDiscount === 0 ? (
              ''
            ) : (
              <>
                {t('fixDiscount')} <b>{fixDiscount}%</b>
              </>
            )}
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
});

export default React.memo(PaymentList);
