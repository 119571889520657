import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';
import { ClientInfo } from '../models/client-info';

export const userService = {
  getUsers,
  getUserDetail,
  createUser,
  saveUser,
  removeUser,
  getIndividualPrice,
  saveIndividualPrice,
  getClientInfo,
  saveClientInfo,
  getUserShoppingList,
  findUserByValue,
  unblockingUser,
  newPassword,
};

function getUsers(params: object) {
  return ajaxRequest.get(urlRepository.getUrl('adminUser'), params);
}

function getUserDetail(id: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('adminUserDetail', id), {});
}

function getUserShoppingList(id: number, params: object) {
  return ajaxRequest.get(urlRepository.getUrlWithId('adminUserShoppingList', id), params);
}

function createUser(params: object) {
  return ajaxRequest.put(urlRepository.getUrl('adminUser'), params);
}

function saveUser(id: number, params: object) {
  return ajaxRequest.post(urlRepository.getUrlWithId('adminUserDetail', id), params);
}

function removeUser(id: number) {
  return ajaxRequest.del(urlRepository.getUrlWithId('adminUserDetail', id), {});
}

function getIndividualPrice(id: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('adminUserIndividualPrice', id), {});
}

function saveIndividualPrice(id: number, params: object) {
  return ajaxRequest.post(urlRepository.getUrlWithId('adminUserIndividualPrice', id), params);
}

function getClientInfo(id: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('adminUserInfo', id));
}

function saveClientInfo(id: number, params: ClientInfo) {
  return ajaxRequest.post(urlRepository.getUrlWithId('adminUserInfo', id), params);
}

function findUserByValue(value: string) {
  return ajaxRequest.get(urlRepository.getUrl('adminUserFind'), { value });
}

function unblockingUser(id: number) {
  return ajaxRequest.post(urlRepository.getUrlWithId('adminUserUnblocking', id), {});
}

function newPassword(id: number) {
  return ajaxRequest.put(urlRepository.getUrlWithId('adminUserNewPassword', id), {});
}
