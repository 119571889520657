import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';

export const globalSettingsService = {
  getOptions,
  getRoyaltyPercentList,
  getDynamicPercentList,
  saveOptions,
  saveRoyaltyPercentList,
  saveDynamicPercentList,
};

function getOptions() {
  return ajaxRequest.get(urlRepository.getUrl('adminSettingOptions'));
}

function getRoyaltyPercentList() {
  return ajaxRequest.get(urlRepository.getUrl('adminSettingRoyalty'));
}

function getDynamicPercentList() {
  return ajaxRequest.get(urlRepository.getUrl('adminSettingDynamic'));
}

function saveOptions(params: object) {
  return ajaxRequest.post(urlRepository.getUrl('adminSettingOptions'), params);
}

function saveRoyaltyPercentList(params: object) {
  return ajaxRequest.post(urlRepository.getUrl('adminSettingRoyalty'), params);
}

function saveDynamicPercentList(params: object) {
  return ajaxRequest.post(urlRepository.getUrl('adminSettingDynamic'), params);
}
