import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';
import { InvoiceTransfer } from '../models/invoice-transfer';

const transferService = {
  payment,
  paid,
  cancel,
  uploadFile,
  getRemoteTransfers,
};

function getRemoteTransfers(data: any): Promise<any> {
  return ajaxRequest.get(urlRepository.getUrl('clientInvoiceTransfer'), data);
}

function payment(data: any) {
  return ajaxRequest.post(urlRepository.getUrl('clientPaymentTransfer'), data);
}

function paid(id: number): Promise<any> {
  return ajaxRequest.post(urlRepository.getUrlWithId('clientInvoiceTransferPaid', id), {});
}

function cancel(id: number): Promise<any> {
  return ajaxRequest.post(urlRepository.getUrlWithId('clientInvoiceTransferCancel', id), {});
}

function uploadFile(transfer: InvoiceTransfer) {
  ajaxRequest.downloadFile(
    urlRepository.getUrlWithId('clientDownloadInvoiceTransfer', transfer.id),
    `proforma_invoice_${transfer.transferNumber}.xlsx`,
  );
}

export default transferService;
