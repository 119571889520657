class BasketProductModel {
  id: number = 0;
  name: string = '';
  remainder: string = '';
  basePrice: number = 0;
  percent: number = 0;
  finishPrice: number = 0;
  isPreorder: number = 0;
  nds: number = 0;
  maxCountForBuy: number = 0;
}

export default BasketProductModel;
