export const validation = {
  get datetimeFormat(): RegExp {
    return /^[0-9]{4}-[0-9]{2}-[0-9]{2} ([0-9]{2}:?){3}$/;
  },
  get phoneNumber(): RegExp {
    return /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  },
  get latinStringAndNumber(): RegExp {
    return /^[a-zA-Z\d\s]*$/;
  },
  get latinString(): RegExp {
    return /^[a-zA-Z\s]*$/;
  },
  get latinStringAndSymbols(): RegExp {
    return /^[a-zA-Z\d\s-,.:]*$/;
  },
};
