import React, { FC, useState } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';

const ColorPicker: FC<{ color: string | null; onChange: (color: string) => void }> = ({ color, onChange }) => {
  const [colorState, setColorState] = useState(() => (color ? color : '#fff'));

  const handleChangeColor = (color: ColorResult) => {
    setColorState(color.hex);
    onChange(color.hex);
  };
  return <TwitterPicker triangle={'hide'} color={colorState} onChangeComplete={handleChangeColor} />;
};

export default ColorPicker;
