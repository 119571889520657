import React from 'react';

const Catalog = React.lazy(() => import('./../views/Client/Catalog/Catalog'));
const Discounts = React.lazy(() => import('./../views/Client/Discounts/Discounts'));
const DiscountDetail = React.lazy(() => import('../views/Client/Discounts/DiscountProducts/DiscountDetail'));
const Invoice = React.lazy(() => import('./../views/Client/Invoice/Invoice'));
const Orders = React.lazy(() => import('./../views/Client/Orders/Orders'));
const OrderDetails = React.lazy(() => import('./../views/Client/Orders/OrderDetails/OrderDetails'));
const Settings = React.lazy(() => import('./../views/Client/Settings/Settings'));
const ContractLicense = React.lazy(() => import('./../views/Client/Contract/ContractLicense'));
const ContractDigitalServices = React.lazy(() => import('./../views/Client/Contract/ContractDigitalServices'));
const Help = React.lazy(() => import('./../views/Client/Help/Help'));
const Basket = React.lazy(() => import('./../views/Client/Basket/Basket'));
const PaymentOrder = React.lazy(() => import('../views/Client/PaymentOrder/PaymentOrder'));
const BukaCoinController = React.lazy(() => import('../views/Client/BukaCoin/BukaCoinController'));
const Transfer = React.lazy(() => import('./../views/Client/Transfer/TransferClientController'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const getRoutes = (t: (s: string) => string): object[] => [
  { path: '/', exact: true, name: t('navHome') },
  { path: '/catalog', exact: true, name: t('navClientCatalog'), component: Catalog },
  { path: '/discounts', exact: true, name: t('navClientDiscounts'), component: Discounts },
  {
    path: '/discounts/:id',
    exact: true,
    name: t('navClientDiscountDetails'),
    component: DiscountDetail,
  },
  { path: '/invoices', exact: true, name: t('navClientInvoices'), component: Invoice },
  { path: '/orders', exact: true, name: t('navClientOrders'), component: Orders },
  { path: '/orders/:id', exact: true, name: t('navClientOrderDetails'), component: OrderDetails },
  { path: '/settings', exact: true, name: t('navClientSettings'), component: Settings },
  {
    path: '/contract/license',
    exact: true,
    name: t('navClientContractLicense'),
    component: ContractLicense,
  },
  {
    path: '/contract/digitalService',
    exact: true,
    name: t('navClientContractDigitalService'),
    component: ContractDigitalServices,
  },
  { path: '/help', exact: true, name: t('navClientHelp'), component: Help },
  { path: '/basket', exact: true, name: t('navClientBasket'), component: Basket },
  { path: '/payment-order', exact: true, name: t('navClientPaymentOrders'), component: PaymentOrder },
  { path: '/bukacoin', exact: true, name: t('navClientBukaCoin'), component: BukaCoinController },
  { path: '/transfer', exact: true, name: t('navTransfer'), component: Transfer },
];

export default getRoutes;
