import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import { Loading, PrivateRoute } from '../../components';

import './App.scss';
import { history } from '../../helpers';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);
dom.watch();

const Auth = React.lazy(() => import('../../views/Auth/Auth'));
const TheLayout = React.lazy(() => import('../TheLayout/TheLayout'));

const App = () => {
  return (
    <Router history={history}>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route exact={true} path="/auth" component={Auth} />
          <Route exact={true} path="/auth/:passwd" component={Auth} />
          <PrivateRoute path="/" component={TheLayout} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default App;
