import React, { useState } from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker, DateRangePickerPhrases, DateRangePickerShape, isInclusivelyAfterDay } from 'react-dates';
import {
  START_DATE,
  END_DATE,
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
  ANCHOR_LEFT,
  NAV_POSITION_TOP,
  // @ts-ignore
} from 'react-dates/constants';
import { isMobileDevice } from '../../helpers';

import './DateRangePickerWrapper.scss';

const defaultProps: DateRangePickerShape = {
  // example props for the demo
  //@ts-ignore
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  onChangeStartDate: (date: any) => date,
  onChangeEndDate: (date: any) => date,

  // input related props
  startDateId: START_DATE,
  startDatePlaceholderText: 'Start Date',
  endDateId: END_DATE,
  endDatePlaceholderText: 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: true,
  customInputIcon: undefined,
  customArrowIcon: undefined,
  customCloseIcon: undefined,
  block: true,
  small: true,
  regular: false,

  // calendar presentation and interaction related props
  renderMonthText: undefined,
  orientation: isMobileDevice() ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: undefined,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPosition: NAV_POSITION_TOP,
  navPrev: undefined,
  navNext: undefined,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: undefined,
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: (day: any) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: 'DD/MM/YYYY',
  monthFormat: 'MMMM YYYY',
  // @ts-ignore
  phrases: DateRangePickerPhrases,
};

const DateRangePickerWrapper: React.FC<any> = (props) => {
  const [focusedInput, setFocusedInput] = useState<string | null>(
    (() => {
      if (props.autoFocus) {
        return START_DATE;
      } else if (props.autoFocusEndDate) {
        return END_DATE;
      }
      return null;
    })(),
  );
  const [startDateState, setStartDateState] = useState<moment.Moment>(props.initialStartDate);
  const [endDateState, setEndDateState] = useState<moment.Moment>(props.initialEndDate);

  const onDatesChange = ({ startDate, endDate }: any) => {
    const { onChangeStartDate, onChangeEndDate } = props;

    if (startDate === null && endDate === null) {
      onChangeStartDate(startDate);
      onChangeEndDate(endDate);
    } else {
      if ((!startDateState && startDate) || (startDateState && !startDateState.isSame(startDate?.toISOString()))) {
        onChangeStartDate(startDate);
      }
      if ((!endDateState && endDate) || (endDateState && !endDateState.isSame(endDate?.toISOString()))) {
        onChangeEndDate(endDate);
      }
    }

    setStartDateState(startDate);
    setEndDateState(endDate);
  };

  const onFocusChange = (focusedInput: string) => setFocusedInput(focusedInput);

  const {
    autoFocus,
    autoFocusEndDate,
    initialStartDate,
    initialEndDate,
    onChangeStartDate,
    onChangeEndDate,
    ...propsDateRange
  } = props;

  return (
    <DateRangePicker
      {...propsDateRange}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      focusedInput={focusedInput}
      startDate={startDateState}
      endDate={endDateState}
    />
  );
};

DateRangePickerWrapper.defaultProps = defaultProps;

export default DateRangePickerWrapper;
