import React, { FC } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AlertRequestRegistration: FC<{ send: boolean }> = ({ send }) => {
  const { t } = useTranslation('sign-in');
  return send ? (
    <Alert variant="success">
      <p className="mb-0">{t('reg.successAlertSend')}</p>
    </Alert>
  ) : (
    <Alert variant="warning">
      <p>{t('reg.warningAlertFill')}</p>
      <hr />
      <p className="mb-0">{t('reg.warningAlertEmail')}</p>
    </Alert>
  );
};

export default AlertRequestRegistration;
