import React, { useEffect, useState } from 'react';
import { Table, Card, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CSwitch } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Formik, FieldArray, FormikErrors } from 'formik';
import * as yup from 'yup';
import AsyncSelect from 'react-select/async';
import { Moment } from 'moment';

import { IndividualPrice } from '../../../models/individual-price';
import { catalogService, userService } from '../../../services';
import { DatetimePicker, LoadingButton } from '../../index';
import { validation } from '../../../helpers/validation';
import { toastHelper } from '../../../helpers';

import './IndividualPriceForm.scss';

const IndividualPriceForm: React.FC<{ userID: number }> = ({ userID }) => {
  const { t } = useTranslation(['users', 'buttons', 'form', 'notifications', 'dates']);

  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: { list: IndividualPrice[] } = { list: [] };
  const [listPrices, setListPrices] = useState(initialValues);
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    userService.getIndividualPrice(userID).then(
      (response: IndividualPrice[]) => setListPrices({ list: response }),
      (error) => console.log(error),
    );
  }, [userID, forceRender]);

  const schema = yup.object({
    list: yup.array().of(
      yup.object({
        productId: yup.mixed().nullable().required(t('form:error.requiredField')),
        value: yup
          .number()
          .min(0.01, t('form:error.minValue', { value: 0.01 }))
          .required(t('form:error.requiredField')),
        startDate: yup
          .string()
          .matches(validation.datetimeFormat, t('form:error.notValidDatetime'))
          .required(t('form:error.requiredField')),
        finishDate: yup
          .string()
          .matches(validation.datetimeFormat, t('form:error.notValidDatetime'))
          .required(t('form:error.requiredField')),
      }),
    ),
  });
  const priceForAdd: IndividualPrice = {
    individualPriceId: 0,
    productId: null,
    productName: '',
    isTypePercent: false,
    value: 1,
    finishDate: '',
    startDate: '',
  };

  const handleSubmit = (values: typeof listPrices, { setSubmitting }: any) => {
    setLoading(true);
    let list: IndividualPrice[] = [...values.list];
    list = list.map((item) => {
      item.productId = typeof item.productId === 'object' ? item.productId.value : item.productId;
      item.type = item.isTypePercent ? 2 : 1;
      item.price = item.percent = 0;
      item[item.isTypePercent ? 'percent' : 'price'] = item.value;
      return item;
    });

    userService.saveIndividualPrice(userID, list).then(
      () => {
        toastHelper.success(t('notifications:success'));
        setLoading(false);
        setForceRender(!forceRender);
      },
      (error) => {
        toastHelper.error(`Error: ${error}`);
        setSubmitting(false);
        console.log(error);
        setLoading(false);
      },
    );
  };

  let onAdd: (price: IndividualPrice) => {};
  const searchProduct = (value: string) => {
    return catalogService.findProductForSelectControl(value).then(
      (response) => response,
      (error) => console.log(error),
    );
  };

  return (
    <Card>
      <Formik validationSchema={schema} initialValues={listPrices} enableReinitialize={true} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
            <Card.Header>
              {t('headerIndividualPrice')}
              <div className="float-right">
                <Button size={'sm'} variant={'primary'} onClick={() => onAdd(priceForAdd)}>
                  <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons:add')}
                </Button>
                <LoadingButton type={'submit'} size={'sm'} loading={loading} variant={'success'} className="ml-1">
                  <FontAwesomeIcon icon={faSave} /> {t('buttons:save')}
                </LoadingButton>
              </div>
            </Card.Header>
            <Card.Body>
              <Table responsive={'lg'} size={'sm'}>
                <thead>
                  <tr>
                    <th>{t('fieldProductName')}</th>
                    <th>{t('fieldValue')}</th>
                    <th>{t('fieldType')}</th>
                    <th>{t('fieldStartDate')}</th>
                    <th>{t('fieldFinishDate')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="list"
                    render={({ unshift, remove }) => {
                      onAdd = unshift;
                      let errorText: any;
                      return (
                        <>
                          {values.list.map((price, key) => (
                            <tr key={price.individualPriceId}>
                              {[
                                { field: 'productId', type: 'static' },
                                { field: 'value', type: 'number' },
                                { field: 'isTypePercent', type: 'checkbox' },
                                { field: 'startDate', type: 'text' },
                                { field: 'finishDate', type: 'text' },
                              ].map(({ field, type }, ind) => {
                                errorText = (errors.list as FormikErrors<IndividualPrice>[])?.[key]?.[field];
                                return (
                                  <td key={`${ind}.${field}`}>
                                    {type === 'static' ? (
                                      <div style={{ width: '15rem' }}>
                                        {price.individualPriceId === 0 ? (
                                          <AsyncSelect
                                            className={!!errorText ? 'is-invalid' : ''}
                                            name={`list[${key}].${field}`}
                                            type={'text'}
                                            onChange={(option) => {
                                              setFieldValue(`list[${key}].${field}`, option);
                                            }}
                                            isClearable
                                            placeholder={t('findProduct')}
                                            cacheOptions
                                            loadOptions={searchProduct}
                                          />
                                        ) : (
                                          <>
                                            {['individualPriceId', 'productId'].map((nameHidden) => (
                                              <Form.Control
                                                key={`${ind}.${nameHidden}`}
                                                name={`list[${key}].${nameHidden}`}
                                                type={'hidden'}
                                                value={price[nameHidden]}
                                              />
                                            ))}
                                            {price['productName']}
                                          </>
                                        )}
                                      </div>
                                    ) : null}
                                    {type === 'number' ? (
                                      <Form.Control
                                        style={{ width: '5rem' }}
                                        type={type}
                                        name={`list[${key}].${field}`}
                                        value={price[field]}
                                        onChange={handleChange}
                                        isInvalid={!!errorText}
                                      />
                                    ) : null}
                                    {type === 'checkbox' ? (
                                      <CSwitch
                                        className={'mx-1 float-left'}
                                        id={`${field}_${key}`}
                                        name={`list[${key}].${field}`}
                                        variant={'3d'}
                                        color={'secondary'}
                                        labelOn={'fix'}
                                        labelOff={'%'}
                                        onChange={(e: any) => setFieldValue(`list[${key}].${field}`, !e.target.checked)}
                                        checked={!values.list[key][field]}
                                      />
                                    ) : null}
                                    {type === 'text' ? (
                                      <>
                                        <DatetimePicker
                                          name={`list[${key}].${field}`}
                                          locale={t('dates:locale')}
                                          onChangeDate={(date: Moment | any) => {
                                            let value = date;
                                            if (typeof date === 'object') {
                                              value = date.format('YYYY-MM-DD hh:mm:ss');
                                            }
                                            setFieldValue(`list[${key}].${field}`, value);
                                          }}
                                          value={price[field]}
                                          isInvalid={!!errorText}
                                          style={{ width: '10rem' }}
                                        />
                                      </>
                                    ) : null}
                                    <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
                                  </td>
                                );
                              })}
                              <td>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-price-remove-${price.individualPriceId}`}>
                                      {t('buttons.remove')}
                                    </Tooltip>
                                  }
                                >
                                  <Button variant="danger" onClick={() => remove(key)}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                        </>
                      );
                    }}
                  />
                </tbody>
              </Table>
            </Card.Body>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default IndividualPriceForm;
