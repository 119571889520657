import { authHeader } from './auth-header';
import { handleResponse } from './handle-response';

export const ajaxRequest = {
  post,
  get,
  patch,
  put,
  del,
  downloadFile,
  base64Encode,
  base64Decode,
  getIP,
};

function post(url: string, body: object) {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function patch(url: string, body: object) {
  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(url: string, body: object) {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function get(url: string, body?: object) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: authHeader(),
  };

  const urlParams = body ? base64Encode(JSON.stringify(body)) : '';
  return fetch(url + '?body=' + urlParams, requestOptions).then(handleResponse);
}

function del(url: string, body?: object) {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    body: JSON.stringify(body),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function downloadFile(url: string, fileName: string) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch((error) => console.log(error));
}

function base64Encode(str: string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function base64Decode(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

function getIP() {
  return fetch(process.env.REACT_APP_GET_IP as string).then(
    (result) => result.json(),
    (error) => console.log(error),
  );
}
