import { action, computed, observable } from 'mobx';

class TheSidebarStore {
  @observable
  show: boolean = true;

  @computed
  get getShow() {
    return this.show;
  }

  @action
  toggleShow() {
    this.show = !this.show;
  }

  @action
  setShow(val: boolean) {
    this.show = val;
  }
}

export default TheSidebarStore;
