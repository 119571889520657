import React from 'react';
import { Spinner } from 'react-bootstrap';
const Loading: React.FC<{ min?: boolean }> = (props) => (
  <Spinner
    as="span"
    animation="grow"
    className="text-center pt-3"
    style={{ color: 'red' }}
    size={props.min ? 'sm' : undefined}
  />
);

export default Loading;
