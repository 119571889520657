import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import ClientSettingStore from '../ClientSetting/ClientSettingStore';

const AlertMoreMaxSumOrder: React.FC<{
  clientSettingStore: ClientSettingStore;
  allSum: number;
}> = observer(({ clientSettingStore, allSum }) => {
  const { t } = useTranslation(['basket']);
  const maxSum: number = clientSettingStore.maxSum;
  const isMoreMaxSum: boolean = maxSum <= allSum;
  return (
    <>
      {isMoreMaxSum ? (
        <Alert variant="danger">
          {t('basket:create.errorMaxSum')} {maxSum}
        </Alert>
      ) : null}
    </>
  );
});

export default AlertMoreMaxSumOrder;
