import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';

export const helpService = {
  getHelpText,
  saveHelpText,
};

function getHelpText(): Promise<any> {
  return ajaxRequest.get(urlRepository.getUrl('clientDocumentHelp'));
}

function saveHelpText(text: string): Promise<any> {
  return ajaxRequest.post(urlRepository.getUrl('adminDocumentHelp'), { text });
}
