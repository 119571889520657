import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Button, Card, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FieldArray, Formik, FormikErrors } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { toastHelper } from '../../../helpers';

import { globalSettingsService } from '../../../services';
import { LoadingButton } from '../../index';
import { DynamicPercent } from '../../../models/dynamic-percent';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

interface ResponseDynamicPercentForm {
  percents: DynamicPercent[];
}

const DynamicPercentForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ResponseDynamicPercentForm>({ percents: [] });
  const [forceReload, setForceReload] = useState(false);
  const { t } = useTranslation(['settings', 'notifications', 'form', 'buttons']);

  useEffect(() => {
    globalSettingsService.getDynamicPercentList().then(
      (response: DynamicPercent[]) => setValues({ percents: response }),
      (error) => console.log(error),
    );
  }, [forceReload]);

  const schema = yup.object({
    percents: yup.array().of(
      yup.object({
        sumBuys: yup
          .number()
          .min(1, t('form:error.minValue', { value: 1 }))
          .required(t('form:error.requiredField')),
        percent: yup
          .number()
          .min(0, t('form:error.minValue', { value: 0 }))
          .max(99, t('form:error.maxValue', { value: 99 }))
          .required(t('form:error.requiredField')),
      }),
    ),
  });

  const handleSubmit = (values: ResponseDynamicPercentForm, { setSubmitting }: any) => {
    setLoading(true);
    globalSettingsService.saveDynamicPercentList(values.percents).then(
      () => {
        toastHelper.success(t('notifications:success'));
        setLoading(false);
        setForceReload(!forceReload);
      },
      (error) => {
        toastHelper.error(`Error: ${error}`);
        setSubmitting(false);
        console.log(error);
        setLoading(false);
      },
    );
  };
  const dynamicPercentRow: DynamicPercent = { dynamicPercentListId: 0, percent: 0, sumBuys: 0 };
  let onAdd: any;

  return (
    <Card>
      <Formik validationSchema={schema} initialValues={values} enableReinitialize={true} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
            <Card.Header>
              {t('dynamic.title')}
              <div className="float-right">
                <Button size={'sm'} variant={'primary'} onClick={() => onAdd(dynamicPercentRow)}>
                  <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons:add')}
                </Button>
                <LoadingButton type={'submit'} size={'sm'} loading={loading} variant={'success'} className="ml-1">
                  <FontAwesomeIcon icon={faSave} /> {t('buttons:save')}
                </LoadingButton>
              </div>
            </Card.Header>
            <Card.Body>
              <FieldArray
                name="percents"
                render={({ unshift, remove }) => {
                  onAdd = unshift;
                  return (
                    <Table size={'sm'}>
                      <thead>
                        <tr>
                          <th>{t('dynamic.fieldSumBuys')}</th>
                          <th>{t('dynamic.fieldPercent')}</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {values.percents.length > 0 &&
                          values.percents.map((item, key) => {
                            return (
                              <tr key={item.dynamicPercentListId}>
                                {[{ field: 'sumBuys' }, { field: 'percent' }].map(({ field }) => {
                                  const errorText = (errors.percents as FormikErrors<DynamicPercent>[])?.[key]?.[field];
                                  return (
                                    <td key={`${item.dynamicPercentListId}_${field}`}>
                                      <Form.Control
                                        type={'number'}
                                        name={`percents[${key}].${field}`}
                                        value={item[field]}
                                        onChange={handleChange}
                                        isInvalid={!!errorText}
                                      />
                                      <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
                                    </td>
                                  );
                                })}
                                <td>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-royalty-remove-${item.dynamicPercentListId}`}>
                                        {t('buttons:delete')}
                                      </Tooltip>
                                    }
                                  >
                                    <Button variant="danger" onClick={() => remove(key)}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  );
                }}
              />
            </Card.Body>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default DynamicPercentForm;
