import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { ClientInfo } from '../../../models/client-info';

const FormRegIndividualFields: FC<{ t: any; values: ClientInfo; handleChange: any; errors: any }> = ({
  values,
  handleChange,
  errors,
  t,
}) => {
  return (
    <>
      {[
        { field: 'email', label: t('users:fieldEmail'), type: 'text' },
        { field: 'firstName', label: t('users:fieldFirstName'), type: 'text' },
        { field: 'country', label: t('users:infoField.country'), type: 'text' },
        { field: 'tradingWebsite', label: t('users:infoField.tradingWebsite'), type: 'text' },
      ].map(({ field, label }) => {
        return (
          <Form.Group controlId={`form${field}`} key={`key_ind_${field}`}>
            <Form.Label className="sr-only">{label}</Form.Label>
            <Form.Control
              type="text"
              name={field}
              placeholder={label}
              value={values[field]}
              onChange={handleChange}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">{errors[field]}</Form.Control.Feedback>
          </Form.Group>
        );
      })}
    </>
  );
};

export default FormRegIndividualFields;
