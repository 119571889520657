import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { LoadingButton } from '../index';
import ClientSettingStore from '../ClientSetting/ClientSettingStore';

const CreateOrderButton: React.FC<{
  loading: boolean;
  onClick: () => void;
  allSum: number;
  clientSettingStore: ClientSettingStore;
}> = observer(({ clientSettingStore, ...props }) => {
  const { t } = useTranslation('buttons');
  const isMoreMaxSum: boolean = clientSettingStore.maxSum <= props.allSum;
  const isExistOverduePayment: boolean = clientSettingStore.isExistOverduePayments;

  return (
    <>
      {!isMoreMaxSum && !isExistOverduePayment ? (
        <LoadingButton variant="primary" loading={props.loading} onClick={props.onClick}>
          {t('createOrder')}
        </LoadingButton>
      ) : null}
    </>
  );
});

export default CreateOrderButton;
