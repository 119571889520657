import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { LoadingButton } from '../index';
import ClientSettingStore from '../ClientSetting/ClientSettingStore';

const PaymentButton: React.FC<{
  loading: boolean;
  onClick: () => void;
  allSum: number;
  clientSettingStore: ClientSettingStore;
}> = observer(({ clientSettingStore, allSum, ...props }) => {
  const { t } = useTranslation('buttons');

  return (
    <>
      {clientSettingStore.maxSum >= allSum ? (
        <LoadingButton variant="success" {...props}>
          {t('buttons:pay')}
        </LoadingButton>
      ) : null}
    </>
  );
});

export default PaymentButton;
