import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';

export const clientSettingService = {
  changePassword,
};

function changePassword(params: any) {
  return ajaxRequest.patch(urlRepository.getUrl('clientChangePassword'), params);
}
