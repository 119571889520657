import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import ClientSettingStore from '../ClientSetting/ClientSettingStore';

const AlertOrdersOverduePayment: React.FC<{ clientSettingStore: ClientSettingStore }> = observer(
  ({ clientSettingStore }) => {
    const { t } = useTranslation(['translation', 'basket']);
    return (
      <>
        {clientSettingStore.isExistOverduePayments ? (
          <Alert variant="danger">
            <Trans i18nKey={'basket:create.errorOverdueOrders'} t={t}>
              <p>Your payment for pending orders is overdue. You will not be able to create a new order.</p>
              You can pay by going to the page
            </Trans>{' '}
            <Link to={'/payment-order/#overdue'}>{t('translation:navClientPaymentOrders')}</Link>
          </Alert>
        ) : null}
      </>
    );
  },
);

export default AlertOrdersOverduePayment;
