import React, { FC, useEffect } from 'react';
import { LoadingButton } from '../../index';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonFullUpdateViewModel from './ButtonFullUpdateViewModel';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const ButtonFullUpdateCatalogView: FC<{ viewModel: ButtonFullUpdateViewModel }> = observer(({ viewModel }) => {
  const { t } = useTranslation('catalog-table');

  useEffect(() => {
    let id: any = null;
    if (viewModel.isLoading) {
      id = setInterval(() => {
        if (!viewModel.isEndUpdate) {
          viewModel.updateByPage();
          return;
        }
        clearInterval(id);
        viewModel.setPage(0);
        viewModel.setLoading(false);
        viewModel.setSize(0);
      }, 2000);
    }
    return () => {
      id && clearInterval(id);
    };
  }, [viewModel, viewModel.size]);

  return (
    <>
      <LoadingButton
        className={'col-12'}
        size={'sm'}
        variant={'success'}
        loading={viewModel.isLoading}
        onClick={() => viewModel.startUpdate()}
      >
        <FontAwesomeIcon icon={faSyncAlt} /> {t('buttons.fullUpdateFromAPI')}
      </LoadingButton>
      {viewModel.isLoading ? <ProgressBar animated now={viewModel.progress} style={{ height: '0.2rem' }} /> : null}
    </>
  );
});

export default ButtonFullUpdateCatalogView;
