import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { translateService } from '../../services';

const cifRu = [
  '301 201',
  "<g fill='none'><path fill='#FFF' d='M.5.5h300v100H.5z'/><path fill='#D52B1E' d='M.5 100.5h300v100H.5z'/><path fill='#0039A6' d='M.5 67.166h300v66.667H.5z'/></g>",
];
const cifAu = [
  '301 151',
  "<defs><path id='a' d='M.5.5h150v75H.5z'/><path id='c' d='M.5.5V38h175v37.5h-25L.5.5zm150 0h-75V88H.5V75.5l150-75z'/></defs><g fill='none' fill-rule='evenodd'><path fill='#00008B' fill-rule='nonzero' d='M.5.5h300v150H.5z'/><mask id='b' fill='#fff'><use xlink:href='#a'/></mask><path fill='#000' fill-rule='nonzero' stroke='#FFF' stroke-width='17.578' d='M.5.5l150 75m0-75l-150 75' mask='url(#b)'/><mask id='d' fill='#fff'><use xlink:href='#c'/></mask><path fill='#000' fill-rule='nonzero' stroke='red' stroke-width='9.375' d='M.5.5l150 75m0-75l-150 75' mask='url(#d)'/><path fill='#000' fill-rule='nonzero' stroke='#FFF' stroke-width='23.438' d='M75.5.5V88M.5 38h175'/><path fill='#000' fill-rule='nonzero' stroke='red' stroke-width='14.063' d='M75.5.5v80.273M.5 38h155.273'/><path fill='#00008B' fill-rule='nonzero' d='M.5 75.5h150V.5h50v100H.5z'/><path fill='#FFF' fill-rule='nonzero' d='M75.5 90.5l4.339 13.491 13.252-5.019-7.842 11.803 12.187 7.232-14.118 1.228 1.944 14.037L75.5 123l-9.762 10.272 1.944-14.037-14.118-1.228 12.187-7.232-7.842-11.803 13.252 5.019zM225.5 114.785l2.066 6.424 6.311-2.39-3.734 5.621 5.803 3.444-6.722.585.926 6.684-4.649-4.891-4.649 4.891.926-6.684-6.722-.585 5.803-3.444-3.734-5.621 6.31 2.39zM188 55.411l2.066 6.424 6.311-2.39-3.734 5.621 5.803 3.444-6.722.585.926 6.684-4.65-4.892-4.649 4.891.926-6.684-6.723-.585 5.803-3.444-3.734-5.621 6.311 2.39zM225.5 14.786l2.066 6.424 6.311-2.39-3.734 5.62 5.803 3.444-6.722.585.926 6.684-4.649-4.891-4.649 4.891.926-6.684-6.722-.585 5.803-3.444-3.734-5.62 6.31 2.39zM258.833 45.411l2.066 6.424 6.311-2.39-3.734 5.621 5.803 3.444-6.723.585.926 6.684-4.649-4.891-4.649 4.891.926-6.684-6.723-.585 5.803-3.444-3.734-5.621 6.311 2.39zM240.5 75.5l1.633 4.003 4.311.316-3.302 2.79 1.032 4.198-3.674-2.279-3.674 2.279 1.032-4.198-3.302-2.79 4.311-.316z'/></g>",
];

const LanguageSwitch: FC = () => {
  const { t } = useTranslation();

  const handleSwitch = (lang: string) => {
    translateService.switchLng(lang);
    document.location.reload();
  };

  return (
    // @ts-ignore
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-translate`}>{t('translationMenu')}</Tooltip>}>
          <div>
            <FontAwesomeIcon icon={faGlobeEurope} size={'lg'} />
            <FontAwesomeIcon icon={faCaretDown} size={'sm'} className="ml-1" />
          </div>
        </OverlayTrigger>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem onClick={() => handleSwitch('ru-RU')}>
          <CIcon content={cifRu} size="2xl" className="mfe-2" />
          Русский
        </CDropdownItem>
        <CDropdownItem onClick={() => handleSwitch('en')}>
          <CIcon content={cifAu} size="2xl" className="mfe-2" />
          English
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default LanguageSwitch;
