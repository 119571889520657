import { action, computed, flow, observable } from 'mobx';
import { ClientSettingPurchases } from '../../models/client-setting-purchases';
import { ajaxRequest } from '../../helpers';
import { urlRepository } from '../../routes';
import { orderService } from '../../services';

class ClientSettingStore {
  @observable
  settingPurchases: ClientSettingPurchases | null = null;
  @observable
  countOrdersAwaitPay: number | null = null;

  @action
  loadSettingsPurchases = flow(function* (this: ClientSettingStore) {
    try {
      this.settingPurchases = yield ajaxRequest.get(urlRepository.getUrl('clientSettingPurchases'));
    } catch (error) {
      console.log(error.toString());
    }
  });

  @action
  loadCountOrdersAwaitPay = flow(function* (this: ClientSettingStore) {
    if (this.settingPurchases?.delayedPaymentFlag !== true) {
      return;
    }
    try {
      const response = yield orderService.getRemoteCountOrdersAwaitPay();
      this.countOrdersAwaitPay = response.count;
    } catch (error) {
      console.log(error.toString());
    }
  });

  @action
  setCountOrdersAwaitPay(value: number) {
    this.countOrdersAwaitPay = value;
  }

  @computed
  get getCountOrdersAwaitPay() {
    return this.countOrdersAwaitPay;
  }

  @computed
  get isDelayedPayment() {
    return this.settingPurchases?.delayedPaymentFlag;
  }

  @computed
  get isDownloadKeysAfterPayment() {
    return this.settingPurchases?.downloadKeysAfterPaymentFlag;
  }

  @computed
  get canBeGiveAwayKeysByClient() {
    return this.settingPurchases?.giveAwayKeysByClientFlag;
  }

  @computed
  get isCashPayment() {
    return this.settingPurchases?.cashPaymentFlag;
  }

  @computed
  get intervalDelayedPaymentNumber() {
    return this.settingPurchases?.intervalDelayedPaymentNumber;
  }

  @computed
  get maxSum(): number {
    return this.settingPurchases?.maxSum as number;
  }

  @computed
  get isExistOverduePayments(): boolean {
    return this.settingPurchases?.isExistOrdersOverduePayment as boolean;
  }

  @computed
  get isEnableBukacoin(): boolean {
    return this.settingPurchases?.isEnableBukacoin as boolean;
  }
}

export default ClientSettingStore;
