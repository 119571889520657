import React, { useEffect, useState } from 'react';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip, Popover } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { SortOrder } from 'react-bootstrap-table-next';
import Select from 'react-select';
import { User } from '../../../models/user';
import GeneralTable from '../GeneralTable';
import { userService } from '../../../services';
import { rolesList } from '../../../helpers/user/roles';
import { ModalWarning } from '../../index';
import { toastHelper } from '../../../helpers';

let filterRoleFunc: any = null;

const UserTable: React.FC = () => {
  const [roles, setRoles] = useState<any[]>([]);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [userModalDelete, setUserShowModalDelete] = useState<{ id: number; email: string }>({ id: 0, email: '' });
  const { t } = useTranslation(['users', 'search-element', 'notifications']);

  useEffect(() => {
    setRoles(rolesList.map(({ label, value }) => ({ value, label: t(label) })));
  }, [t]);

  const onShowModalDeleteUser = (id: number, email: string) => {
    setShowModalDelete(true);
    setUserShowModalDelete({ id, email });
  };

  const onDeleteUser = (id: number) => {
    userService.removeUser(id).then(
      () => {
        setShowModalDelete(false);
        toastHelper.success(t('notifications:successDelete'));
        handleFilterRole(null);
      },
      (error) => {
        toastHelper.error(t('notifications:error'));
        console.log(error);
      },
    );
  };

  const columns: any[] = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'email', text: t('fieldEmail'), sort: true },
    { dataField: 'firstName', text: t('fieldFirstName'), sort: true, headerStyle: { width: '20%' } },
    { dataField: 'lastName', text: t('fieldLastName'), sort: true, headerStyle: { width: '20%' } },
    { dataField: 'phoneNumber', text: t('fieldPhone'), sort: true },
    {
      dataField: 'role',
      text: t('fieldRole'),
      sort: false,
      formatter: (cellContent: any, row: User) => roles.find((item: any) => item.value === row.roles?.[1])?.label,
      filter: selectFilter({
        options: roles,
        className: 'd-none',
        getFilter: (filter) => (filterRoleFunc = filter),
      }),
    },
    {
      dataField: 'buttonEdit',
      text: '',
      formatter: (cellContent: any, row: User) => {
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-user-info-${row.id}`}>{t('buttons.edit')}</Tooltip>}
          >
            <LinkContainer to={`/users/${row.id}`}>
              <Button variant="info" size={'sm'}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </LinkContainer>
          </OverlayTrigger>
        );
      },
      classes: 'text-center',
    },

    {
      dataField: 'buttonDelete',
      text: '',
      formatter: (cellContent: any, row: User) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-user-remove-${row.id}`}>{t('buttons.remove')}</Tooltip>}
        >
          <Button variant="danger" onClick={() => onShowModalDeleteUser(row.id as number, row.email)} size={'sm'}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </OverlayTrigger>
      ),
      classes: 'text-center',
    },

    {
      dataField: 'buttonSignIn',
      text: '',
      formatter: (cellContent: any, row: User) => (
        <OverlayTrigger
          placement="top"
          trigger={'click'}
          overlay={
            <Popover id={`popover-user-log-in-${row.id}`}>
              <Popover.Title as="h3">{t('buttons.login-as-user')}</Popover.Title>
              <Popover.Content>
                <a href={`/auth/${row.password}`}>{t('buttons.link-login-as-user')}</a>
              </Popover.Content>
            </Popover>
          }
        >
          <Button variant="primary" size={'sm'}>
            <FontAwesomeIcon icon={faSignInAlt} />
          </Button>
        </OverlayTrigger>
      ),
      classes: 'text-center',
    },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'firstName',
      order: 'asc',
    },
  ];

  const handleFilterRole = (data: any) => filterRoleFunc(data && data.value);

  const getData: (params: any) => Promise<User[]> = (params: any) => userService.getUsers(params);
  return (
    <>
      <Row>
        <Col md={'auto'} as={Form.Group}>
          <LinkContainer to={'/users/create'}>
            <Button variant={'success'}>
              <FontAwesomeIcon icon={faUserPlus} /> {t('buttons.create')}
            </Button>
          </LinkContainer>
        </Col>
        <Col md={3} as={Form.Group}>
          <Select options={roles} onChange={handleFilterRole} isClearable placeholder={t('fieldRole')} />
        </Col>
      </Row>
      <GeneralTable
        defaultSorted={defaultSorted}
        columns={columns}
        getData={getData}
        placeholderSearch={t('search-element:placeholder.search')}
        filter={filterFactory()}
      />
      <ModalWarning
        show={showModalDelete}
        toggleClose={() => setShowModalDelete(false)}
        data={userModalDelete.id}
        toggleSuccess={onDeleteUser}
        title={t('modal.titleDeleteUser', { email: userModalDelete.email })}
      >
        <Trans i18nKey={'modal.bodyDeleteUser'} t={t}>
          Are you sure you want to delete the username<strong> "{{ email: userModalDelete.email }}"</strong> and all his
          data?<strong style={{ color: 'red' }}> This operation is irreversible!</strong>
        </Trans>
      </ModalWarning>
    </>
  );
};

export default UserTable;
