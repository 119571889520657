import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import CreateOrderStore from '../CreateOrder/CreateOrderStore';

const AddProductForCreateOrderButton: React.FC<{
  productID: number;
  nds: number;
  createOrderStore: CreateOrderStore;
}> = observer((props) => {
  const { t } = useTranslation('buttons');

  const isChecked = props.createOrderStore.existProduct(props.productID);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-change-basket-${props.productID}`}>
          {!isChecked ? t('addToOrder') : t('removeFromOrder')}
        </Tooltip>
      }
    >
      <Button
        variant={!isChecked ? 'success' : 'danger'}
        onClick={() => {
          if (!isChecked) {
            props.createOrderStore.add(props.productID, props.nds === 1);
          } else {
            props.createOrderStore.remove(props.productID);
          }
        }}
      >
        {!isChecked ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
      </Button>
    </OverlayTrigger>
  );
});

export default React.memo(AddProductForCreateOrderButton);
