import React, { useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { Currency, LoadingButton } from '../../index';
import * as yup from 'yup';
import { catalogService } from '../../../services';
import { toastHelper } from '../../../helpers';
import { ProductEditFields } from '../../../models/product-edit-fields';
import { CSwitch } from '@coreui/react';

const ProductEditForm: React.FC<any> = (props) => {
  const id: any = props.match.params.id;

  const { t } = useTranslation(['catalog-table', 'notifications', 'form', 'buttons']);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: ProductEditFields, { setSubmitting }: any) => {
    setLoading(true);
    catalogService.saveAdminProduct(id, values).then(
      () => {
        setLoading(false);
        toastHelper.success(t('notifications:success'));
      },
      (error) => {
        toastHelper.error(t('notifications:error'));
        setSubmitting(false);
        console.log(error);
        setLoading(false);
      },
    );
  };

  const schema = yup.object({
    priceFix: yup
      .number()
      .required(t('form:error.requiredField'))
      .min(0, t('form:error.minValue', { value: 0 })),
    maxCountForBuy: yup
      .number()
      .required(t('form:error.requiredField'))
      .integer(t('form:error.integerNumber'))
      .min(0, t('form:error.minValue', { value: 0 })),
  });

  const stateForm: ProductEditFields = props.stateForm;

  return (
    <Card>
      <Card.Header>{t('form.headerEdit')}</Card.Header>
      <Card.Body>
        <Formik validationSchema={schema} initialValues={stateForm} enableReinitialize={true} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
              {[
                { field: 'price', trans: 'fieldPrice', type: 'static' },
                { field: 'priceFix', trans: 'fieldPriceFix', type: 'number' },
                { field: 'maxCountForBuy', trans: 'fieldMaxCountForBuy', type: 'number' },
                { field: 'publication', trans: 'fieldPublication', type: 'check' },
                { field: 'visible', trans: 'fieldVisible', type: 'check' },
              ].map(({ field, trans, type }) => {
                return (
                  <Form.Group controlId={field} key={field}>
                    {type === 'static' ? (
                      <>
                        <Form.Label>{t(trans)}</Form.Label>
                        <div className={'form-control-plaintext'}>
                          <Currency
                            value={(values.priceCurrency === null ? values.price : values.priceCurrency) as number}
                          />
                        </div>
                      </>
                    ) : null}
                    {type === 'number' ? (
                      <>
                        <Form.Label>{t(trans)}</Form.Label>
                        <Form.Control
                          type={type}
                          name={field}
                          value={values[field]}
                          onChange={handleChange}
                          isInvalid={!!errors[field]}
                        />
                        <Form.Control.Feedback type="invalid">{errors[field]}</Form.Control.Feedback>
                      </>
                    ) : null}
                    {type === 'check' ? (
                      <>
                        <CSwitch
                          className={'mx-1 float-left'}
                          id={`${field}_id`}
                          name={field}
                          variant={'3d'}
                          color={'success'}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                          onChange={handleChange}
                          checked={values[field]}
                        />
                        <Form.Label>{t(trans)}</Form.Label>
                      </>
                    ) : null}
                  </Form.Group>
                );
              })}

              <Form.Group>
                <LoadingButton variant="success" type="submit" loading={loading}>
                  <FontAwesomeIcon icon={faSave} /> {t('buttons:edit')}
                </LoadingButton>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default ProductEditForm;
