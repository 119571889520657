import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';

import { contractService } from '../../services';
import { toastHelper } from '../../helpers';
import { CKEditorWrapper, LoadingButton } from '../index';

const DigitalServiceAgreementEdit: React.FC = () => {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['buttons', 'notifications']);

  useEffect(() => {
    contractService.getContractDigitalService().then(
      (response) => setText(response.text),
      (error) => console.log(error),
    );
  }, []);

  const onSave = () => {
    setLoading(true);
    contractService.saveContractDigitalService(text).then(
      () => {
        setLoading(false);
        toastHelper.success(t('notifications:success'));
      },
      (error) => {
        toastHelper.error(t('notifications:error'));
        console.log(error);
      },
    );
  };

  return (
    <>
      <LoadingButton as={Form.Group} variant={'success'} className="mt-3" loading={loading} onClick={() => onSave()}>
        <FontAwesomeIcon icon={faSave} /> {t('buttons:save')}
      </LoadingButton>
      <CKEditorWrapper data={text} onChange={(text: string) => setText(text)} />
    </>
  );
};

export default DigitalServiceAgreementEdit;
