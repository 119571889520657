import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../index';
import { userService } from '../../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const UserNewPassword: FC<{ userId: number }> = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string | null>(null);
  const { t } = useTranslation('users');

  const handleClick = () => {
    setLoading(true);
    userService.newPassword(userId).then(
      (response: { password: string }) => {
        setLoading(false);
        setPassword(response.password);
      },
      (error) => {
        setLoading(false);
        console.log(error);
      },
    );
  };
  return (
    <span>
      {password && <strong className="mr-1">{t('newPasswordText', { password })}</strong>}
      <LoadingButton loading={loading} variant={'warning'} onClick={handleClick} size={'sm'}>
        <FontAwesomeIcon icon={faPaperPlane} /> {t('buttons.new-password')}
      </LoadingButton>
    </span>
  );
};

export default UserNewPassword;
