import { authenticationService } from '../services';

export function authHeader(): any {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    return {
      Authorization: `Bearer ${currentUser.token}`,
      'X-Remote-Addr': currentUser.ip,
    };
  } else {
    return { 'X-Remote-Addr': authenticationService.ipUser };
  }
}
