import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';
import { Order } from '../models/order';

export const orderService = {
  getRemoteOrders,
  getRemoteOrder,
  getRemoteOrderProducts,
  getRemoteOrderStatus,
  getRemoteOrdersAwaitPay,
  getRemoteSumOrdersAwaitPay,
  getRemoteCountOrdersAwaitPay,
  createOrder,
  changeStatus,
  changeColor,
  giveAwayOrder,
  downloadOrder,
  createOrderAdmin,
  saveOrderAdmin,
};

function getRemoteOrders(params: object) {
  return ajaxRequest.get(urlRepository.getUrl('clientOrder'), params);
}

function getRemoteOrder(id: number) {
  return ajaxRequest.get(urlRepository.getUrlWithId('clientOrderDetail', id));
}

function getRemoteOrderProducts(id: number, params: object) {
  return ajaxRequest.get(urlRepository.getUrlWithId('clientOrderDetail', id), params);
}

function getRemoteOrderStatus() {
  return ajaxRequest.get(urlRepository.getUrl('clientOrderStatus'));
}

function getRemoteOrdersAwaitPay(params: object) {
  return ajaxRequest.get(urlRepository.getUrl('clientOrderAwaitPay'), params);
}

function getRemoteSumOrdersAwaitPay() {
  return ajaxRequest.get(urlRepository.getUrl('clientOrderAwaitPayAllSum'));
}

function getRemoteCountOrdersAwaitPay() {
  return ajaxRequest.get(urlRepository.getUrl('clientOrderAwaitPayCount'));
}

function createOrder(params: object) {
  return ajaxRequest.put(urlRepository.getUrl('clientOrderCreate'), params);
}

function changeStatus(id: number, operation: object) {
  return ajaxRequest.post(urlRepository.getUrlWithId('clientOrderChangeStatus', id), operation);
}

function changeColor(id: number, color: string | null) {
  return ajaxRequest.put(urlRepository.getUrlWithId('clientOrderChangeColor', id), { color });
}

function giveAwayOrder(id: number) {
  return ajaxRequest.post(urlRepository.getUrlWithId('clientOrderGiveAway', id), {});
}

function downloadOrder(order: Order) {
  ajaxRequest.downloadFile(
    urlRepository.getUrlWithId('clientDownloadOrderKey', order.id),
    `order_${order.orderNumber}.xlsx`,
  );
}

function createOrderAdmin(values: Order) {
  return ajaxRequest.put(urlRepository.getUrlWithId('adminOrderOne', 0), values);
}

function saveOrderAdmin(id: number, values: Order) {
  return ajaxRequest.post(urlRepository.getUrlWithId('adminOrderOne', id), values);
}
