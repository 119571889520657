import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../hooks/use-store';
import BasketModel from '../Basket/BasketModel';

const BasketChangeButton: React.FC<{ productID: number }> = observer(({ productID }) => {
  const { basketViewModel } = useStores();
  const { t } = useTranslation('basket');

  const isCheck = basketViewModel.existProduct(productID);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-change-basket-${productID}`}>
          {isCheck ? t('button.deleteFromBasket') : t('button.addToBasket')}
        </Tooltip>
      }
    >
      <Button
        variant={!isCheck ? 'success' : 'danger'}
        onClick={
          !isCheck
            ? () => basketViewModel.add(new BasketModel({ productID: productID, count: 1 }))
            : () => basketViewModel.remove(productID)
        }
        size={'sm'}
      >
        {!isCheck ? <FontAwesomeIcon icon={faCartPlus} /> : <FontAwesomeIcon icon={faTrashAlt} />}
      </Button>
    </OverlayTrigger>
  );
});

export default BasketChangeButton;
