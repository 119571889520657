import React, { FC, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { AlertRequestRegistration, LoadingButton } from '../../index';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { registrationService } from '../../../services';
import { validation } from '../../../helpers/validation';
import FormRegIndividualFields from './FormRegIndividualFields';
import FormRegCompanyFields from './FormRegCompanyFields';
import { ClientInfo } from '../../../models/client-info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const FormReg: FC<{ onBack: () => void }> = ({ onBack }) => {
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [typeForm, setTypeForm] = useState(0);
  const { t } = useTranslation(['sign-in', 'form', 'users']);

  const initialState: ClientInfo = {
    email: '',
    firstName: '',
    country: '',
    tradingWebsite: '',
    companyName: '',
    registeredAddress: '',
    taxId: '',
    bank: '',
    bankAddress: '',
    bankBicOrSwift: '',
  };

  const schemaIndividual = yup.object({
    email: yup.string().email(t('form:error.notValidEmail')).required(t('form:error.requiredField')),
    firstName: yup
      .string()
      .matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber'))
      .required(t('form:error.requiredField')),
    country: yup
      .string()
      .matches(validation.latinString, t('form:error.latinString'))
      .required(t('form:error.requiredField')),
    tradingWebsite: yup.string().url(t('form:error.url')).required(t('form:error.requiredField')),
  });

  const schemaCompany = yup.object({
    email: yup.string().email(t('form:error.notValidEmail')).required(t('form:error.requiredField')),
    country: yup
      .string()
      .matches(validation.latinString, t('form:error.latinString'))
      .required(t('form:error.requiredField')),
    companyName: yup
      .string()
      .matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber'))
      .required(t('form:error.requiredField')),
    registeredAddress: yup
      .string()
      .matches(validation.latinStringAndSymbols, t('form:error.latinStringAndSymbols'))
      .required(t('form:error.requiredField')),
    taxId: yup
      .string()
      .matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber'))
      .required(t('form:error.requiredField')),
    bank: yup.string().matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber')),
    bankAddress: yup.string().matches(validation.latinStringAndSymbols, t('form:error.latinStringAndSymbols')),
    bankBicOrSwift: yup.string().matches(validation.latinStringAndNumber, t('form:error.latinStringAndNumber')),
  });
  const handleSubmit = (values: ClientInfo, { setSubmitting, resetForm }: any) => {
    setLoading(true);
    registrationService.sendForm(values).then(
      () => {
        setLoading(false);
        setIsSend(true);
        resetForm();
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setSubmitting(false);
      },
    );
  };

  return (
    <Formik
      validationSchema={() => (typeForm === 0 ? schemaIndividual : schemaCompany)}
      initialValues={initialState}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
          <AlertRequestRegistration send={isSend} />
          <Form.Row>
            {['Individual', 'Company'].map((type, ind) => (
              <Col as={Form.Group} key={`key-${type}`}>
                <Form.Check
                  type={'radio'}
                  id={`id-${type}`}
                  name={'type_form'}
                  label={type}
                  defaultChecked={ind === 0}
                  onChange={() => setTypeForm(ind)}
                />
              </Col>
            ))}
          </Form.Row>
          {typeForm === 0 ? (
            <FormRegIndividualFields t={t} values={values} handleChange={handleChange} errors={errors} />
          ) : (
            <FormRegCompanyFields t={t} values={values} handleChange={handleChange} errors={errors} />
          )}
          <Form.Group>
            <Button variant={'danger'} onClick={onBack} className="float-left py-1 px-4 mr-1">
              <FontAwesomeIcon icon={faAngleLeft} /> {t('reg.back')}
            </Button>
            <LoadingButton variant="primary" type="submit" loading={loading} className="py-1 px-4">
              {t('reg.send')}
            </LoadingButton>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default FormReg;
