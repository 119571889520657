import React, { useState } from 'react';
import { Form, Alert, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';

import { LoadingButton } from '../';
import { clientSettingService } from '../../services';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';

interface FormState {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const FormChangePassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('settings');
  const schema = yup.object({
    oldPassword: yup.string().required(t('error.requiredField')),
    newPassword: yup.string().required(t('error.requiredField')),
    repeatNewPassword: yup
      .string()
      .required(t('error.requiredField'))
      .oneOf([yup.ref('newPassword'), null], t('error.passwordMatch')),
  });

  const handleSubmit = (values: FormState, { resetForm, setStatus, setSubmitting }: any) => {
    setStatus();
    setIsLoading(true);
    clientSettingService
      .changePassword(values)
      .then(() => {
        resetForm({});
        setStatus(1);
        setIsLoading(false);
      })
      .catch((error) => {
        setStatus(error === 'error_not_valid_old_passw' ? 0 : 2);
        setSubmitting(false);
        console.log(error);
        setIsLoading(false);
      });
  };

  const initialState: FormState = {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  };

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faShieldAlt} /> {t('headerChangePassword')}
      </Card.Header>
      <Card.Body>
        <Formik validationSchema={schema} initialValues={initialState} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, errors, status }) => (
            <Form validated={false} onSubmit={(e: any) => handleSubmit(e)}>
              <Form.Group controlId="formOldPassword">
                <Form.Label>{t('fieldOldPassword')}</Form.Label>
                <Form.Control
                  type="password"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.oldPassword}
                />
                <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formNewPassword">
                <Form.Label>{t('fieldNewPassword')}</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  value={values.newPassword}
                  isInvalid={!!errors.newPassword}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRepeatNewPassword">
                <Form.Label>{t('fieldRepeatNewPassword')}</Form.Label>
                <Form.Control
                  type="password"
                  name="repeatNewPassword"
                  value={values.repeatNewPassword}
                  isInvalid={!!errors.repeatNewPassword}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.repeatNewPassword}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <LoadingButton variant="success" type="submit" loading={isLoading}>
                  <FontAwesomeIcon icon={faSave} /> {t('btnSave')}
                </LoadingButton>
              </Form.Group>
              {status === 0 && <Alert variant="danger">{t('error.notValidOldPassword')}</Alert>}
              {status === 1 && <Alert variant="success">{t('successSubmitPassword')}</Alert>}
              {status === 2 && <Alert variant="danger">{t('error.submitPassword')}</Alert>}
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default FormChangePassword;
