import React, { FC, useEffect, useState } from 'react';
import { authenticationService } from '../../../services';
import { Trans, useTranslation } from 'react-i18next';
import { statusTransfer } from '../../../helpers/transfer/status-transfer';
import transferService from '../../../services/transfer.service';
import { InvoiceTransfer } from '../../../models/invoice-transfer';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { Button, Col, Form, OverlayTrigger, Tooltip, Row, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileExcel, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { SortOrder } from 'react-bootstrap-table-next';
import moment from 'moment';
import GeneralTable from '../GeneralTable';
import Select from 'react-select';
import DateRangePickerWrapper from '../../DateRangePickerWrapper/DateRangePickerWrapper';
import { toastHelper } from '../../../helpers';
import ModalWarning from '../../ModalWindow/ModalWarning';

let filterStatus: any = null;
let filterStartDateFunc: any = null;
let filterEndDateFunc: any = null;

const TransferTable: FC = () => {
  const { t } = useTranslation(['transfer', 'buttons', 'search-element', 'dates', 'notifications']);
  const [stateStatuses, setStateStatuses] = useState<Array<{ label: string; value: number }>>([]);
  const [forceReload, setForceReload] = useState<number>(1);
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [showModalPaid, setShowModalPaid] = useState<boolean>(false);
  const [transferID, setTransferID] = useState<{ id: number; number: string } | null>(null);

  const STATUS_ACTIVE: number = 1,
    STATUS_PAID = 3;

  const isAdmin = authenticationService.isAdmin;
  useEffect(() => {
    setStateStatuses(statusTransfer.map((item) => ({ value: item.value, label: t(item.label) })));
  }, [t]);

  const onShowCancelModal = (transfer: InvoiceTransfer) => {
    setShowModalCancel(true);
    setTransferID({ id: transfer.id, number: transfer.transferNumber });
  };
  const onShowPaidModal = (transfer: InvoiceTransfer) => {
    setShowModalPaid(true);
    setTransferID({ id: transfer.id, number: transfer.transferNumber });
  };

  function onCancelTransfer(id: number) {
    transferService.cancel(id).then(
      () => {
        toastHelper.success(t('notifications:successOperation'));
        setForceReload(forceReload + 1);
      },
      (error) => {
        toastHelper.error(t('notifications:error'));
        console.log(error);
      },
    );
    setShowModalCancel(false);
  }

  function onPaidTransfer(id: number) {
    transferService.paid(id).then(
      () => {
        toastHelper.success(t('notifications:successOperation'));
        setForceReload(forceReload + 1);
      },
      (error) => {
        toastHelper.error(t('notifications:error'));
        console.log(error);
      },
    );
    setShowModalPaid(false);
  }

  const columns: any[] = [
    { dataField: 'id', text: '', hidden: true },
    { dataField: 'transferNumber', text: t('transfer:fields.transferNumber'), sort: true },
    {
      dataField: 'email',
      text: t('transfer:fields.email'),
      sort: true,
    },
    {
      dataField: 'startDate',
      text: t('transfer:fields.dateCreate'),
      sort: true,
      formatter: (cellContent: any, row: InvoiceTransfer) => row.dateCreate,
      filter: selectFilter({
        options: {},
        className: 'd-none',
        getFilter: (filter) => (filterStartDateFunc = filter),
      }),
    },
    {
      dataField: 'endDate',
      text: t('transfer:fields.sum'),
      sort: true,
      formatter: (cellContent: any, row: InvoiceTransfer) => row.sum,
      filter: selectFilter({
        options: {},
        className: 'd-none',
        getFilter: (filter) => (filterEndDateFunc = filter),
      }),
    },
    {
      dataField: 'status',
      text: t('transfer:fields.status'),
      sort: true,
      formatter: (cellContent: any, row: InvoiceTransfer) => {
        const value = stateStatuses.find((item) => item.value === row.status)?.label;
        return (
          <Badge
            pill
            variant={row.status === STATUS_ACTIVE ? 'warning' : row.status === STATUS_PAID ? 'success' : 'danger'}
          >
            {value}
          </Badge>
        );
      },
      filter: selectFilter({
        options: {},
        className: 'd-none',
        getFilter: (filter) => (filterStatus = filter),
      }),
    },
    {
      dataField: 'datePayment',
      text: t('transfer:fields.datePayment'),
      sort: true,
    },

    {
      dataField: 'buttonPaid',
      text: '',
      hidden: !isAdmin,
      formatter: (cellContent: any, row: InvoiceTransfer) => (
        <>
          {row.status === STATUS_ACTIVE ? (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-paid-${row.id}`}>{t('transfer:tooltip.paid')}</Tooltip>}
            >
              <Button variant="success" onClick={() => onShowPaidModal(row)} size={'sm'}>
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </OverlayTrigger>
          ) : null}
        </>
      ),
    },
    {
      dataField: 'buttonCancel',
      text: '',
      formatter: (cellContent: any, row: InvoiceTransfer) => (
        <>
          {row.status === STATUS_ACTIVE ? (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-cancel-${row.id}`}>{t('transfer:tooltip.cancel')}</Tooltip>}
            >
              <Button variant="danger" onClick={() => onShowCancelModal(row)} size={'sm'}>
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </OverlayTrigger>
          ) : null}
        </>
      ),
    },
    {
      dataField: 'buttonUpload',
      text: '',
      formatter: (cellContent: any, row: InvoiceTransfer) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-upload-${row.id}`}>{t('buttons:tooltipDownload')}</Tooltip>}
        >
          <Button variant="warning" onClick={() => transferService.uploadFile(row)} size={'sm'}>
            <FontAwesomeIcon icon={faFileExcel} />
          </Button>
        </OverlayTrigger>
      ),
    },
  ];
  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'startDate',
      order: 'desc',
    },
  ];

  const getData: (params: any) => Promise<InvoiceTransfer[]> = (params: any) => {
    return transferService.getRemoteTransfers(params);
  };
  const handleFilterStatus = (data: any) => filterStatus(data && data.value);
  const handleFilterStartDate = (date: moment.Moment) => {
    filterStartDateFunc(date && date.toISOString());
    return date;
  };
  const handleFilterEndDate = (date: moment.Moment) => {
    filterEndDateFunc(date && date.toISOString());
    return date;
  };

  return (
    <>
      <Row>
        <Col sm={3} as={Form.Group}>
          <DateRangePickerWrapper
            showClearDates
            readOnly
            startDatePlaceholderText={t('dates:placeholderStartDate')}
            endDatePlaceholderText={t('dates:placeholderFinishedDate')}
            onChangeStartDate={handleFilterStartDate}
            onChangeEndDate={handleFilterEndDate}
            isOutsideRange={() => false}
          />
        </Col>
        <Col md={3} lg={2} as={Form.Group}>
          <Select
            options={stateStatuses}
            onChange={handleFilterStatus}
            isClearable
            placeholder={t('transfer:fields.status')}
          />
        </Col>
      </Row>
      <GeneralTable
        key={forceReload}
        defaultSorted={defaultSorted}
        columns={columns}
        getData={getData}
        placeholderSearch={t('search-element:placeholder.invoice_transfer_number')}
        filter={filterFactory()}
      />

      <ModalWarning
        show={showModalCancel}
        toggleClose={() => setShowModalCancel(false)}
        data={transferID?.id}
        toggleSuccess={onCancelTransfer}
        title={t('transfer:modal.titleCancelTransfer')}
      >
        <Trans i18nKey={'transfer:modal.textWarningCancelTransfer'} t={t}>
          Are you sure you want to cancel the transfer with NUM={{ number: transferID?.number }}?
          <strong style={{ color: 'red' }}> This operation is irreversible!</strong>
        </Trans>
      </ModalWarning>
      <ModalWarning
        show={showModalPaid}
        toggleClose={() => setShowModalPaid(false)}
        data={transferID?.id}
        toggleSuccess={onPaidTransfer}
        title={t('transfer:modal.titlePaidTransfer')}
      >
        <Trans i18nKey={'transfer:modal.textWarningPaidTransfer'} t={t}>
          Are you sure you want to place a payment for the transfer with NUM={{ number: transferID?.number }}?
          <strong style={{ color: 'red' }}> This operation is irreversible!</strong>
        </Trans>
      </ModalWarning>
    </>
  );
};

export default TransferTable;
