import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { CListGroup, CListGroupItem } from '@coreui/react';

const OptionCurrency: FC<{
  field: string;
  handleChange: any;
  errorText: any;
  value: any;
  currencyValue: number;
}> = ({ field, handleChange, errorText, value, currencyValue }) => {
  const { t } = useTranslation('settings');
  const currency = process.env.REACT_APP_CURRENCY;

  return (
    <>
      <Form.Group>
        <CListGroup accent>
          <CListGroupItem accent="success" color="success">
            <span>
              <Trans t={t} i18nKey={'global.textCurrencyValue'}>
                Currency rate <strong>{{ currency }}</strong> for the current day
              </Trans>
              : <strong>{currencyValue}</strong>
            </span>
          </CListGroupItem>
        </CListGroup>
      </Form.Group>
      <Form.Group controlId={field}>
        <Form.Label>{t('global.fieldIndexCurrencyFlu')}</Form.Label>
        <Form.Control
          type={'number'}
          name={`globalOption.${field}`}
          value={value}
          onChange={handleChange}
          isInvalid={!!errorText}
        />
        <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default OptionCurrency;
