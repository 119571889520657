import { action, computed, flow, observable } from 'mobx';
import { DiscountProduct } from '../../models/discount-product';
import { discountService } from '../../services';

class CreateOrderStore {
  @observable
  productCounts: { productID: number; count: number }[] = [];
  @observable
  nds: boolean | null = null;
  @observable
  productPrices: DiscountProduct[] = [];
  @observable
  countsMoreMaxCount: { name: string; maxCount: number }[] = [];

  @action
  clear() {
    this.productCounts = [];
    this.productPrices = [];
    this.countsMoreMaxCount = [];
    this.nds = null;
  }

  @action
  add(productID: number, nds: boolean) {
    this.productCounts.push({ productID: productID, count: 1 });
    this.nds = nds;
  }

  @action
  remove(productID: number) {
    this.productCounts = this.productCounts.filter((item) => item.productID !== productID);
    this.productPrices = this.productPrices.filter((item) => item.id !== productID);
    if (this.productCounts.length === 0) {
      this.nds = null;
    }
  }

  @action
  setProductCounts(counts: { productID: number; count: number }[]) {
    this.productCounts = counts;
  }

  @action
  loadProductPrices = flow(function* (this: CreateOrderStore, discountID: number) {
    try {
      this.productPrices = yield discountService.getProductPrices(
        discountID,
        this.productCounts.map((item) => item.productID),
      );
    } catch (error) {
      console.log(error.toString());
    }
  });

  @action
  changeCountByProduct(productID: number, count: number) {
    const ind = this.productCounts.findIndex((item) => item.productID === productID);
    this.productCounts[ind].count = Math.abs(Math.round(count || 1));
    this.checkCountsMoreMaxCount();
  }

  countById(productID: number): number {
    return this.productCounts.find((item) => item.productID === productID)?.count as number;
  }

  sumById(productID: number): number {
    return (
      this.countById(productID) * (this.productPrices.find((item) => item.id === productID)?.finishPrice as number)
    );
  }

  existProduct(productID: number): boolean {
    return this.productCounts.find((item) => item.productID === productID) !== undefined;
  }

  @computed
  get allSum(): number {
    return this.productCounts.reduce((sum: number, item) => sum + this.sumById(item.productID), 0);
  }

  @computed
  get lengthCounts(): number {
    return this.productCounts.length;
  }

  @action
  checkCountsMoreMaxCount(): void {
    const products = this.productPrices.filter((prices) => {
      const ind = this.productCounts.findIndex((item) => item.productID === prices.id);
      return prices.maxCountForBuy !== 0 && prices.maxCountForBuy < this.productCounts[ind].count;
    });
    this.countsMoreMaxCount = products.map((item) => ({ name: item.productName, maxCount: item.maxCountForBuy }));
  }

  @computed
  get isExistMoreMaxCount(): boolean {
    return this.countsMoreMaxCount.length > 0;
  }

  @computed
  get isExistCountMoreRemainingPart(): boolean {
    let result = false;
    this.productPrices.forEach((price) => {
      const count = this.productCounts.find((item) => item.productID === price.id)?.count as number;
      if (parseInt(price.count as string) < count) {
        result = true;
      }
    });
    return result;
  }
}

export default CreateOrderStore;
