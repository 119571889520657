import React, { FC } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { SortOrder } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import moment from 'moment';
import GeneralTable from '../GeneralTable';
import { CoinTransaction } from '../../../models/coin-transaction';
import DateRangePickerWrapper from '../../DateRangePickerWrapper/DateRangePickerWrapper';

const BukaCoinHistoryTable: FC<{ getData: (params: any) => Promise<any> }> = ({ getData }) => {
  const { t } = useTranslation(['bukacoin', 'dates', 'search-element']);
  let filterOperation: any = null;
  let filterDateStart: any = null;
  let filterDateFinish: any = null;
  const selectOperationValues = [
    { value: 1, label: t('operationText.replenish') },
    { value: 2, label: t('operationText.spend') },
  ];

  const columns: any[] = [
    { dataField: 'value', text: t('value'), sort: true },
    {
      dataField: 'operation',
      text: t('operation'),
      sort: true,
      formatter: (cellContent: any, row: CoinTransaction) => {
        const label = selectOperationValues.find((value) => value.value === Number(row.operation))?.label;
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-operation-${row.coinTransactionId}`}>{label}</Tooltip>}
          >
            {Number(row.operation) === 1 ? (
              <FontAwesomeIcon icon={faPlusCircle} className="text-success" />
            ) : (
              <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
            )}
          </OverlayTrigger>
        );
      },
      filter: selectFilter({
        options: selectOperationValues,
        className: 'd-none',
        getFilter: (filter) => (filterOperation = filter),
      }),
    },
    {
      dataField: 'typeReplenish',
      text: t('typeReplenish'),
      sort: true,
      formatter: (cellContent: any, row: CoinTransaction) =>
        Number(row.typeReplenish) === 1 ? t('typeReplenishText.online') : t('typeReplenishText.cash'),
    },
    {
      dataField: 'startDate',
      text: t('dateInsert'),
      sort: true,
      formatter: (cellContent: any, row: CoinTransaction) => row.dateInsert,
      filter: selectFilter({
        options: {},
        className: 'd-none',
        getFilter: (filter) => (filterDateStart = filter),
      }),
    },
    {
      dataField: 'finishedDate',
      text: t('invoice'),
      sort: true,
      formatter: (cellContent: any, row: CoinTransaction) => row.invoiceNumber,
      filter: selectFilter({
        options: {},
        className: 'd-none',
        getFilter: (filter) => (filterDateFinish = filter),
      }),
    },
    {
      dataField: 'comment',
      text: t('comment'),
    },
  ];
  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: 'startDate',
      order: 'desc',
    },
  ];
  const handleFilterOperation = (data: any) => filterOperation(data && data.value);
  const handleFilterStartDate = (date: moment.Moment) => {
    filterDateStart(date && date.toISOString());
    return date;
  };
  const handleFilterEndDate = (date: moment.Moment) => {
    filterDateFinish(date && date.toISOString());
    return date;
  };

  return (
    <>
      <Row>
        <Col sm={6} lg={3} as={Form.Group}>
          <DateRangePickerWrapper
            showClearDates
            showDefaultInputIcon
            readOnly
            startDatePlaceholderText={t('dates:placeholderStartDate')}
            endDatePlaceholderText={t('dates:placeholderFinishedDate')}
            onChangeStartDate={handleFilterStartDate}
            onChangeEndDate={handleFilterEndDate}
            isOutsideRange={() => false}
          />
        </Col>
        <Col sm={6} lg={2} as={Form.Group}>
          <Select
            options={selectOperationValues}
            onChange={handleFilterOperation}
            isClearable
            placeholder={t('operation')}
          />
        </Col>
      </Row>
      <GeneralTable
        defaultSorted={defaultSorted}
        columns={columns}
        getData={getData}
        filter={filterFactory()}
        placeholderSearch={t('search-element:placeholder.invoice_or_comment')}
      />
    </>
  );
};

export default BukaCoinHistoryTable;
