import { ajaxRequest } from '../helpers';
import { urlRepository } from '../routes';
import { ClientInfo } from '../models/client-info';

export const registrationService = {
  sendForm,
};

function sendForm(data: ClientInfo) {
  return ajaxRequest.post(urlRepository.getUrl('requestRegistration'), data);
}
