const listApi: any = {
  login: '/login',
  requestRegistration: '/request-reg',
  //Client
  clientCatalog: '/client/catalog',
  clientCatalogProductInfo: '/client/catalog/:id',
  clientPublishers: '/client/publishers',
  clientActivations: '/client/activations',
  clientDiscounts: '/client/discount',
  clientDiscountOne: '/client/discount/:id',
  clientDiscountProducts: '/client/discountProducts/:id',
  clientDiscountProductsPrices: '/client/discountProducts/:id/prices',
  clientInvoice: '/client/invoice',
  clientOrder: '/client/order',
  clientOrderDetail: '/client/order/:id',
  clientOrderChangeColor: '/client/order/:id/color',
  clientOrderChangeStatus: '/client/order/:id/change-status',
  clientOrderGiveAway: '/client/order/:id/give-away',
  clientOrderStatus: '/client/order/status',
  clientOrderCreate: '/client/order/create',
  clientChangePassword: '/client/setting/changePassword',
  clientSettingPurchases: '/client/setting/purchases',
  clientBasket: '/client/basket',
  clientBasketSave: '/client/basket/save',
  clientBasketCount: '/client/basket/count',

  //BukaCoin
  clientBukaCoinBalance: '/client/bukacoin/balance',
  clientBukaCoinTransactions: '/client/bukacoin/transactions',

  clientPayment: '/client/payment',
  clientPaymentMethod: '/client/payment/method',
  clientPaymentByCoin: '/client/payment/coin',
  clientOrderAwaitPay: '/client/order-await-pay',
  clientOrderAwaitPayAllSum: '/client/order-await-pay/all-sum',
  clientOrderAwaitPayCount: '/client/order-await-pay/count',

  clientDocumentLicense: '/client/agreement/license',
  clientDocumentDigitalService: '/client/agreement/digital-service',
  clientDocumentHelp: '/client/help',

  //download files
  clientDownloadDiscount: '/client/discount/:id/download',
  clientDownloadOrderKey: '/client/key/order/:id/download',
  clientDownloadOrderProductKey: '/client/key/order/:1/product/:2/download',
  clientDownloadInvoiceKey: '/client/key/invoice/:id/download',
  clientDownloadInvoiceProductKey: '/client/key/invoice/:1/product/:2/download',

  //Bank Transfer
  clientDownloadInvoiceTransfer: '/client/invoice-transfer/:id/upload',
  clientInvoiceTransfer: '/client/invoice-transfer',
  clientInvoiceTransferCancel: '/client/invoice-transfer/:id/cancel',
  clientInvoiceTransferPaid: '/admin/invoice-transfer/:id/paid',
  clientPaymentTransfer: '/client/payment/transfer',

  //Admin
  adminUser: '/admin/user',
  adminUserDetail: '/admin/user/:id',
  adminUserIndividualPrice: '/admin/user/:id/individual-price',
  adminUserInfo: '/admin/user/:id/info',
  adminUserShoppingList: '/admin/user/:id/shopping-list',
  adminUserFind: '/admin/user/find',
  adminUserUnblocking: '/admin/user/:id/unblocking',
  adminUserNewPassword: '/admin/user/:id/new-password',
  adminProductFind: '/admin/product/find',

  adminDocumentLicense: '/admin/agreement/license',
  adminDocumentDigitalService: '/admin/agreement/digital-service',
  adminDocumentHelp: '/admin/help',

  adminSettingOptions: '/admin/setting/options',
  adminSettingRoyalty: '/admin/setting/royalty',
  adminSettingDynamic: '/admin/setting/dynamic',
  adminCatalog: '/admin/product',
  adminProduct: '/admin/product/:id',
  adminProductUpdate: '/admin/product/:id/update',
  adminFullUpdateSize: '/admin/product/full-update-size',
  adminFullUpdatePage: '/admin/product/full-update-page',
  adminProductPriceDiscountsClient: '/admin/product/:id/price-discounts-client',
  adminOrder: '/admin/order',
  adminOrderOne: '/admin/order/:id',

  adminBukaCoinBalance: '/admin/bukacoin/balance/:id',
  adminBukaCoinAdd: '/admin/bukacoin/add',
};

export const urlRepository = {
  listApi,
  getUrlWithId,
  getUrlWithParams,
  getUrl,
};

function getUrlWithId(name: string, id: number): string {
  return process.env.REACT_APP_BASE_URL_BACKEND + listApi[name].replace(':id', id);
}

function getUrlWithParams(name: string, params: Array<string>): string {
  let url: string = listApi[name];
  params.forEach((p, key) => (url = url.replace(':' + ++key, p)));
  return process.env.REACT_APP_BASE_URL_BACKEND + url;
}

function getUrl(name: string): string {
  return process.env.REACT_APP_BASE_URL_BACKEND + listApi[name];
}
