import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import BasketViewModel from '../Basket/BasketViewModel';
import { Currency } from '../index';

const BukaCoin: FC<{ basketViewModel: BasketViewModel }> = observer(({ basketViewModel }) => {
  useEffect(() => {
    basketViewModel.loadBalanceBukaCoin();
  }, [basketViewModel]);

  return (
    <div>
      <Row>
        <Col md={4}>
          <FontAwesomeIcon icon={faWallet} size={'3x'} color={'grey'} />
        </Col>
        <Col>
          <p className="mb-0">
            <b>BukaCoin</b>
          </p>
          <p>
            <b className="text-center text-muted">
              <Currency value={basketViewModel.getBalanceBukaCoin} />
            </b>
          </p>
        </Col>
      </Row>
    </div>
  );
});

export default BukaCoin;
