import React, { FC, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { bukaCoinService } from '../../../services';
import BukaCoinView from '../../../views/Client/BukaCoin/BukaCoinView';
import { Currency, BukaCoinForm } from '../../index';
import { CoinTransaction } from '../../../models/coin-transaction';

const BukaCoinPanel: FC<{ userID: number }> = ({ userID }) => {
  const { t } = useTranslation(['bukacoin', 'search-element']);
  const [balance, setBalance] = useState<number>(0);
  const [forceReload, setForceReload] = useState<string>('BukaCoinPanel_refresh_BukaCoinView');

  useEffect(() => {
    bukaCoinService.getRemoteBukaCoinBalanceForUser(userID).then(({ balance }) => setBalance(balance));
  }, [forceReload, userID]);

  const getData = (params: any) => {
    params['user_id'] = userID;
    return bukaCoinService.getRemoteBukaCoinTransactions(params);
  };
  const submitForm = (values: CoinTransaction) => {
    values.userId = userID;
    return bukaCoinService.saveCoinTransaction(values).then(() => setForceReload(forceReload + '1'));
  };
  return (
    <Card>
      <Card.Header>
        {t('headerBukaCoin')} | {t('balance')}:{' '}
        <strong>
          <Currency value={balance} />
        </strong>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <BukaCoinForm onSubmit={submitForm} />
          </Col>
        </Row>
        <hr className={'mt-0'} />
        <BukaCoinView key={forceReload} getData={getData} />
      </Card.Body>
    </Card>
  );
};

export default BukaCoinPanel;
