import { authenticationService } from './authentication.service';
import { getNavAdmin, getNavClient, getRoutesAdmin, getRoutesClient } from '../routes';
import { ClientSettingStore } from '../components';
import { currencyService } from './currency.service';

const rolesMapping: Array<any> = [
  { role: 'ROLE_ADMIN', getRoutes: getRoutesAdmin, getNav: getNavAdmin },
  { role: 'ROLE_CLIENT', getRoutes: getRoutesClient, getNav: getNavClient },
];

export const routingService = {
  getNavAndRoutesList(t: (s: string) => string, clientStore: ClientSettingStore): { routes: any; nav: any } | any {
    const roles = authenticationService.currentUserValue?.roles;
    const result = rolesMapping.find((x) => {
      return roles?.indexOf(x.role) !== -1;
    });

    if (result === undefined) {
      authenticationService.logout();
      return null;
    }

    const { getRoutes, getNav, role } = result;
    if (role === 'ROLE_ADMIN') {
      return this.filterForAdmin(getNav(t), getRoutes(t));
    }
    return this.filterForClient(getNav(t), getRoutes(t), clientStore);
  },

  filterForAdmin(nav: Array<any>, routes: Array<any>): { routes: any; nav: any } {
    return {
      nav: nav.filter((item) => item.to !== '/transfer' || currencyService.isSet()),
      routes: routes.filter((item) => item.path !== '/transfer' || currencyService.isSet()),
    };
  },

  filterForClient(nav: Array<any>, routes: Array<any>, clientStore: ClientSettingStore): { routes: any; nav: any } {
    return {
      nav: nav.filter((item) => item.to !== '/transfer' || (clientStore.isDelayedPayment && currencyService.isSet())),
      routes: routes.filter(
        (item) => item.path !== '/transfer' || (clientStore.isDelayedPayment && currencyService.isSet()),
      ),
    };
  },
};
